import { getTranslatedText } from "localization";
import { ValidationHelper } from "validations/validation.helper";

export const validateCreateEstimatesInput =
  ValidationHelper.BuildObjectValidationSchema({
    input: ValidationHelper.BuildObjectValidationSchema({
      // TODO: add field validations for create
    }),
  });

// export const validateCreateEstimatesInput =
//   ValidationHelper.BuildObjectValidationSchema({
//     input: ValidationHelper.BuildObjectValidationSchema({
//       prefix: ValidationHelper.RequiredString(
//         getTranslatedText("prefixIsRequired")
//       ),
//       timeZone: ValidationHelper.RequiredString(
//         getTranslatedText("timeZoneIsRequired")
//       ),
//       prospectTargetEntityId: ValidationHelper.RequiredString(
//         getTranslatedText("prospectTargetIsRequired")
//       ),
//       prospectTargetEntityRef: ValidationHelper.RequiredString(
//         getTranslatedText("prospectTargetRefIsRequired")
//       ),
//       prospectType: ValidationHelper.RequiredString(
//         getTranslatedText("prospectTypeIsRequired")
//       ),
//       prospectName: ValidationHelper.RequiredString(
//         getTranslatedText("prospectNameIsRequired")
//       ),
//       prospectPhoneNumber: ValidationHelper.RequiredString(
//         getTranslatedText("prospectPhoneNumberIsRequired")
//       ),
//       prospectCountry: ValidationHelper.RequiredString(
//         getTranslatedText("prospectCountryIsRequired")
//       ),
//       prospectState: ValidationHelper.RequiredString(
//         getTranslatedText("prospectStateIsRequired")
//       ),
//       prospectCity: ValidationHelper.RequiredString(
//         getTranslatedText("prospectCityIsRequired")
//       ),
//       prospectContactFirstName: ValidationHelper.RequiredString(
//         getTranslatedText("prospectContactFirstNameIsRequired")
//       ),
//       prospectContactLastName: ValidationHelper.RequiredString(
//         getTranslatedText("prospectContactLastNameIsRequired")
//       ),
//       prospectContactEmail: ValidationHelper.RequiredString(
//         getTranslatedText("prospectContactEmailIsRequired")
//       ),
//       language: ValidationHelper.RequiredString(
//         getTranslatedText("languageIsRequired")
//       ),
//       date: ValidationHelper.RequiredDate(getTranslatedText("dateIsRequired")),
//       expireDate: ValidationHelper.RequiredDate(
//         getTranslatedText("expireDateIsRequired")
//       ),
//       sendEmailOnExpiration: ValidationHelper.RequiredBoolean(
//         getTranslatedText("sendEmailOnExpirationIsRequired")
//       ),
//       currency: ValidationHelper.RequiredString(
//         getTranslatedText("currencyIsRequired")
//       ),
//       products: ValidationHelper.RequiredArray(
//         getTranslatedText("productsIsRequired")
//       ),
//       salesAgentIds: ValidationHelper.RequiredArray(
//         getTranslatedText("salesAgentIdsIsRequired")
//       ),
//       allowComments: ValidationHelper.RequiredBoolean(
//         getTranslatedText("allowCommentsIsRequired")
//       ),
//       useLegalNDA: ValidationHelper.RequiredBoolean(
//         getTranslatedText("useLegalNDAIsRequired")
//       ),
//       useLegalTermAndCond: ValidationHelper.RequiredBoolean(
//         getTranslatedText("useLegalTermAndCondIsRequired")
//       ),
//       signerUserId: ValidationHelper.RequiredString(
//         getTranslatedText("signerUserIdIsRequired")
//       ),
//     }),
//   });
