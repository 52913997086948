/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, IconButton, Modal, Stack, SvgIcon, TextField, Typography, } from "@mui/material";
import { useAppDispatch, } from "context";
import { productsAndServicesActions } from "../../../context";
import { useEffect, useState } from "react";
import { useProductCategoryCreateMutation, useProductCategoryListQuery, useProductCategoryUpdateMutation, } from "../../categories/context/categories.api"
import { Cancel, DeleteOutlineRounded, Save } from "@mui/icons-material";
import { DataGridPremium, GridColDef, GridRowModes, GridRowModesModel, useGridApiRef } from "@mui/x-data-grid-premium";
import { enqueueSnackbar } from "notistack";
import * as Icons from '@mui/icons-material';
import CategoryCreateDrawer from "./CategoryCreateDrawer";
import { useTranslation } from "react-i18next";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import Delete from "./Delete";
import { IBaseProductCategoryEntity } from "corede-common-cocrm";
import { Language } from "corede-common";
import { trTR } from '@mui/x-data-grid/locales';
import { getCurrentLanguage } from "localization";
import CategoryCreateWraper from "./CategoryCreateWraper";
import CategoryUpdateWraper from "./CategoryUpdateWraper";


const List = () => {

  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const dispatch = useAppDispatch();
  const apiRef = useGridApiRef();
  const { data: productCategoryListData, isLoading: productCategoryListLoading, isFetching: productCategoryListFetching } = useProductCategoryListQuery({
    input: {
      pagination: {
        sort: {
          createdAt: 1
        }
      }
    }
  });
  const [productCategoryUpdate, { isLoading: productCategoryUpdateLoading, error: productCategoryUpdateError }] = useProductCategoryUpdateMutation();
  const [productCategoryCreate, { isLoading: productCategoryCreateLoading, error: productCategoryCreateError }] = useProductCategoryCreateMutation();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [selectedName, setSelectedName] = useState('');
  const [selectedIcon, setSelectedIcon] = useState<any>('');
  const [addStatusAvailable, setAddStatusAvailable] = useState(true);
  const [openSelectIconset, setOpenSelectIconset] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [searchQuery, setSearchQuery] = useState('');
  const [openStatusCreateDrawer, setOpenStatusCreateDrawer] = useState(false);
  const [openCustomerCategoryUpdate, setOpenCustomerCategoryUpdate] = useState(false);
  const [selectedItemForDelete, setSelectedItemForDelete] = useState<any>();

  const [rows, setRows] = useState<any[]>(productCategoryListData?.data?.map((status, index) => ({
    id: index + 1,
    _id: status._id,
    name: status.name || '-',
    icon: status.icon || '-',
  })) || []);

  const columns: GridColDef[] = [
    { field: 'id', headerName: '#', width: 50, disableColumnMenu: true, filterable: false, align: 'center', headerAlign: 'center' },
    {
      field: 'name', headerName: 'Name', width: 160, editable: true,
      renderEditCell: (params) => {
        return (
          <TextField
            size="small"
            value={params.value || ''}
            onChange={(e) => {
              const newValue = e.target.value;
              setSelectedName(newValue);  // selectedName'i güncelle
              apiRef.current.setEditCellValue({ ...params, value: newValue });  // Grid'deki değeri güncelle
            }}
            onBlur={() => apiRef.current.stopCellEditMode({ id: params.id, field: params.field })}
          />
        );
      },
    },
    {
      field: 'icon', headerName: 'Icon', width: 100, editable: true,
      renderCell: (params) => {
        return (
          <SvgIcon component={(Icons as any)[params.value]} fontSize="small" sx={{ mt: 2, ml: 0.5 }} />
        )
      },
      renderEditCell: () => null
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 120,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"center"} mt={{ xs: 0, md: 2 }}>
            <IconButton
              onClick={() => {
                setSelectedRow(params?.row);
                setOpenCustomerCategoryUpdate(true);
              }}
              color="info"
              sx={{ p: "4px", }}
            >
              <Icons.EditNoteRounded color="info" fontSize="small" />
            </IconButton>
            <IconButton
              onClick={() => handleDeleteRow(params?.row)}
              color="error"
              sx={{ p: "4px", }}
            >
              <DeleteOutlineRounded color="error" fontSize="small" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];



  const handleDeleteRow = (item: IBaseProductCategoryEntity) => {
    setSelectedItemForDelete(item);
  }

  function handleProcessRowUpdate(newRow: any, oldRow: any) {
    if (newRow.name !== oldRow.name && addStatusAvailable) {
      productCategoryUpdate({ filter: { _id: newRow._id }, input: { name: newRow.name } });
    }
    if (newRow.icon !== oldRow.icon && addStatusAvailable) {
      productCategoryUpdate({ filter: { _id: newRow._id }, input: { icon: newRow.icon } });
    }
    return newRow;
  };

  useEffect(() => {
    if (productCategoryListData && productCategoryListData.data) {
      setRows(productCategoryListData.data.map((status, index) => ({
        id: index + 1,
        _id: status._id,
        name: status.name || '-',
        icon: status.icon || '-',
      })));
    }
  }, [productCategoryListData]);

  useEffect(() => {
    if (productCategoryUpdateError) {
      enqueueSnackbar('Product Category Update Failed', { variant: 'error' });
    }
  }, [productCategoryUpdateError])

  useEffect(() => {
    if (productCategoryCreateError) {
      enqueueSnackbar('Product Category Creation Failed', { variant: 'error' });
    }
  }, [productCategoryCreateError])


  //initial



  return (
    <Grid item xs={12} height={"calc(100vh - 260px)"}>
      <HeaderBase
        title={t(`crm.productsAndServices.categories.title`)}
        rightButton={{
          icon: <Icons.AddCircle sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.productsAndServices.categories.create`),
          onClick: () => setOpenStatusCreateDrawer(true),
        }}
        showBackButton={true}
      />
      <Box minHeight={"350px"} maxHeight={"calc(100vh - 200px)"} mt={1} overflow={"auto"} borderRadius={"16px"}>
        <DataGridPremium
          localeText={currentLanguage === Language.tr ? trTR.components.MuiDataGrid.defaultProps.localeText : undefined}
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          showColumnVerticalBorder
          disableAggregation
          disableRowGrouping
          hideFooter
          processRowUpdate={handleProcessRowUpdate}
          loading={productCategoryListLoading || productCategoryUpdateLoading || productCategoryCreateLoading || productCategoryListFetching}
          onCellDoubleClick={(params, event) => {
            if (params.field === 'icon') {
              setOpenCustomerCategoryUpdate(true);
              setSelectedRow(params.row);
              event.stopPropagation();
            }
          }}
          scrollbarSize={1}
          scrollEndThreshold={10}
          pinnedColumns={{ left: ['id'], right: ['actions'] }}
          rowsLoadingMode="server"
          sx={[tableStyle, {
            backgroundColor: 'background.secondary', minHeight: "350px",
            '&. .MuiDataGrid-topContainer': {
              border: "2px solid #000"
            },
          }]}
        />
      </Box>


      <CategoryCreateWraper
        open={openStatusCreateDrawer}
        setOpen={setOpenStatusCreateDrawer}
      />

      <CategoryUpdateWraper
        open={openCustomerCategoryUpdate}
        setOpen={setOpenCustomerCategoryUpdate}
        selectedRow={selectedRow}
      />

      <Delete
        open={!!selectedItemForDelete}
        onClose={() => setSelectedItemForDelete(null)}
        item={selectedItemForDelete}
      />
    </Grid >
  );
};

export default List;



export const tableStyle = {
  fontSize: "12px",
  borderRadius: "16px",

  '& .MuiDataGrid-toolbarContainer > button': {
    fontSize: "12px",
  },
  '& .MuiDataGrid-columnHeader .MuiIconButton-root': {
    fontSize: "12px",
    p: "2px",
    border: "none !important",
  },
  '& .MuiDataGrid-columnHeader .MuiSvgIcon-root': {
    width: "16px",
    height: "16px",
  },
  '& .MuiDataGrid-footerContainer': {
    height: '20px !important',
    maxHeight: '20px !important',
    m: 0,
  }
};
