import {
  // queries

  IUserListInput, IUserListRequest, IUserListResponse, IUserListResult, userListQuery,
  IOrganizationChartDetailInput, IOrganizationChartDetailRequest, IOrganizationChartDetailResponse, IOrganizationChartDetailResult, organizationChartDetailQuery,

  IUserPermissionDetailInput, IUserPermissionDetailRequest, IUserPermissionDetailResponse, IUserPermissionDetailResult, userPermissionDetailQuery,

  // mutations
  IUserCreateInput, IUserCreateRequest, IUserCreateResponse, IUserCreateResult, userCreateQuery,
  IUserUpdateInput, IUserUpdateRequest, IUserUpdateResponse, IUserUpdateResult, userUpdateQuery, IUserUpdateFilterInput,
  IUserDeleteInput, IUserDeleteRequest, IUserDeleteResponse, IUserDeleteResult, userDeleteQuery,
  IUserRoleUpdateInput, IUserRoleUpdateRequest, IUserRoleUpdateResponse, IUserRoleUpdateResult, userRoleUpdateQuery, IUserRoleUpdateFilterInput,
  IOrganizationChartUpdateInput, IOrganizationChartUpdateRequest, IOrganizationChartUpdateResponse, IOrganizationChartUpdateResult, organizationChartUpdateQuery, IOrganizationChartUpdateFilterInput,
  IUserPermissionUpdateInput, IUserPermissionUpdateRequest, IUserPermissionUpdateResponse, IUserPermissionUpdateResult, userPermissionUpdateQuery, IUserPermissionUpdateFilterInput,




} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { organizationTags } from "./employees.tags";
import { IGraphqlVariables } from "corede-common";


export const organizationApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({


    userList: builder.query<
      IUserListResult,
      IGraphqlVariables<IUserListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserListRequest,
        IUserListResponse,
        IUserListResult,
        IUserListInput
      >({
        query: userListQuery,
        providesTags: [organizationTags.users, organizationTags.user],
      })),

    organizationChartDetail: builder.query<
      IOrganizationChartDetailResult,
      IGraphqlVariables<IOrganizationChartDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationChartDetailRequest,
        IOrganizationChartDetailResponse,
        IOrganizationChartDetailResult,
        IOrganizationChartDetailInput
      >({
        query: organizationChartDetailQuery,
        providesTags: [organizationTags.organization],
      })),


    userPermissionDetail: builder.query<
      IUserPermissionDetailResult,
      IGraphqlVariables<IUserPermissionDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserPermissionDetailRequest,
        IUserPermissionDetailResponse,
        IUserPermissionDetailResult,
        IUserPermissionDetailInput
      >({
        query: userPermissionDetailQuery,
        providesTags: [organizationTags.permissions],
      })),

    // mutations

    userCreate: builder.mutation<
      IUserCreateResult,
      IGraphqlVariables<IUserCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserCreateRequest,
        IUserCreateResponse,
        IUserCreateResult,
        IUserCreateInput
      >({
        query: userCreateQuery,
        invalidatesTags: [organizationTags.users, organizationTags.user],
      })),

    userUpdate: builder.mutation<
      IUserUpdateResult,
      IGraphqlVariables<IUserUpdateInput, IUserUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserUpdateRequest,
        IUserUpdateResponse,
        IUserUpdateResult,
        IUserUpdateInput,
        IUserUpdateFilterInput
      >({
        query: userUpdateQuery,
        invalidatesTags: [organizationTags.users, organizationTags.user],
      })),

    userDelete: builder.mutation<
      IUserDeleteResult,
      IGraphqlVariables<IUserDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserDeleteRequest,
        IUserDeleteResponse,
        IUserDeleteResult,
        IUserDeleteInput
      >({
        query: userDeleteQuery,
        invalidatesTags: [organizationTags.users, organizationTags.user],
      })),

    userRoleUpdate: builder.mutation<
      IUserRoleUpdateResult,
      IGraphqlVariables<IUserRoleUpdateInput, IUserRoleUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserRoleUpdateRequest,
        IUserRoleUpdateResponse,
        IUserRoleUpdateResult,
        IUserRoleUpdateInput,
        IUserRoleUpdateFilterInput
      >({
        query: userRoleUpdateQuery,
        invalidatesTags: [organizationTags.users, organizationTags.user],
      })),



    organizationChartUpdate: builder.mutation<
      IOrganizationChartUpdateResult,
      IGraphqlVariables<IOrganizationChartUpdateInput, IOrganizationChartUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationChartUpdateRequest,
        IOrganizationChartUpdateResponse,
        IOrganizationChartUpdateResult,
        IOrganizationChartUpdateInput,
        IOrganizationChartUpdateFilterInput
      >({
        query: organizationChartUpdateQuery,
        invalidatesTags: [organizationTags.organization],
      })),

    userPermissionUpdate: builder.mutation<
      IUserPermissionUpdateResult,
      IGraphqlVariables<IUserPermissionUpdateInput, IUserPermissionUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserPermissionUpdateRequest,
        IUserPermissionUpdateResponse,
        IUserPermissionUpdateResult,
        IUserPermissionUpdateInput,
        IUserPermissionUpdateFilterInput
      >({
        query: userPermissionUpdateQuery,
        invalidatesTags: [organizationTags.permissions],
      }))



  })

});

export const {

  useUserListQuery,
  useLazyUserListQuery,
  useOrganizationChartDetailQuery,
  useLazyOrganizationChartDetailQuery,
  useUserPermissionDetailQuery,
  useLazyUserPermissionDetailQuery,

  useUserCreateMutation,
  useUserUpdateMutation,
  useUserDeleteMutation,
  useUserRoleUpdateMutation,
  useOrganizationChartUpdateMutation,
  useUserPermissionUpdateMutation


} = organizationApi;
