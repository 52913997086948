import { Info } from "@mui/icons-material"
import { IconButton, Stack, SwipeableDrawer, Typography } from "@mui/material"
import { useState } from "react"


type Props = {
    testMessages?: string[]
}

const TestInfo = (props: Props) => {

    const [open, setOpen] = useState(false)

    return <>
        <IconButton
            onClick={() => setOpen(!open)}
            size="large"
            color="secondary"
            sx={{ml:-10, position: 'absolute',zIndex: 120, width: 40, height: 40, '& .MuiSvgIcon-root': { fontSize: 30 } }}
        >
            <Info />
        </IconButton>
        <SwipeableDrawer
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            anchor='bottom'
        >
            <Stack sx={{ p: 2 }}>
                <Typography variant="h5">Test Info</Typography>

                <Stack direction={"column"} gap={1}>
                    {props.testMessages?.map((message, index) => (
                        <Typography key={index}>- {message}</Typography>
                    ))}
                </Stack>
            </Stack>
        </SwipeableDrawer>
    </>
}

export default TestInfo