import { useState, lazy, Suspense } from 'react';


const LeadStatusCreateDrawer = lazy(() => import('./LeadStatusCreateDrawer'));


const LeadStatusCreateWrapper = ({ 
  open, 
  setOpen 
  }: any) => {

    const [mounted, setMounted] = useState(false);

    if (open && !mounted) {
        setMounted(true);
    }

    return (
        <>
            {mounted && (
                <Suspense>
                    < LeadStatusCreateDrawer 
                    open={open} 
                    setOpen={setOpen}
                    
                     />
                </Suspense>
            )}
        </>
    );
};

export default LeadStatusCreateWrapper;