const locale = {
  service: "Services",
  title: "Services",
  create: "Create Service",
  delete: "Delete Service",
  deleteConfirm: "Are you sure you want to delete this status: ",
  deletedSuccessfully: "Service Service deleted successfully",
  createService: "Create Service",
  serviceCreateSuccess: "Service Service created successfully",
  name: "Name",
  icon: "Icon",
  showMore: "Show More",

  type: "Type",
  image: "Image",
  sku: "Sku",
  description: "Description",
  currency: "Currency",
  price: "Price",
  priceType: "PriceType",
  discountedPrice: "Discounted Price",
  unit: "Unit",
  categoryId: "Category",
  natTaxRate: "National Tax Rate",
  intTaxRate: "International Tax Rate",

  categories: "Categories",
};

export default locale;
