import { useState, lazy, Suspense } from 'react';


const DepartmentCreateDrawer = lazy(() => import('./DepartmentCreateDrawer'));


const DepartmentCreateWrapper = ({
    open,
    setOpen,
    setOpenRoles
}: any) => {

    const [mounted, setMounted] = useState(false);

    if (open && !mounted) {
        setMounted(true);
    }

    return (
        <>
            {mounted && (
                <Suspense>
                    <DepartmentCreateDrawer
                        open={open}
                        setOpen={setOpen}
                        setOpenRoles={setOpenRoles}
                    />
                </Suspense>
            )}
        </>
    );
};

export default DepartmentCreateWrapper;