/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useEffect } from "react";

import { IBaseError } from "corede-common"
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { IBaseProductEntity } from "corede-common-cocrm";
import { useTranslation } from "react-i18next";
import { useProductDeleteMutation } from "../../products";

const Delete = (prop: {
  open: boolean;
  item: IBaseProductEntity;
  onClose: () => void;
}) => {
  // general
  const currentLanguage = getCurrentLanguage();
  const { t } = useTranslation();

  // queries

  // mutations
  const [productStatusDelete, { data: productStatusDeleteData, isLoading: productStatusDeleteLoading, error: productStatusDeleteError }] = useProductDeleteMutation();

  // constants

  // action handlers
  const handleDelete = (_id: string) => {
    productStatusDelete({ input: { _id: _id } });
  };

  // useEffects.success
  useEffect(() => {
    if (productStatusDeleteData) {
      prop?.onClose();
      enqueueSnackbar(t("crm.service.services.deletedSuccessfully"), {
        variant: "success",
      });
    }
  }, [productStatusDeleteData]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      productStatusDeleteError as IBaseError,
      currentLanguage
    );
  }, [productStatusDeleteError, currentLanguage]);

  // useEffects.init

  return (
    <Dialog
      open={prop?.open}
      onClose={prop?.onClose}
      sx={{
        "& .MuiDialog-paper": {
          padding: 1,
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle>{t("crm.service.services.delete")}</DialogTitle>
      <DialogContent>
        {t("crm.service.services.deleteConfirm")} {prop?.item?.name}?
      </DialogContent>
      <DialogActions>
        <Button onClick={prop?.onClose} color="primary">
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="error"
          disabled={productStatusDeleteLoading}
          onClick={() => {
            handleDelete(prop?.item?._id);
          }}
        >
          {t("delete")}
        </Button>
      </DialogActions>
    </Dialog >
  );
};

export default Delete;
