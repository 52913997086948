const locale = {
  product: "Products",
  title: "Products",
  create: "Create Product",
  delete: "Delete Product",
  deleteConfirm: "Are you sure you want to delete this status: ",
  deletedSuccessfully: "Product Product deleted successfully",
  createProduct: "Create Product",
  productCreateSuccess: "Product Product created successfully",
  name: "Name",
  icon: "Icon",
  showMore: "Show More",

  type: "Type",
  image: "Image",
  sku: "Sku",
  description: "Description",
  currency: "Currency",
  price: "Price",
  priceType: "PriceType",
  discountedPrice: "Discounted Price",
  unit: "Unit",
  categoryId: "Category",
  natTaxRate: "National Tax Rate",
  intTaxRate: "International Tax Rate",

  categories: "Categories",
};

export default locale;
