/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useEffect } from "react";
import { useProductCategoryDeleteMutation } from "../../categories/context/categories.api";
import { IBaseError } from "corede-common"
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { IBaseProductCategoryEntity } from "corede-common-cocrm";
import { useTranslation } from "react-i18next";

const Delete = (prop: {
  open: boolean;
  item: IBaseProductCategoryEntity;
  onClose: () => void;
}) => {
  // general
  const currentLanguage = getCurrentLanguage();
  const { t } = useTranslation();

  // queries

  // mutations
  const [productCategoryDelete, { data: productCategoryDeleteData, isLoading: productCategoryDeleteLoading, error: productCategoryDeleteError }] = useProductCategoryDeleteMutation();

  // constants

  // action handlers
  const handleDelete = (_id: string) => {
    productCategoryDelete({ input: { _id: _id } });
  };

  // useEffects.success
  useEffect(() => {
    if (productCategoryDeleteData) {
      prop?.onClose();
      enqueueSnackbar(t("crm.productsAndServices.categories.deletedSuccessfully"), {
        variant: "success",
      });
    }
  }, [productCategoryDeleteData]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      productCategoryDeleteError as IBaseError,
      currentLanguage
    );
  }, [productCategoryDeleteError, currentLanguage]);

  // useEffects.init

  return (
    <Dialog
      open={prop?.open}
      onClose={prop?.onClose}
      sx={{
        "& .MuiDialog-paper": {
          padding: 1,
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle>{t("crm.productsAndServices.categories.delete")}</DialogTitle>
      <DialogContent>
        {t("crm.productsAndServices.categories.deleteConfirm")} {prop?.item?.name}?
      </DialogContent>
      <DialogActions>
        <Button onClick={prop?.onClose} color="primary">
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="error"
          disabled={productCategoryDeleteLoading}
          onClick={() => {
            handleDelete(prop?.item?._id);
          }}
        >
          {t("delete")}
        </Button>
      </DialogActions>
    </Dialog >
  );
};

export default Delete;
