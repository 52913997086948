/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography, CircularProgress, Stack, IconButton } from "@mui/material";
import { useFormik } from "formik";
import { loginValidationSchema } from 'utils/validations';
import { AuthLayout } from "../layouts";
import { useLoginMutation, setUser, useUserDetailOwnQuery } from "../context";
import { useSnackbar } from "notistack";
import { useAppDispatch, } from "context";
import { useEffect } from "react";
import { Iconify, MyField } from "components";
import LoginCo from "assets/images/login-co.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { useTranslation } from 'react-i18next';

const Login = () => {

  const { t } = useTranslation();
  const [getLogin, { data, isLoading, error }] = useLoginMutation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: userData, refetch } = useUserDetailOwnQuery({});


  const { values, handleSubmit, setFieldValue, errors, handleBlur, touched } = useFormik({
    enableReinitialize: true,
    validationSchema: loginValidationSchema,
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      try {
        await getLogin({
          input: { email: values.email, password: values.password }
        });
      } catch (error) {
        enqueueSnackbar(t("auth.somethingWentWrong"), { variant: 'error' });
      }
    }
  });

  useEffect(() => {
    if (error) {
      enqueueSnackbar((error as any)?.error?.message?.en ?? t("auth.somethingWentWrong"), { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (data) {
      enqueueSnackbar(t("auth.loginSuccess"), { variant: 'success' });
      dispatch(setUser(data));
    }
  }, [data, dispatch, enqueueSnackbar]);

  useEffect(() => {
    refetch()
  }, [refetch])


  return <AuthLayout>
    <Stack direction={"column"} justifyContent={"space-between"} alignItems={"center"}>
      <Typography fontSize={14} fontWeight={400} textAlign={"center"} color={"GrayText"} py={2}>

      </Typography>
      <Grid item xs={12} display={"flex"} height={"100vh"} width={"100%"} justifyContent={'center'} alignItems={'center'}>


        <Box display={{ xs: 'flex', md: 'none' }} height={100} />
        <Box
          sx={{
            width: { xs: '100%', md: '80%', lg: '60%' },
            height: "80vh",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: { xs: 'flex-start', md: 'center' },
            alignItems: 'center',
            gap: 2,
          }}
          px={{ xs: 1, md: 7 }}
        >
          <img src={LoginCo} alt={t("auth.logoAlt")} width={120} />
          <Box display={"flex"} flexDirection={"column"} gap={2} alignItems={"center"} py={2}>
            <Typography fontSize={14} fontWeight={400} textAlign={"center"}>
              {t("auth.signinText")}
            </Typography>
          </Box>

          <Box display={"flex"} sx={{ width: '100%' }} flexDirection={"column"} alignItems={"center"}>
            <form onSubmit={handleSubmit as any} style={{ width: '100%' }}>
              <Box display={"flex"} flexDirection={"column"} gap={1.5} alignItems={"center"} >
                <MyField
                  placeholder={t("auth.emailAddress")}
                  type="email"
                  values={values}
                  setFieldValue={setFieldValue}
                  name="email"
                  errors={errors}
                  handleBlur={handleBlur}
                  touched={touched}

                />
                <MyField
                  placeholder={t("auth.password")}
                  type="password"
                  values={values}
                  setFieldValue={setFieldValue}
                  name="password"
                  errors={errors}
                  handleBlur={handleBlur}
                  touched={touched}

                />
                <Typography fontSize={14} fontWeight={600} textAlign={"center"} py={1} sx={{ cursor: "pointer" }} onClick={() => navigate(routes.auth.forgotPassword)}>
                  {t("auth.forgotPassword")}
                </Typography>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSubmit as any}
                  disabled={isLoading}
                  sx={{ fontSize: 14, height: 56 }}
                >
                  {(isLoading) ? <CircularProgress size="1rem" /> : t("auth.login")}

                </Button>
              </Box>
            </form>

          </Box>
          <Stack display={"flex"} flexDirection={"row"} gap={2} alignItems={"center"} width={"100%"}>
            <Box bgcolor={"#aaa"} width={"80%"} height={"1px"} sx={{ borderRadius: 1 }} />
            <Typography width="100%" fontSize={14} fontWeight={400} color={"GrayText"} textAlign={"center"}>
              {t("auth.continueWith")}
            </Typography>
            <Box bgcolor={"#aaa"} width={"80%"} height={"1px"} sx={{ borderRadius: 1 }} />
          </Stack>
          <Stack display={"flex"} flexDirection={"row"} gap={2} alignItems={"center"} justifyContent={"center"} width={"100%"}>
            <IconButton sx={{ backgroundColor: "primary.main", p: 1.5, color: "#fff", '&:hover': { backgroundColor: "primary.dark", boxShadow: 2 } }}>
              <Iconify icon={"ant-design:google-outlined"} width={24} sx={{ color: "#fff" }} />
            </IconButton>
            <IconButton sx={{ backgroundColor: "primary.main", p: 1.5, color: "#fff", '&:hover': { backgroundColor: "primary.dark", boxShadow: 2 } }}>
              <Iconify icon={"ic:baseline-apple"} width={24} sx={{ color: "#fff" }} />
            </IconButton>
            <IconButton sx={{ backgroundColor: "primary.main", p: 1.5, color: "#fff", '&:hover': { backgroundColor: "primary.dark", boxShadow: 2 } }}>
              <Iconify icon={"ri:linkedin-fill"} width={24} sx={{ color: "#fff" }} />
            </IconButton>
          </Stack>
        </Box>

      </Grid>

      <Typography fontSize={14} fontWeight={400} textAlign={"center"} color={"GrayText"} py={2} sx={{ cursor: "pointer" }}>
        {t("auth.noAccount")}
        <span style={{ color: "#000" }} onClick={() => navigate(routes.auth.signup)}> {t("auth.signUp")}</span>
      </Typography>
    </Stack>
  </AuthLayout >
}

export default Login;
