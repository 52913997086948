import { Backdrop, Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { lazy, memo, Suspense, useEffect, useState } from "react";
import { Add, FileUpload, } from "@mui/icons-material";
import { LeadSourcesDrawer } from "../../leadSources";
import { LeadStatusesDrawer } from "../../leadStatuses";
import { useLeadListQuery } from "../context/lead.api";
import { useLeadStatusesQuery } from "../../leadStatuses/context/leadStatuses.api";
import { useLeadSourcesQuery } from "../../leadSources/context/leadSources.api";
import { enqueueSnackbar } from "notistack";
import { KanbanView } from "./KanbanView";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { useTranslation } from "react-i18next";
import ListView from "./ListView";
import { importLeadRoute } from "../../../routes/lead.base.route";
import { useNavigate } from "react-router-dom";
import { BarChart, Gauge, gaugeClasses, LineChartPro } from '@mui/x-charts-pro';
import LeadCreateDrawerWrapper from "./LeadCreateWrapper";
import LeadUpdateDrawerWrapper from "./LeadUpdateWrapper";
import LeadDetailWrapper from "./LeadDetailWrapper";
import LeadSourcesCreateWraper from "../../leadSources/pages/LeadSourceCreateWraper";
import LeadStatusCreateWrapper from "../../leadStatuses/pages/LeadStatusCreateWrapper";


const List = memo(() => {

  // general
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openUserDrawer, setOpenUserDrawer] = useState(false);
  const [openLeadStatusesDrawer, setOpenLeadStatusesDrawer] = useState(false);
  const [openLeadSourcesDrawer, setOpenLeadSourcesDrawer] = useState(false);
  const [openLeadCreateDrawer, setOpenLeadCreateDrawer] = useState(false);
  const [openLeadUpdateDrawer, setOpenLeadUpdateDrawer] = useState(false);
  const [selectedLead, setSelectedLead] = useState<any>();
  const [leadListType, setLeadListType] = useState("list");
  const [leadStatusCreateOpen, setLeadStatusCreateOpen] = useState(false);
  const [leadSourceCreateOpen, setLeadSourceCreateOpen] = useState(false);

  // queries 
  const { data: leadStatusesData, isLoading: leadStatusesLoading, error: leadStatusesError } = useLeadStatusesQuery({});
  const { data: leadSourcesData, isLoading: leadSourcesLoading, error: leadSourcesError } = useLeadSourcesQuery({});
  const { data: leadListData } = useLeadListQuery({});


  function handleSelectLead(direction: 'next' | 'back') {
    if (!selectedLead || !leadListData?.data?.length) return;

    const currentIndex = leadListData.data.findIndex(lead => lead._id === selectedLead._id);

    let newIndex = currentIndex;
    if (direction === 'next') {
      newIndex = currentIndex + 1;
    } else if (direction === 'back') {
      newIndex = currentIndex - 1;
    }

    // Ensure the new index is within the valid range
    if (newIndex >= 0 && newIndex < leadListData?.data?.length) {
      const newSelectedLead = { ...leadListData.data[newIndex], id: newIndex + 1 }; // index bilgisini ekle
      setSelectedLead(newSelectedLead);
    }
  }

  // success useEffects


  //error useEffects

  useEffect(() => {
    if (leadStatusesError) {
      enqueueSnackbar("Error", { variant: "error" });
    }
  }, [leadStatusesError]);

  useEffect(() => {
    if (leadSourcesError) {
      enqueueSnackbar("Error", { variant: "error" });
    }
  }, [leadSourcesError]);





  return (
    <Grid item xs={12} height={"calc(100vh - 260px)"}>
      <HeaderBase
        title={t(`crm.lead.lead.title`)}
        toggleButton={(leadListData as any)?.data?.length > 0 ? {
          value: leadListType,
          action: (value: any) => setLeadListType(value),
        } : undefined}
        rightButton={(leadListData as any)?.data?.length > 0 ? {
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.lead.lead.create`),
          onClick: () => setOpenLeadCreateDrawer(true),
        } : undefined}
        rightButton2={(leadListData as any)?.data?.length > 0 ? {
          icon: <FileUpload sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          onClick: () => navigate(importLeadRoute()),
          title: t(`crm.lead.lead.importLead`),
        } : undefined}
      />

      {(leadListData as any)?.count > 0 ? <Grid container spacing={2} my={1}>
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" sx={{ border: "1px solid", borderRadius: "14px" }}>
            <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between" width={"85%"} mb={-5}>
              <Typography fontSize={"18px"} fontWeight={"bold"}>Leads</Typography>
              <Typography fontSize={"44px"} fontWeight={"bold"}>25</Typography>
            </Stack>
            <LineChartPro
              xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
              series={[{
                data: [2, 5.5, 2, 8.5, 1.5, 5],
                color: "#F43F5E"
              }]}
              height={170}
              sx={{ mb: -5 }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" sx={{ border: "1px solid", borderRadius: "14px" }}>
            <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between" width={"85%"} mb={-2}>
              <Typography fontSize={"18px"} fontWeight={"bold"}>Lead Conversions</Typography>
              <Typography fontSize={"44px"} fontWeight={"bold"}>35</Typography>
            </Stack>
            <Gauge
              width={180}
              height={147}
              value={35}
              cornerRadius="50%"
              sx={(theme) => ({
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 40,
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: theme.palette.secondary.main,
                },
                [`& .${gaugeClasses.referenceArc}`]: {
                  fill: theme.palette.text.disabled,
                },
              })}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" sx={{ border: "1px solid", borderRadius: "14px" }}>
            <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between" width={"85%"} mb={-2}>
              <Typography fontSize={"18px"} fontWeight={"bold"}>B2B / B2C</Typography>
              <Typography fontSize={"44px"} fontWeight={"bold"}>&nbsp;</Typography>
            </Stack>
            <BarChart
              xAxis={[
                {
                  id: 'barCategories',
                  data: ['BTB', 'BTC',],
                  scaleType: 'band',
                },
              ]}
              series={[
                {
                  data: [2, 5],
                  color: "#F43F5E"
                },
              ]}
              height={150}
              sx={{ mb: -5 }}
            />
          </Box>
        </Grid>
      </Grid> : <Grid container spacing={2} my={1}>
        {Array.from({ length: 3 }).map((_, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Skeleton variant="rectangular" width={"100%"} height={200} sx={{ borderRadius: "16px" }} />
          </Grid>
        ))}
      </Grid>}

      {leadListType === "list" ?
        <ListView
          leadSourcesData={leadSourcesData}
          leadStatusesData={leadStatusesData}
          setOpenUserDrawer={setOpenUserDrawer}
          setSelectedLead={setSelectedLead}
          leadStatusesLoading={leadStatusesLoading}
          leadSourcesLoading={leadSourcesLoading}
          setOpenLeadCreateDrawer={setOpenLeadCreateDrawer}
          selectedLead={selectedLead}
          setOpenLeadUpdateDrawer={setOpenLeadUpdateDrawer}
          leadSourceCreateOpen={openLeadSourcesDrawer}
          leadStatusCreateOpen={openLeadStatusesDrawer}
          setLeadSourceCreateOpen={setOpenLeadSourcesDrawer}
          setLeadStatusCreateOpen={setOpenLeadStatusesDrawer}
        />
        :
        <KanbanView
          statuses={leadStatusesData?.data}
          leads={leadListData?.data}
          setOpenLeadStatusesDrawer={setOpenLeadStatusesDrawer}
          setSelectedLead={setSelectedLead}
          setOpenUserDrawer={setOpenUserDrawer}
        />
      }

      <LeadDetailWrapper
        open={openUserDrawer}
        setOpen={setOpenUserDrawer}
        selectedLead={selectedLead}
        setSelectedLead={setSelectedLead}
        handleSelect={handleSelectLead}
        count={leadListData?.count}
      />

      <LeadStatusCreateWrapper
        open={leadStatusCreateOpen}
        setOpen={setLeadStatusCreateOpen}
      />

      <LeadSourcesCreateWraper
        open={leadSourceCreateOpen}
        setOpen={setLeadSourceCreateOpen}
      />

      <LeadCreateDrawerWrapper
        open={openLeadCreateDrawer}
        setOpen={setOpenLeadCreateDrawer}
        
      />

      <LeadUpdateDrawerWrapper
        open={openLeadUpdateDrawer}
        setOpen={setOpenLeadUpdateDrawer}
        selectedLead={selectedLead}
        setSelectedLead={setSelectedLead}
        openLeadStatusesDrawer={openLeadStatusesDrawer}
        setOpenLeadStatusesDrawer={setOpenLeadStatusesDrawer}
        openLeadSourcesDrawer={openLeadSourcesDrawer}
        setOpenLeadSourcesDrawer={setOpenLeadSourcesDrawer}
      />
    </Grid >
  );
});

export default List;
