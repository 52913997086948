import { ProductsAndServicesDomainLayout } from "../layouts";
import { CategoriesRouter } from "../subdomains/categories/routes/CategoriesRouter";
import { ProductsRouter } from "../subdomains/products/routes/ProductsRouter";
import { ServicesRouter } from "../subdomains/services/routes/ServicesRouter";
import { productsAndServicesDomainRoutes } from "./productsAndServices.base.route";
import { Route, Routes } from "react-router-dom";

export const ProductsAndServicesDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ProductsAndServicesDomainLayout />}>
        <Route index element={<ProductsRouter />} />
        <Route
          path={`${productsAndServicesDomainRoutes.subdomains.products.base}/*`}
          element={<ProductsRouter />}
        />
        <Route
          path={`${productsAndServicesDomainRoutes.subdomains.services.base}/*`}
          element={<ServicesRouter />}
        />
                <Route
          path={`${productsAndServicesDomainRoutes.subdomains.categories.base}/*`}
          element={<CategoriesRouter />}
        />
      </Route>
    </Routes>
  );
};
