import { Avatar, Box, Grid, InputLabel, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { compressImage, saveImageAndGetUrl } from "utils/imageUpload";


const List = () => {

  const [profileImageFile, setProfileImageFile] = useState<any>(null)
  const [profileImageThumbnail, setProfileImageThumbnail] = useState<any>(null)

  const handleProfileImageChange = async (e: any) => {
    const file = e.target.files[0];

    const compressedProfileImageFile = await compressImage(file, 0.5, 1000)
    const compressedProfileImageThumbnail = await compressImage(file, 0.5, 200)

    setProfileImageFile(compressedProfileImageFile)
    setProfileImageThumbnail(compressedProfileImageThumbnail)
  }


  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h5">Profile</Typography>
      </Grid>
      <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>Customer Picture*</InputLabel>
        <Box
          onClick={() => document.getElementById('profile-image-input')?.click()}
          sx={{ width: 'calc(100% - 20px)', border: '1px dashed', borderColor: "primary.light", borderRadius: '16px', p: 1, flexGrow: 1 }}>
          <Stack direction="column" justifyContent="center" alignItems="center" gap={1} sx={{ width: '100%', height: '100%' }}>
            <Avatar
              sx={{ width: 50, height: 50, padding: 2, backgroundColor: "background.default" }}
              src={"https://cdn-icons-png.flaticon.com/128/9261/9261196.png"}
            />
            <Typography variant="body2"> {"Upload a picture"}</Typography>
          </Stack>
        </Box>
        <input
          id="profile-image-input"
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleProfileImageChange}
        />
      </Grid>
    </Grid>
  );
};

export default List;
function imageCompress(arg0: { file: any; enqueueSnackbar: any; setCompressedImage: any; ratio: number; maxWidth: number; }) {
  throw new Error("Function not implemented.");
}

