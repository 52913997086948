/* eslint-disable react-hooks/exhaustive-deps */
import {  Box, Button, CircularProgress, Grid, IconButton, InputLabel, Stack, SvgIcon, SwipeableDrawer, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { getCurrentLanguage } from "localization";
import { IGraphqlVariables, unknownError } from "corede-common";
import { IProductCategoryCreateInput } from "corede-common-cocrm";
import { validateCreateCategoriesInput } from "../validations/create.validation";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useProductCategoryCreateMutation, useProductCategoryListQuery } from "../context/categories.api";
import * as Icons from '@mui/icons-material';

interface IProductCategoryCreateDrawer {
    open: boolean
    setOpen: (open: boolean) => void
}

const ProductCategoryCreateDrawer = (props: IProductCategoryCreateDrawer) => {
    // general

    const iconsContainerRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    const currentLanguage = getCurrentLanguage();
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [visibleIcons, setVisibleIcons] = useState<string[]>([]);

    // queries
    const [productCategoryCreate, { data: productCategoryCreateData, isLoading: productCategoryCreateLoading, error: productCategoryCreateError }] = useProductCategoryCreateMutation();
    // mutations

    // constants
    const { values, handleSubmit, handleBlur, setFieldValue, errors, touched, resetForm, isValid } =
        useFormik({
            initialValues: {
                input: {
                    name: "",
                    nameTranslation: {
                        en: "",
                        tr: ""
                    },
                    icon: "CircleOutlined",
                },
            },
            enableReinitialize: true,
            validationSchema: validateCreateCategoriesInput,
            onSubmit: async (values: IGraphqlVariables<IProductCategoryCreateInput>) => {
                setLoading(true);
                const transformedValues =
                    RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
                        input: values,
                    });
                await productCategoryCreate(transformedValues as IGraphqlVariables<IProductCategoryCreateInput>)
                setLoading(false);
            }
        });

    const filteredIcons = Object.keys(Icons).filter((iconName) =>
        iconName.endsWith('Outlined') && iconName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        setVisibleIcons(filteredIcons.slice(0, 50));
    }, [searchQuery]);

    const handleShowMoreIcons = () => {
        setVisibleIcons((prevVisibleIcons) => {
            const currentLength = prevVisibleIcons?.length;
            const nextIcons = filteredIcons.slice(0, currentLength + 50);
            return nextIcons;
        });
    };

    const handleIconSelect = (iconName: string) => {
        setFieldValue("input.icon", iconName);
    };

    // useEffects.success

    useEffect(() => {
        if (productCategoryCreateData) {
            enqueueSnackbar(t("crm.productsAndServices.categories.createSuccess"), { variant: 'success' });
            resetForm();
            props.setOpen(false);
        }
    }, [productCategoryCreateData, resetForm]);

    // useEffects.error

    useEffect(() => {
        if (productCategoryCreateError) {
            const errorMessage = (productCategoryCreateError as any)?.error?.message?.[currentLanguage] || unknownError.message[currentLanguage];
            enqueueSnackbar(errorMessage, { variant: 'error' });
        }
    }, [productCategoryCreateError]);


    return <SwipeableDrawer
        open={props.open}
        onOpen={() => props.setOpen(true)}
        onClose={() => props.setOpen(false)}
        anchor='right'
    >
        <Grid item xs={12} p={3} sx={{ width: "400px" }}>
            <Typography variant="h4" gutterBottom>
                {t("crm.productsAndServices.categories.create")}
            </Typography>
            <Box sx={{ mt: 4, width: 400, }} />
            <Stack direction="column" gap={1} mt={1}>
                <Grid container spacing={5}>
                    <Grid item xs={12} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                            {t("crm.productsAndServices.categories.name")} *
                        </InputLabel>
                        <TextField
                            fullWidth
                            name="input.name"
                            value={(values as any).input.name}
                            onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                            onBlur={handleBlur}
                            error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                            helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                            {t("crm.productsAndServices.categories.en")}
                        </InputLabel>
                          <TextField
                            fullWidth
                            name="input.nameTranslation.tr"
                            value={(values as any).input.nameTranslation?.en}
                            onChange={(e) => setFieldValue("input.nameTranslation.en", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                            onBlur={handleBlur}
                            error={!!((errors as any)?.input?.nameTranslation?.en && (touched as any)?.input?.nameTranslation?.en)}
                            helperText={(touched as any)?.input?.nameTranslation?.en && (errors as any)?.input?.nameTranslation?.en}
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                            {t("crm.productsAndServices.categories.tr")}
                        </InputLabel>
                        <TextField
                            fullWidth
                            name="input.nameTranslation.tr"
                            value={(values as any).input.nameTranslation?.tr}
                            onChange={(e) => setFieldValue("input.nameTranslation.tr", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                            onBlur={handleBlur}
                            error={!!((errors as any)?.input?.nameTranslation?.tr && (touched as any)?.input?.nameTranslation?.tr)}
                            helperText={(touched as any)?.input?.nameTranslation?.tr && (errors as any)?.input?.nameTranslation?.tr}
                        />
                    </Grid>
                </Grid>
                <Grid container borderRadius={1} sx={{ overflow: 'auto' }}>
                    <Grid item xs={12} mb={-2}>
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.productsAndServices.categories.icon")}</InputLabel>
                    </Grid>
                    <Grid item xs={12} mt={2} sx={{ border: '1px solid', borderColor: 'grey.400', borderRadius: '16px' }}>
                        <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={1} sx={{ p: 1 }}>
                            <Typography fontSize={"14px"} fontWeight={"bold"} textAlign={"center"} mt={1}>
                                Selected Icon:
                            </Typography>
                            <SvgIcon component={(Icons as any)[(values as any).input.icon]} sx={{ color: "primary.main", fontSize: "48px", opacity: 0.7 }} />
                        </Stack>
                        <Box sx={{ height: 260, overflow: 'auto', display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'flex-start' }} ref={iconsContainerRef}>
                            <TextField
                                placeholder="Search for an icon"
                                variant="outlined"
                                sx={{
                                    width: '70%',
                                    '& .MuiOutlinedInput-root': {
                                        height: 30,
                                        fontSize: '12px',
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '12px',
                                    }
                                }}
                                margin="normal"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <Box sx={{ maxHeight: 180, ml: 1, overflow: 'auto', mt: 1, display: 'flex', flexDirection: "column", justifyContent: 'center' }}>
                                <Grid container spacing={1}>
                                    {visibleIcons.map((iconName) => {
                                        const IconComponent = (Icons as any)[iconName];
                                        return (
                                            <Grid item xs={2} key={iconName}>
                                                <IconButton onClick={() => handleIconSelect(iconName)}>
                                                    <IconComponent />
                                                </IconButton>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                                {visibleIcons?.length < filteredIcons?.length && (
                                    <Button onClick={handleShowMoreIcons} sx={{ mt: 2, alignSelf: 'center' }}>
                                        {t("crm.lead.leadSources.showMore")}
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={5} pt={1.5}>
                    <Grid item xs={12} >
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => handleSubmit()}
                            disabled={loading || !isValid || productCategoryCreateLoading || !(values as any).input?.name}
                        >
                            {(loading || productCategoryCreateLoading) ? <CircularProgress size="1rem" /> : t("crm.productsAndServices.categories.create")}
                        </Button>
                    </Grid>
                </Grid>
            </Stack >
        </Grid >
    </SwipeableDrawer>
}

export default ProductCategoryCreateDrawer