import { useState, lazy, Suspense } from 'react';


const UserCreateDrawer = lazy(() => import('./UserCreateDrawer'));


const UserCreateWrapper = ({
    open,
    setOpen,
    setOpenRolesDrawer
}: any) => {

    const [mounted, setMounted] = useState(false);

    if (open && !mounted) {
        setMounted(true);
    }

    return (
        <>
            {mounted && (
                <Suspense>
                    < UserCreateDrawer
                        open={open}
                        setOpen={setOpen}
                        setOpenRoles={setOpenRolesDrawer}
                    />
                </Suspense>
            )}
        </>
    );
};

export default UserCreateWrapper;