/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack, Skeleton, Typography, Avatar, Box, Chip, Button, Slider, useMediaQuery, Backdrop } from "@mui/material";
import { memo, Suspense, useEffect, useState } from "react";
import { AccountTreeRounded, TocRounded, ZoomOut, ZoomIn, AccountTreeOutlined } from "@mui/icons-material";
import Tree from 'react-d3-tree';
import 'react-modern-drawer/dist/index.css'
import UserList from "./UserList";
import { useOrganizationChartDetailQuery, useOrganizationChartUpdateMutation, useUserListQuery } from "../context/employees.api";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { OrganizationalChartHeader } from "components";
import { unknownError } from "corede-common";
import { getCurrentLanguage } from "localization";
import { RolesDrawer } from "../../permissions";
import { useUserDetailOwnQuery } from "apps/auth/context";
import UserDetailWrapper from "./UserDetailWrapper";
import ChartEditWrapper from "./ChartEditWrapper";
import UserCreateWrapper from "./UserCreateWrapper";
import ChartEditDrawer from "./ChartEditDrawer";

const convertLikeOrgChart = (items: any) => {
  const result = items.map((item: any) => {
    return {
      user: {
        _id: item?.user?._id,
      },
      children: item?.children ? convertLikeOrgChart(item.children) : [],
    };
  });
  return result;
}

const collectChartUserIds = (node: any, ids: string[] = []): string[] => {
  if (node && node.user && node.user._id) {
    ids.push(node.user._id);
  }

  if (node && node.children && Array.isArray(node.children)) {
    node.children.forEach((child: any) => collectChartUserIds(child, ids));
  }

  return ids;
};

const List = memo(() => {
  // general
  const { t } = useTranslation();
  const [openUserDetail, setOpenUserDetail] = useState(false);
  const [openChartEdit, setOpenChartEdit] = useState(false);
  const [openRolesDrawer, setOpenRolesDrawer] = useState(false);
  const [openCreateUserDrawer, setOpenCreateUserDrawer] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [zoom, setZoom] = useState(0.725);
  const [treeTranslate, setTreeTranslate] = useState({ x: 350, y: 100 });
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const [viewMode, setViewMode] = useState('tree');
  const { data: userListData, error: userListError, refetch: refetchUserList } = useUserListQuery({});
  const { data: organizationChartDetailData, error: organizationChartDetailError, refetch: refetchOrganizationChart } = useOrganizationChartDetailQuery({});
  const [organizationChartUpdate, { data: organizationChartUpdateData }] = useOrganizationChartUpdateMutation();
  const { data: userDetailOwnData } = useUserDetailOwnQuery({});
  const [convertedChart, setConvertedChart] = useState<any>({});
  const [chartUserIds, setChartUserIds] = useState<string[]>([]);
  const [notAddedUsers, setNotAddedUsers] = useState<any[]>([]);
  const currentLanguage = getCurrentLanguage();

  const handleChangeZoom = (event: Event, newValue: number | number[]) => {
    setZoom(newValue as number);
  };

  function handleSelectUser(direction: 'next' | 'back') {
    if (!selectedUser || !userListData?.data?.length) return;

    const currentIndex = userListData.data.findIndex(user => user._id === selectedUser._id);

    let newIndex = currentIndex;
    if (direction === 'next') {
      newIndex = currentIndex + 1;
    } else if (direction === 'back') {
      newIndex = currentIndex - 1;
    }

    if (newIndex >= 0 && newIndex < userListData.data?.length) {
      const newSelectedUser = { ...userListData.data[newIndex], id: newIndex + 1 };
      setSelectedUser(newSelectedUser);
    }
  }


  useEffect(() => {
    if (!openUserDetail) {
      setTimeout(() => {
        setSelectedUser(null);
      }, 300);
    }
  }, [openUserDetail]);

  useEffect(() => {
    if (downMd) {
      setZoom(0.5)
      setTreeTranslate({ x: 150, y: 100 })
    } else {
      setZoom(0.725)
      setTreeTranslate({ x: 350, y: 100 })
    }
  }, [downMd]);

  useEffect(() => {
    if (organizationChartDetailData) {
      setConvertedChart(organizationChartDetailData.chart)
      setChartUserIds(collectChartUserIds(organizationChartDetailData?.chart))
    }

  }, [organizationChartDetailData]);


  useEffect(() => {
    if (chartUserIds?.length > 0) {
      setNotAddedUsers(userListData?.data.filter(
        user => user.status === "active" && !chartUserIds.includes(user._id)
      ) ?? [])
    }
  }, [chartUserIds]);

  useEffect(() => {
    if (notAddedUsers?.length > 0) {
      organizationChartUpdate({
        input: {
          chart: convertLikeOrgChart([organizationChartDetailData?.chart])?.map((item: any, index: number) => {
            return {
              user: {
                _id: item?.user?._id,
              },
              children: item?.children
                ? [
                  ...convertLikeOrgChart(item.children),
                  ...(index === 0 ? notAddedUsers.map((user) => ({ user: { _id: user._id }, children: [] })) : [])
                ]
                : [],
            };
          })?.[0]
        },
      });
    }
  }, [notAddedUsers]);

  useEffect(() => {
    if (organizationChartUpdateData) {
      refetchOrganizationChart().then(() => {
        refetchUserList().then(() => {
          enqueueSnackbar(t("crm.organizationalChart.employees.updateChartSuccessfully"), { variant: 'success' });
        })
      })
    }
  }, [organizationChartUpdateData])

  useEffect(() => {
    if (userListError) {
      enqueueSnackbar(t((userListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage]), { variant: 'error' });
    }
  }, [userListError])

  useEffect(() => {
    if (organizationChartDetailError) {
      enqueueSnackbar(t((organizationChartDetailError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage]), { variant: 'error' });
    }
  }, [organizationChartDetailError])

  return (
    <Grid item xs={12}>
      <OrganizationalChartHeader
        userListData={userListData}
        setOpenCreateUserDrawer={setOpenCreateUserDrawer}
      />
      <Stack direction="row" alignItems={"center"} justifyContent={{ xs: "space-between", md: "flex-start" }} mt={2} gap={{ xs: 1, md: 2 }}>
        <Button
          variant="outlined"
          color="primary"
          sx={{ width: { xs: 90, md: "auto" }, height: 40, fontSize: { xs: "11px", md: "13px" } }}
          onClick={() => setViewMode(viewMode === "tree" ? "list" : "tree")}
        >
          {!downMd && viewMode === "list" ? <AccountTreeRounded sx={{ mr: 1, opacity: 0.7 }} fontSize="small" /> : <TocRounded sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />}
          {viewMode === "tree" ? t("crm.organizationalChart.employees.list") : t("crm.organizationalChart.employees.chart")}
        </Button>

        {!downMd && viewMode === "tree" && <Stack direction="row"
          sx={{ border: "1px solid", borderColor: "primary.main", px: 1, borderRadius: "14px", width: 200, height: "36px" }}
          alignItems="center">
          <ZoomOut fontSize="small" sx={{ opacity: 0.7 }} color="primary" />
          <Slider
            aria-label={t("crm.organizationalChart.employees.zoom")}
            value={zoom}
            color="primary"
            size="small"
            sx={{ opacity: 0.7 }}
            onChange={handleChangeZoom}
            min={0.5}
            max={1}
            step={0.02}
          />
          <ZoomIn fontSize="small" sx={{ opacity: 0.7 }} color="primary" />
        </Stack>}
        {viewMode === "tree" && <Button
          variant="outlined"
          color="primary"
          sx={{ width: { xs: 90, md: "auto" }, height: 40, fontSize: { xs: "11px", md: "13px" } }}
          onClick={() => setOpenChartEdit(true)}
        >
          {!downMd && <AccountTreeOutlined sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />}
          {t("crm.organizationalChart.employees.editChart")}
        </Button>}
      </Stack>
      {downMd && <Stack direction="row" gap={1} justifyContent={"center"}
        sx={{
          border: "1px solid", borderColor: "primary.main", mt: 1, borderRadius: "14px", width: "100%",
          height: "36px",
        }}
        alignItems="center">
        <ZoomOut fontSize="small" sx={{ opacity: 0.7 }} />
        <Slider
          aria-label={t("crm.organizationalChart.employees.zoom")}
          value={zoom}
          color="primary"
          size="small"
          sx={{ opacity: 0.7 }}
          onChange={handleChangeZoom}
          min={0.4}
          max={1}
          step={0.02}
        />
        <ZoomIn fontSize="small" sx={{ opacity: 0.7 }} />
      </Stack>}
      <Stack direction="row" justifyContent="space-between">
        {viewMode === "tree" ?
          <div id="treeWrapper" style={{
            width: '100%', height: 'calc(100dvh - 360px)',
            marginTop: 10,
            border: "1px solid",
            borderColor: "#d3d3d3",
            borderRadius: "16px",
            backgroundColor: "rgba(238, 238, 238, 0)",
            background:
              "linear-gradient(135deg, rgba(213, 213, 213, 0.1) 25%, transparent 25%) -28px 0/ 56px 56px, linear-gradient(225deg, rgba(213, 213, 213, 0.1) 25%, transparent 25%) -28px 0/ 56px 56px, linear-gradient(315deg, rgba(213, 213, 213, 0.1) 25%, transparent 25%) 0px 0/ 56px 56px, linear-gradient(45deg, rgba(213, 213, 213, 0.1) 25%, rgba(238, 238, 238, 0.1) 25%) 0px 0/ 56px 56px"
          }}>
            <Tree
              data={convertedChart}
              orientation="vertical"
              pathFunc={"step"}
              zoom={zoom}
              translate={treeTranslate}
              separation={{ siblings: 2, nonSiblings: 2 }}
              pathClassFunc={() => 'link'}
              enableLegacyTransitions={true}
              renderCustomNodeElement={(rd3tProps) => {
                const { nodeDatum } = rd3tProps;
                const width = 200;
                return (
                  <g>
                    <foreignObject
                      width={width}
                      height="80"
                      x={-width / 2}
                      onClick={() => {
                        setSelectedUser((nodeDatum as any)?.user)
                        setOpenUserDetail(true)
                      }}
                      style={{ overflow: "visible" }}
                    >
                      <div style={{
                        width: '100%', height: '100%', overflow: 'hidden', borderRadius: '16px',
                        boxShadow: selectedUser?.name === nodeDatum.name ? "0px 2px 4px  rose" : '0px 2px 4px rgba(0,0,0,0.1)'
                      }}>

                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            py: 1,
                            height: 80,
                            bgcolor: "primary.lighter",
                            borderRadius: "10px",
                            textAlign: "center",
                            boxShadow: 1,
                            borderTop: "5px solid",
                            borderTopColor: "secondary.main",
                          }}
                        >
                          <Stack direction="row" justifyContent="center" alignItems="center" gap={2} width={"90%"}>
                            <Avatar
                              alt={nodeDatum.name}
                              src={nodeDatum.attributes?.avatar?.toString()}
                              sx={{ width: 40, height: 40, mb: 1 }}
                            />
                            <Stack direction="column" justifyContent="center" alignItems="center" gap={1}>
                              <Typography sx={{ fontWeight: "bold", fontSize: "12px", color: "primary.main" }}>
                                {(nodeDatum as any).user?.name} {(nodeDatum as any).user?.surname}
                              </Typography>
                              <Chip
                                label={userDetailOwnData?._id === (nodeDatum as any).user?._id ? t("crm.organizationalChart.employees.admin") : (nodeDatum as any).user?.department?.name}
                                size="small"
                                sx={{ fontSize: "10px", fontWeight: "bold" }}
                                color="secondary"
                              />
                            </Stack>
                          </Stack>
                        </Box>
                      </div>
                    </foreignObject>
                  </g>
                );
              }}
            />
          </div>
          : viewMode === "list" ?
            <UserList />
            : [1, 2, 3].map((key) => (
              <Stack
                key={key}
                direction="row"
                justifyContent="space-between"
                width={340}
                alignItems={"center"}
                p={2}
              >
                <Skeleton
                  variant="rectangular"
                  width={340}
                  height={60}
                  sx={{ borderRadius: 2 }}
                />
              </Stack>
            ))}
      </Stack>

      <UserDetailWrapper
        open={openUserDetail}
        setOpen={setOpenUserDetail}
        selectedUser={selectedUser}
        handleSelect={handleSelectUser}
      />

      <ChartEditWrapper
        open={openChartEdit}
        setOpen={setOpenChartEdit}
        orgChartData={convertedChart}
        setConvertedChart={setConvertedChart}
      />

      <UserCreateWrapper
        open={openCreateUserDrawer}
        setOpen={setOpenCreateUserDrawer}
        setOpenRoles={setOpenRolesDrawer}
      />


      <RolesDrawer
        open={openRolesDrawer}
        setOpen={setOpenRolesDrawer}
      />




    </Grid >
  );
});

export default List;
