
import { useState, lazy, Suspense } from 'react';

const LeadCreateDrawer = lazy(() => import('./CreateDrawer'));

const LeadCreateDrawerWrapper = ({ 
    open, 
    setOpen,
    leadStatusCreateOpen,
    setLeadStatusCreateOpen,
    leadSourceCreateOpen,
    setLeadSourceCreateOpen
 }: any) => {
    const [mounted, setMounted] = useState(false);

    if (open && !mounted) {
        setMounted(true);
    }

    return (
        <>
            {mounted && (
                <Suspense>
                    <LeadCreateDrawer 
                    open={open} 
                    setOpen={setOpen} 
                    leadStatusCreateOpen={leadStatusCreateOpen}
                    setLeadStatusCreateOpen={setLeadStatusCreateOpen}
                    leadSourceCreateOpen={leadSourceCreateOpen}
                    setLeadSourceCreateOpen={setLeadSourceCreateOpen}
                    />
                </Suspense>
            )}
        </>
    );
};

export default LeadCreateDrawerWrapper;
