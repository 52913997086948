import customersLocale from "../subdomains/customers/locales/en";
import customerCategoriesLocale from "../subdomains/customerCategories/locales/en";
import contactsLocale from "../subdomains/contacts/locales/en";

const locale = {
  // Todo
  // employees: {
  //   ...employeesLocale,
  // },
  customers: {
    ...customersLocale,
  },
  contacts: {
    ...contactsLocale,
  },
  customerCategories: {
    ...customerCategoriesLocale,
  },
};

export default locale;
