/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from "@mui/icons-material";
import { Box, Grid, IconButton, Stack, SvgIcon, } from "@mui/material";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { useCallback, useEffect, useState } from "react";
import { DataGridPremium, GridColDef, useGridApiRef, GridCellModes, GridCellModesModel, GridCellParams } from "@mui/x-data-grid-premium";
import { tableStyle } from "apps/crm/domains/03-lead/subdomains/leadStatuses";
import { trTR } from '@mui/x-data-grid/locales';
import { getCurrentLanguage } from "localization";
import { Language, unknownError } from "corede-common";
import { useCustomerCategoryListQuery, useCustomerCategoryUpdateMutation } from "../context/customerCategories.api";
import * as Icons from '@mui/icons-material';
import { IBaseLeadSourceEntity } from "corede-common-cocrm";
import EmptyState from "components/emptyState/EmptyState";
import { RolesDrawer } from "../../../../02-organizationalChart/subdomains/permissions";
import { DeleteDialog } from "./DeleteDialog";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import CustomerCategoryUpdateWraper from "./CustomerCategoryUpdateWraper";
import CustomerCategoryCreateWraper from "./CustomerCategoryCreateWraper";

const List = () => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const apiRef = useGridApiRef();
  const [openCustomerCategoryCreateDrawer, setOpenCustomerCategoryCreateDrawer] = useState(false);
  const [openCustomerCategoryUpdateDrawer, setOpenCustomerCategoryUpdateDrawer] = useState(false);
  const [selectedItemForDelete, setSelectedItemForDelete] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any>();
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const [openRolesDrawer, setOpenRolesDrawer] = useState(false);

  const handleCellClick = useCallback((params: GridCellParams, event: React.MouseEvent) => {

    if (params.field === "name") {
      setCellModesModel((prevModel) => {
        return {
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View }
                }),
                {}
              )
            }),
            {}
          ),
          [params.id]: {
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit }
          }
        };
      });
    }

  }, []);



  const handleCellModesModelChange = useCallback((newModel: any) => {
    setCellModesModel(newModel);
  }, []);

  const { data: customerCategoryListData, isLoading: customerCategoryListLoading, isFetching: customerCategoryListFetching, error: customerCategoryListError } = useCustomerCategoryListQuery({
    input: {
      filter: {}
    }
  })
  const [customerCategoryUpdate, { isLoading: customerCategoryUpdateLoading, error: customerCategoryUpdateError }] = useCustomerCategoryUpdateMutation()

  const [rows, setRows] = useState<any[]>(customerCategoryListData?.data?.map((customerCategory, index) => ({
    id: index + 1,
    _id: customerCategory._id,
    name: customerCategory.name || '-',
    icon: customerCategory.icon || '-',
    parentCategory: customerCategory?.parentCategory?._id || '-',
  })) || []);


  const columns: GridColDef[] = [
    { field: 'id', headerName: t("crm.customer.customerCategories.columnId"), width: 50, disableColumnMenu: true, filterable: false, align: 'center', headerAlign: 'center' },
    { field: 'name', headerName: t("crm.customer.customerCategories.columnName"), width: 160, editable: true },
    {
      field: 'icon', headerName: t("crm.customer.customerCategories.columnIcon"), width: 100, disableColumnMenu: true, filterable: false, align: 'center', headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <SvgIcon component={(Icons as any)[params.value]} fontSize="small" sx={{ mt: 2, ml: 0.5 }} />
        )
      }
    },
    { field: 'parentCategory', headerName: t("crm.customer.customerCategories.columnParentCategory"), width: 160, disableColumnMenu: true, filterable: false, align: 'center', headerAlign: 'center' },
    {
      field: 'actions',
      headerName: t("crm.customer.customerCategories.columnActions"),
      sortable: false,
      width: 120,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"center"} mt={{ xs: 0, md: 2 }}>
            <IconButton
              onClick={() => {
                setSelectedRow(params?.row);
                setOpenCustomerCategoryUpdateDrawer(true);
              }}
              color="info"
              sx={{ p: "4px", }}
            >
              <Icons.EditNoteRounded color="info" fontSize="small" />
            </IconButton>
            <IconButton
              onClick={() => handleDeleteRow(params?.row)}
              color="error"
              sx={{ p: "4px", }}
            >
              <Icons.DeleteOutlineRounded color="error" fontSize="small" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];


  const handleDeleteRow = (item: IBaseLeadSourceEntity) => {
    setSelectedItemForDelete(item);
  }


  const handleProcessRowUpdate = (newRow: any, oldRow: any) => {
    if (newRow.name !== oldRow.name) {
      customerCategoryUpdate({ filter: { _id: newRow._id }, input: { name: newRow.name } });
    }
    if (newRow.icon !== oldRow.icon) {
      customerCategoryUpdate({ filter: { _id: newRow._id }, input: { name: newRow.name, icon: newRow.icon } });
    }
    return newRow;
  };

  useEffect(() => {
    if (customerCategoryListData?.data) {
      setRows(customerCategoryListData?.data?.map((customerCategory, index) => ({
        id: index + 1,
        _id: customerCategory._id,
        name: customerCategory.name || '-',
        icon: customerCategory.icon || '-',
        parentCategory: customerCategory?.parentCategory?._id || '-',
      })))
    }
  }, [customerCategoryListData?.data])


  useEffect(() => {
    if (customerCategoryListError) {
      enqueueSnackbar((customerCategoryListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [customerCategoryListError]);

  useEffect(() => {
    if (customerCategoryUpdateError) {
      enqueueSnackbar((customerCategoryUpdateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [customerCategoryUpdateError]);

  return <Grid item xs={12}>
    <HeaderBase
      title={t("crm.customer.customerCategories.headerTitle")}
      rightButton={{
        title: t("crm.customer.customerCategories.createCustomerCategory"),
        onClick: () => {
          setOpenCustomerCategoryCreateDrawer(true);
        },
        icon: <Add sx={{ mr: 1 }} />,
      }}
    />

    {customerCategoryListData?.data?.length !== 0 ? <Box minHeight={"350px"} maxHeight={"calc(100vh - 200px)"} mt={1} overflow={"auto"} borderRadius={"16px"}>
      <DataGridPremium
        localeText={currentLanguage === Language.tr ? trTR.components.MuiDataGrid.defaultProps.localeText : undefined}
        apiRef={apiRef}
        cellModesModel={cellModesModel}
        onCellClick={handleCellClick}
        onCellModesModelChange={handleCellModesModelChange}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        showColumnVerticalBorder
        disableAggregation
        disableRowGrouping
        hideFooter
        processRowUpdate={handleProcessRowUpdate}
        loading={customerCategoryListLoading || customerCategoryUpdateLoading || customerCategoryListFetching}
        onCellDoubleClick={(params, event) => {
          if (params.field === 'icon') {
            setOpenCustomerCategoryUpdateDrawer(true);
            setSelectedRow(params.row);
            event.stopPropagation();
          }
          if (params.field === 'parentCategory') {
            setSelectedRow(params.row);
            setOpenCustomerCategoryUpdateDrawer(true);
            event.stopPropagation();
          }
        }}
        scrollbarSize={1}
        scrollEndThreshold={10}
        pinnedColumns={{ left: ['id'], right: ['actions'] }}
        rowsLoadingMode="server"
        sx={[tableStyle, {
          backgroundColor: 'background.secondary', minHeight: "350px",
          '&. .MuiDataGrid-topContainer': {
            border: "2px solid #000"
          },
        }]}
      />
    </Box> :
      <EmptyState
        content1={t("crm.customer.customerCategories.emptyStateContent1")}
        content2={t("crm.customer.customerCategories.emptyStateContent2")}
        button1={{
          title: t("crm.customer.customerCategories.createCustomerCategory"),
          onClick: () => {
            setOpenCustomerCategoryCreateDrawer(true);
          },
          leftIcon: <Add />
        }}
      />
    }


    <CustomerCategoryCreateWraper
      open={openCustomerCategoryCreateDrawer}
      setOpen={setOpenCustomerCategoryCreateDrawer}
    />

    <CustomerCategoryUpdateWraper
      open={openCustomerCategoryUpdateDrawer}
      setOpen={setOpenCustomerCategoryUpdateDrawer}
      selectedRow={selectedRow}
    />

    <RolesDrawer
      open={openRolesDrawer}
      setOpen={setOpenRolesDrawer}
    />

    <DeleteDialog
      open={Boolean(selectedItemForDelete)}
      onClose={() => setSelectedItemForDelete(undefined)}
      item={selectedItemForDelete}
    />

  </Grid>;
};

export default List;
