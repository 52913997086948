import servicesLocale from "../subdomains/services/locales/en";
import productsLocale from "../subdomains/products/locales/en";
import categoriesLocale from "../subdomains/categories/locales/en";

const locale = {
  products: {
    ...productsLocale,
  },
  categories: {
    ...categoriesLocale,
  },
  services: {
    ...servicesLocale,
  },
};

export default locale;
