/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import { IBaseError } from "corede-common";
import { IBaseLeadEntity } from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { enqueueSnackbar } from "notistack";
import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { useLeadDeleteMutation } from "../context/lead.api";

export const DeleteDialog = memo((prop: {
  open: boolean;
  item: IBaseLeadEntity | undefined;
  onClose: () => void;
}) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries

  // mutations
  const [leadDelete, { data: leadDeleteData, isLoading: leadDeleteIsLoading, error: leadDeleteError }] = useLeadDeleteMutation();

  // constants

  // // action handlers
  const handleDelete = (_id: string) => {
    leadDelete({ input: { _id: _id } });
  };

  // useEffects.success
  useEffect(() => {
    if (leadDeleteData) {
      prop?.onClose();
      enqueueSnackbar(t("crm.lead.lead.deletedSuccessfully"), {
        variant: "success",
      });
    }
  }, [leadDeleteData]);

  // // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      leadDeleteError as IBaseError,
      currentLanguage
    );
  }, [leadDeleteError, currentLanguage]);

  // useEffects.init

  return (
    <Dialog
      open={prop?.open}
      onClose={prop?.onClose}
      sx={{
        "& .MuiDialog-paper": {
          padding: 1,
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle>{t("crm.lead.lead.delete")} | {prop?.item?.fullName}</DialogTitle>
      <DialogContent>
        <Typography>
          {t("crm.lead.lead.deleteConfirm")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={prop?.onClose} color="primary">
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="error"
          disabled={leadDeleteIsLoading}
          onClick={() => {
            handleDelete(prop?.item?._id as string);
          }}
        >
          {t("delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
