import { Outlet } from "react-router";
import { useTranslation } from "react-i18next";
import { useDashboardDomainMenuItem } from "../domains/01-dashboard/menu/items";
import { useOrganizationalChartDomainMenuItem } from "../domains/02-organizationalChart/menu/items";
import { useLeadDomainMenuItem } from "../domains/03-lead/menu/items";
import { useCustomerDomainMenuItem } from "../domains/04-customer/menu/items";
import { usePreSalesDomainMenuItem } from "../domains/05-preSales/menu/items";
import { useSalesDomainMenuItem } from "../domains/06-sales/menu/items";
import { useContractsDomainMenuItem } from "../domains/07-contracts/menu/items";
import { useProductsAndServicesDomainMenuItem } from "../domains/08-productsAndServices/menu/items";
import { useExpensesDomainMenuItem } from "../domains/09-expenses/menu/items";
import { useProjectsDomainMenuItem } from "../domains/10-projects/menu/items";
import { useTasksDomainMenuItem } from "../domains/11-tasks/menu/items";
import { useCalendarDomainMenuItem } from "../domains/12-calendar/menu/items";
import { useReportsDomainMenuItem } from "../domains/13-reports/menu/items";
import { useSupportDomainMenuItem } from "../domains/14-support/menu/items";
import MainLayout from "layouts/MainLayout";


const CrmLayout = () => {

  const { t } = useTranslation();

  const treeItems = [
    {
      itemId: useDashboardDomainMenuItem().path,
      label: t("crm.dashboardd"),
      href: useDashboardDomainMenuItem().path,
      children: useDashboardDomainMenuItem().children?.map((child) => ({
        itemId: child.title,
        label: child.title,
        href: child.path,
      })),
    },
    {
      itemId: useOrganizationalChartDomainMenuItem().path,
      label: t("crm.organizationalChartt"),
      children: useOrganizationalChartDomainMenuItem().children?.map((child: any) => ({
        itemId: child.title,
        label: child.title,
        href: child.path,
      })),
    },
    {
      itemId: useLeadDomainMenuItem().path,
      label: t("crm.leadd"),
      children: useLeadDomainMenuItem().children?.map((child) => ({
        itemId: child.title,
        label: child.title,
        href: child.path
      })),
    },
    {
      itemId: useCustomerDomainMenuItem().path,
      label: t("crm.customerr"),
      children: useCustomerDomainMenuItem().children?.map((child) => ({
        itemId: child.title,
        label: child.title,
        href: child.path
      })),
    },
    {
      itemId: usePreSalesDomainMenuItem().path,
      label: t("crm.preSaless"),
      children: usePreSalesDomainMenuItem().children?.map((child) => ({
        itemId: child.title,
        label: child.title,
        href: child.path
      })),
    },
    {
      itemId: useSalesDomainMenuItem().path,
      label: t("crm.saless"),
      children: useSalesDomainMenuItem().children?.map((child) => ({
        itemId: child.title,
        label: child.title,
        href: child.path
      })),
    },
    {
      itemId: useContractsDomainMenuItem().path,
      label: t("crm.contractss"),
      href: useContractsDomainMenuItem().path,
      children: useContractsDomainMenuItem().children?.map((child) => ({
        itemId: child.title,
        label: child.title,
        href: child.path
      }))
    },
    {
      itemId: useProductsAndServicesDomainMenuItem().path,
      label: t("crm.productsAndServicess"),
      children: useProductsAndServicesDomainMenuItem().children?.map((child) => ({
        itemId: child.title,
        label: child.title,
        href: child.path
      })),
    },
    {
      itemId: useExpensesDomainMenuItem().path,
      label: t("crm.expensess"),
      href: useExpensesDomainMenuItem().path,
      children: useExpensesDomainMenuItem().children?.map((child) => ({
        itemId: child.title,
        label: child.title,
        href: child.path
      })),
    },
    {
      itemId: useProjectsDomainMenuItem().path,
      label: t("crm.projectss"),
      href: useProjectsDomainMenuItem().path,
      children: useProjectsDomainMenuItem().children?.map((child) => ({
        itemId: child.title,
        label: child.title,
        href: child.path
      })),
    },
    {
      itemId: useTasksDomainMenuItem().path,
      label: t("crm.taskss"),
      href: useTasksDomainMenuItem().path,
      children: useTasksDomainMenuItem().children?.map((child) => ({
        itemId: child.title,
        label: child.title,
        href: child.path
      })),
    },
    {
      itemId: useCalendarDomainMenuItem().path,
      label: t("crm.calendarr"),
      children: useCalendarDomainMenuItem().children?.map((child) => ({
        itemId: child.title,
        label: child.title,
        href: child.path
      })),
    },
    {
      itemId: useReportsDomainMenuItem().path,
      label: t("crm.reportss"),
      href: useReportsDomainMenuItem().path,
      children: useReportsDomainMenuItem().children?.map((child) => ({
        itemId: child.title,
        label: child.title,
        href: child.path
      }))
    },
    {
      itemId: useSupportDomainMenuItem().path,
      label: t("crm.supportt"),
      href: useSupportDomainMenuItem().path,
      children: useSupportDomainMenuItem().children?.map((child) => ({
        itemId: child.title,
        label: child.title,
        href: child.path
      }))
    },
  ];

  return <MainLayout treeItems={treeItems}>
    <Outlet />
  </MainLayout>
}

export default CrmLayout;
