import { Grid, Stack, Typography } from "@mui/material";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { useTranslation } from "react-i18next";
import { OutlinedButton } from "components";
import { importLeadRoute } from "../../../routes/lead.base.route";
import * as Icons from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { memo } from "react";

const Import = memo(() => {

  // general
  const { t } = useTranslation();
  const navigate = useNavigate();


  // queries 

  // success useEffects

  // error useEffects


  return (
    <Grid item xs={12} height={"calc(100vh - 260px)"}>
      <HeaderBase
        title={t(`crm.lead.lead.importTitle`)}
        showBackButton={true}
      />

      <Stack
        alignItems="center"
        justifyContent="center"
        bgcolor={"background.default"}
        gap={1}
        borderRadius={3}
        width={"100%"}
        height={"100%"}
      >
        <Icons.UploadFileOutlined sx={{ opacity: 0.2, fontSize: "100px" }} />
        <Typography variant="body2">{t("crm.lead.lead.importText1")}</Typography>
        <Typography variant="body2">{t("crm.lead.lead.importText2")}</Typography>
        <Stack direction="row" spacing={1} mt={2}>
          <OutlinedButton
            title={t("crm.lead.lead.downloadExample")}
            leftIcon={<Icons.ArticleOutlined sx={{ mr: 1 }} fontSize="small" />}
            onClick={() => navigate(importLeadRoute())}
          />
          <OutlinedButton
            title={t("crm.lead.lead.importLead")}
            leftIcon={<Icons.UploadFileOutlined sx={{ mr: 1 }} fontSize="small" />}
            onClick={() => navigate(importLeadRoute())}
          />
          {/* <input
            type="file"
            hidden
            ref={inputFileRef}
            onChange={(e) => {
              handleImportForm(e.target.files![0]);
            }}
          /> */}
        </Stack>
      </Stack>
    </Grid >
  );
});

export default Import;



