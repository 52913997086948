/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import { IBaseError } from "corede-common";
import { IBaseCustomerEntity } from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { useCustomerDeleteMutation } from "../context/customers.api";

export const DeleteDialog = (prop: {
  open: boolean;
  item: IBaseCustomerEntity | undefined;
  onClose: () => void;
}) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries

  // mutations
  const [customerDelete, { data: customerDeleteData, isLoading: customerDeleteIsLoading, error: customerDeleteError }] = useCustomerDeleteMutation();

  // constants

  // // action handlers
  const handleDelete = (_id: string) => {
    customerDelete({ input: { _id: _id } });
  };

  // useEffects.success
  useEffect(() => {
    if (customerDeleteData) {
      prop?.onClose();
      enqueueSnackbar(t("crm.customer.customers.deletedSuccessfully"), {
        variant: "success",
      });
    }
  }, [customerDeleteData]);

  // // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      customerDeleteError as IBaseError,
      currentLanguage
    );
  }, [customerDeleteError, currentLanguage]);

  // useEffects.init

  return (
    <Dialog
      open={prop?.open}
      onClose={prop?.onClose}
      sx={{
        "& .MuiDialog-paper": {
          padding: 1,
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle>{t("crm.customer.customers.delete")} | {prop?.item?.name}</DialogTitle>
      <DialogContent>
        <Typography>
          {t("crm.customer.customers.deleteConfirm")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={prop?.onClose} color="primary">
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="error"
          disabled={customerDeleteIsLoading}
          onClick={() => {
            handleDelete(prop?.item?._id as string);
          }}
        >
          {t("delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
