/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import { IBaseError } from "corede-common";
import { IBaseCustomerCategoryEntity } from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { useCustomerCategoryDeleteMutation } from "../context/customerCategories.api";

export const DeleteDialog = (prop: {
  open: boolean;
  item: IBaseCustomerCategoryEntity | undefined;
  onClose: () => void;
}) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries

  // mutations
  const [customerCategoryDelete, { data: customerCategoryDeleteData, isLoading: customerCategoryDeleteIsLoading, error: customerCategoryDeleteError }] = useCustomerCategoryDeleteMutation();

  // constants

  // // action handlers
  const handleDelete = (_id: string) => {
    customerCategoryDelete({ input: { _id: _id } });
  };

  // useEffects.success
  useEffect(() => {
    if (customerCategoryDeleteData) {
      prop?.onClose();
      enqueueSnackbar(t("crm.customer.customerCategories.deletedSuccessfully"), {
        variant: "success",
      });
    }
  }, [customerCategoryDeleteData]);

  // // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      customerCategoryDeleteError as IBaseError,
      currentLanguage
    );
  }, [customerCategoryDeleteError, currentLanguage]);

  // useEffects.init

  return (
    <Dialog
      open={prop?.open}
      onClose={prop?.onClose}
      sx={{
        "& .MuiDialog-paper": {
          padding: 1,
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle>{t("crm.customer.customerCategories.delete")} | {prop?.item?.name}</DialogTitle>
      <DialogContent>
        <Typography>
          {t("crm.customer.customerCategories.deleteConfirm")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={prop?.onClose} color="primary">
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="error"
          disabled={customerCategoryDeleteIsLoading}
          onClick={() => {
            handleDelete(prop?.item?._id as string);
          }}
        >
          {t("delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
