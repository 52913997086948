import {

  ICustomerListInput, ICustomerListResult, ICustomerListResponse, ICustomerListRequest, ICustomerListItemResult, customerListQuery,
  ICustomerDetailInput, ICustomerDetailResult, ICustomerDetailResponse, ICustomerDetailRequest, customerDetailQuery,

  ICustomerCreateInput, ICustomerCreateResult, ICustomerCreateResponse, ICustomerCreateRequest, customerCreateQuery,
  ICustomerUpdateInput, ICustomerUpdateResult, ICustomerUpdateResponse, ICustomerUpdateRequest, customerUpdateQuery, ICustomerUpdateFilterInput,
  ICustomerDeleteInput, ICustomerDeleteResult, ICustomerDeleteResponse, ICustomerDeleteRequest, customerDeleteQuery,

} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { customersTags } from "./customers.tags";
import { IGraphqlVariables } from "corede-common";

export const customersApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries

    //   customerCategoryList: builder.query<
    //   ICustomerCategoryListResult,
    //   IGraphqlVariables<ICustomerCategoryListInput>
    // >(
    //   EndpointQueryBuilder.BuildGraphqlQuery<
    //     ICustomerCategoryListRequest,
    //     ICustomerCategoryListResponse,
    //     ICustomerCategoryListResult,
    //     ICustomerCategoryListInput
    //   >({
    //     query: customerCategoryListQuery,
    //     providesTags: [customerCategoriesTags.customerCategories, customerCategoriesTags.customerCategory],
    //   })),

    customerList: builder.query<
      ICustomerListResult,
      IGraphqlVariables<ICustomerListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerListRequest,
        ICustomerListResponse,
        ICustomerListResult,
        ICustomerListInput
      >({
        query: customerListQuery,
        providesTags: [customersTags.customers, customersTags.customer],
      })),

    customerDetail: builder.query<
      ICustomerDetailResult,
      IGraphqlVariables<ICustomerDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerDetailRequest,
        ICustomerDetailResponse,
        ICustomerDetailResult,
        ICustomerDetailInput
      >({
        query: customerDetailQuery,
        providesTags: [customersTags.customers, customersTags.customer],
      })),


    // mutation

    customerCreate: builder.mutation<
      ICustomerCreateResult,
      IGraphqlVariables<ICustomerCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerCreateRequest,
        ICustomerCreateResponse,
        ICustomerCreateResult,
        ICustomerCreateInput
      >({
        query: customerCreateQuery,
        invalidatesTags: [customersTags.customers, customersTags.customer],
      })),


    customerUpdate: builder.mutation<
      ICustomerUpdateResult,
      IGraphqlVariables<ICustomerUpdateInput, ICustomerUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerUpdateRequest,
        ICustomerUpdateResponse,
        ICustomerUpdateResult,
        ICustomerUpdateInput,
        ICustomerUpdateFilterInput
      >({
        query: customerUpdateQuery,
        invalidatesTags: [customersTags.customers, customersTags.customer],
      })),

    customerDelete: builder.mutation<
      ICustomerDeleteResult,
      IGraphqlVariables<ICustomerDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerDeleteRequest,
        ICustomerDeleteResponse,
        ICustomerDeleteResult,
        ICustomerDeleteInput
      >({
        query: customerDeleteQuery,
        invalidatesTags: [customersTags.customers, customersTags.customer],
      })),


  }),
});

export const {

  useCustomerListQuery,
  useLazyCustomerListQuery,
  useCustomerDetailQuery,
  useLazyCustomerDetailQuery,

  useCustomerCreateMutation,
  useCustomerUpdateMutation,
  useCustomerDeleteMutation,

} = customersApi;
