/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from "@mui/icons-material";
import { Autocomplete, Avatar, Box, Button, Chip, Grid, IconButton, MenuItem, Modal, Popover, Select, Skeleton, Stack, SvgIcon, TextField, Tooltip, Typography, } from "@mui/material";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { useCallback, useEffect, useState } from "react";
import { DataGridPremium, GridColDef, useGridApiRef, GridCellModes, GridCellModesModel, GridCellParams } from "@mui/x-data-grid-premium";
import { modalStyle } from "apps/crm/domains/03-lead/subdomains/leadStatuses";
import { trTR } from '@mui/x-data-grid/locales';
import { convertEnums, getCurrentLanguage } from "localization";
import { Language, unknownError } from "corede-common";
import { useCustomerListQuery, useCustomerUpdateMutation } from "../context/customers.api";
import * as Icons from '@mui/icons-material';
import { CustomerType, IBaseLeadSourceEntity } from "corede-common-cocrm";
import EmptyState from "components/emptyState/EmptyState";
import { RolesDrawer } from "../../../../02-organizationalChart/subdomains/permissions";
import { useNavigate } from "react-router-dom";
import { DeleteDialog } from "./DeleteDialog";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { BarChart, Gauge, gaugeClasses, LineChartPro } from '@mui/x-charts-pro';
import { useCustomerCategoryListQuery } from "../../customerCategories";
import { CustomerDetailDrawer } from "./DetailDrawer";
import CustomerCreateWraper from "./CustomerCreateWraper";
import CustomerUpdateWraper from "./CustomerUpdateWraper";

const List = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const localizedCustomerTypes = convertEnums(CustomerType);
  const localizedLanguages = convertEnums(Language);
  const apiRef = useGridApiRef();
  const [openCustomerCreateDrawer, setOpenCustomerCreateDrawer] = useState(false);
  const [openDepartmentsDrawer, setOpenDepartmentsDrawer] = useState(false);
  const [selectedItemForDelete, setSelectedItemForDelete] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any>();
  const [openSelectIconset, setOpenSelectIconset] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openRolesDrawer, setOpenRolesDrawer] = useState(false);
  const [openCustomerCategoryCreateDrawer, setOpenCustomerCategoryCreateDrawer] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const [openCustomerUpdateDrawer, setOpenCustomerUpdateDrawer] = useState(false);

  const handleCellClick = useCallback((params: GridCellParams) => {
    if (params.field !== "actions") {
      setCellModesModel((prevModel) => {
        return {
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View }
                }),
                {}
              )
            }),
            {}
          ),
          [params.id]: {
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit }
          }
        };
      })
    }
  }, []);

  const handleCellModesModelChange = useCallback((newModel: any) => {
    setCellModesModel(newModel);
  }, []);

  const { data: customerCategoryListData, isLoading: customerCategoryListLoading, isFetching: customerCategoryListFetching, error: customerCategoryListError } = useCustomerCategoryListQuery({
    input: {
      filter: {}
    }
  })
  const { data: customerListData, isLoading: customerListLoading, isFetching: customerListFetching, error: customerListError } = useCustomerListQuery({
    input: {
      filter: {}
    }
  })
  const [customerUpdate, { isLoading: customerUpdateLoading, error: customerUpdateError }] = useCustomerUpdateMutation()

  const [rows, setRows] = useState<any[]>(customerListData?.data?.map((customer, index) => ({
    id: index + 1,
    _id: customer._id,
    image: customer.image?.publicUrl || 'https://picsum.photos/200',
    name: customer.name || '-',
    email: customer.email || '-',
    type: customer.type || '-',
    phone: customer.phone || '-',
    language: customer.language || '-',
    category: customer.category || '-',
    about: customer.about || '-',
    website: customer.website || '-',
    country: customer.country || '-',
    city: customer.city || '-',
    state: customer.state || '-',
    address: customer.address || '-',
  })) || []);


  const columns: GridColDef[] = [
    { field: 'id', headerName: t("crm.customer.customers.id"), width: 50, disableColumnMenu: true, filterable: false, align: 'center', headerAlign: 'center' },
    {
      field: 'name', headerName: t("crm.customer.customers.name"), width: 160, editable: true,
      renderCell: (params: any) => {
        return <Stack direction={"row"} alignItems={"center"} gap={1}>
          <Avatar src={params?.row?.image} sx={{ width: 24, height: 24 }} />
          {params?.row?.name}
        </Stack>
      }
    },
    { field: 'email', headerName: t("crm.customer.customers.email"), width: 160, editable: true },
    {
      field: 'type', headerName: t("crm.customer.customers.type"),
      width: 120,
      editable: true,
      renderEditCell: (params: any) => {
        const currentValue = params.value || params.row.type || '';
        return (
          <Select
            value={currentValue}
            label={t("crm.lead.lead.type")}
            onChange={(e) => {
              params.api.setEditCellValue({
                ...params,
                value: e.target.value
              });
            }}
            sx={{ width: "100%" }}
          >
            {Object.values(CustomerType).map((type) => (
              <MenuItem key={type} value={type}>
                <Typography fontSize={"13px"} fontWeight={"bold"}>
                  {localizedCustomerTypes[type as keyof typeof CustomerType]}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        );
      },
      renderCell: (params: any) => (
        <Chip
          label={localizedCustomerTypes[params.value as keyof typeof CustomerType]}
          variant="outlined"
          sx={{
            fontSize: '12px',
            fontWeight: 'bold',
            textTransform: 'capitalize',
          }}
        />
      ),
    },
    { field: 'phone', headerName: t("crm.customer.customers.phone"), width: 160, editable: true },
    {
      field: 'language', headerName: t("crm.customer.customers.language"),
      width: 120,
      editable: true,
      renderEditCell: (params: any) => {
        const currentValue = params.value || params.row.language || '';
        return (
          <Select
            value={currentValue}
            label={t("crm.customer.customers.language")}
            onChange={(e) => {
              params.api.setEditCellValue({
                ...params,
                value: e.target.value
              });
            }}
            sx={{ width: "100%" }}
          >
            {Object.values(Language).map((language) => (
              <MenuItem key={language} value={language}>
                <Typography fontSize={"13px"} fontWeight={"bold"}>
                  {localizedLanguages[language as keyof typeof Language]}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        );
      },
      renderCell: (params: any) => <Stack height={'100%'} justifyContent={"center"} >
        <Typography sx={{ fontSize: '12px' }}>
          {localizedLanguages[params.value as keyof typeof Language]}
        </Typography>
      </Stack>
    },
    {
      field: 'category', headerName: t("crm.customer.customers.category"), width: 120,
      editable: true,
      type: 'singleSelect',
      valueOptions: customerCategoryListData?.data.map((category) => category._id),
      renderEditCell: (params: any) => {
        const currentValue = params.value?._id || params.row.category?._id || '';
        return (
          <Select
            value={currentValue}
            label={t("crm.customer.customers.category")}
            onChange={(e) => {
              params.api.setEditCellValue({
                ...params,
                value: { ...params.value, _id: e.target.value }
              });
            }}
            sx={{ width: "100%" }}
          >
            {customerCategoryListData?.data.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                <Stack direction="row" alignItems={"center"}>
                  <SvgIcon component={(Icons as any)[category.icon ?? '']} sx={{ fontSize: "13px", mr: 0.5 }} />
                  <Typography fontSize={"13px"} fontWeight={"bold"}>   {category.name}   </Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>
        );
      },
      renderCell: (params: any) => {
        return <Box
          sx={{
            border: "1px solid",
            borderRadius: '30px',
            py: 0.5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            px: 1,
          }}
        >
          <SvgIcon component={(Icons as any)[customerCategoryListData?.data.find((category) => category._id === params?.value?._id)?.icon ?? '']} fontSize="small" sx={{ mr: 0.5 }} />
          <Typography fontSize={"12px"} textAlign={"center"} fontWeight={"bold"} textTransform={"capitalize"}>
            {customerCategoryListData?.data.find((category) => category._id === params?.value?._id)?.name ?? ''}
          </Typography>
        </Box>
      },
      renderHeader: (params) => (
        <Stack direction="row" alignItems={"center"}>
          <span style={{ fontWeight: 500 }}>{params.colDef.headerName}</span>
          <IconButton
            size="small"
            onClick={(e: any) => {
              e.stopPropagation();
              setOpenCustomerCategoryCreateDrawer(true)
            }}
          >
            <Add fontSize="small" />
          </IconButton>
        </Stack>
      ),
    },
    { field: 'about', headerName: t("crm.customer.customers.about"), width: 160, editable: true },
    { field: 'website', headerName: t("crm.customer.customers.website"), width: 160, editable: true },
    { field: 'country', headerName: t("crm.customer.customers.country"), width: 160, editable: true },
    { field: 'city', headerName: t("crm.customer.customers.city"), width: 160, editable: true },
    {
      field: 'actions',
      headerName: t("crm.customer.customers.actions"),
      width: 120,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"center"} mt={{ xs: 0, md: 1 }}>
            <Tooltip title={t("crm.lead.lead.view")}>
              <IconButton
                onClick={() => {
                  setSelectedCustomer(params.row);
                  setOpenDetailDrawer(true);
                }}
                color="primary"
                sx={{ p: "4px" }}
              >
                <Icons.VisibilityOutlined color="primary" fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("crm.lead.lead.edit")}>
              <IconButton
                onClick={() => {
                  setSelectedCustomer(params.row);
                  setOpenCustomerUpdateDrawer(true);
                }}
                color="info"
                sx={{ p: "4px", }}
              >
                <Icons.EditNoteRounded color="info" fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("crm.customer.customers.delete")}>
              <IconButton
                onClick={() => handleDeleteRow(params?.row)}
                color="error"
                sx={{ p: "4px", }}
              >
                <Icons.DeleteForeverOutlined color="error" fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack >
        );
      },
    },
  ];


  const handleDeleteRow = (item: IBaseLeadSourceEntity) => {
    setSelectedItemForDelete(item);
  }

  const filteredIcons = Object.keys(Icons).filter((iconName) =>
    iconName.endsWith('Outlined') && iconName.toLowerCase().includes(searchQuery.toLowerCase())
  );


  const handleIconSelect = (iconName: string) => {
    const updatedRow = {
      ...selectedRow,
      icon: iconName,
    };

    apiRef.current.updateRows([updatedRow]);
    handleProcessRowUpdate(updatedRow, selectedRow);
    setOpenSelectIconset(false);
  };

  const handleProcessRowUpdate = (newRow: any, oldRow: any) => {
    if (newRow.name !== oldRow.name) {
      customerUpdate({ filter: { _id: newRow._id }, input: { type: newRow.type, name: newRow.name } });
    }
    if (newRow.email !== oldRow.email) {
      customerUpdate({ filter: { _id: newRow._id }, input: { type: newRow.type, email: newRow.email } });
    }
    if (newRow.type !== oldRow.type) {
      customerUpdate({ filter: { _id: newRow._id }, input: { type: newRow.type } });
    }
    if (newRow.phone !== oldRow.phone) {
      customerUpdate({ filter: { _id: newRow._id }, input: { type: newRow.type, phone: newRow.phone } });
    }
    if (newRow.language !== oldRow.language) {
      customerUpdate({ filter: { _id: newRow._id }, input: { type: newRow.type, language: newRow.language } });
    }
    if (newRow.category !== oldRow.category) {
      customerUpdate({ filter: { _id: newRow._id }, input: { type: newRow.type, categoryId: newRow.category?._id } });
    }
    if (newRow.about !== oldRow.about) {
      customerUpdate({ filter: { _id: newRow._id }, input: { type: newRow.type, about: newRow.about } });
    }
    if (newRow.website !== oldRow.website) {
      customerUpdate({ filter: { _id: newRow._id }, input: { type: newRow.type, website: newRow.website } });
    }
    if (newRow.country !== oldRow.country) {
      customerUpdate({ filter: { _id: newRow._id }, input: { type: newRow.type, country: newRow.country } });
    }
    if (newRow.city !== oldRow.city) {
      customerUpdate({ filter: { _id: newRow._id }, input: { type: newRow.type, city: newRow.city } });
    }
    if (newRow.state !== oldRow.state) {
      customerUpdate({ filter: { _id: newRow._id }, input: { type: newRow.type, state: newRow.state } });
    }
    if (newRow.address !== oldRow.address) {
      customerUpdate({ filter: { _id: newRow._id }, input: { type: newRow.type, address: newRow.address } });
    }

    return newRow;
  };

  function handleSelectCustomer(direction: 'next' | 'back') {
    if (!selectedCustomer || !customerListData?.data?.length) return;

    const currentIndex = customerListData.data.findIndex(customer => customer._id === selectedCustomer._id);

    let newIndex = currentIndex;
    if (direction === 'next') {
      newIndex = currentIndex + 1;
    } else if (direction === 'back') {
      newIndex = currentIndex - 1;
    }

    // Ensure the new index is within the valid range
    if (newIndex >= 0 && newIndex < customerListData.data?.length) {
      const newSelectedCustomer = { ...customerListData.data[newIndex], id: newIndex + 1 }; // index bilgisini ekle
      setSelectedCustomer(newSelectedCustomer);
    }
  }

  useEffect(() => {
    if (customerListData?.data) {
      setRows(customerListData?.data?.map((customer, index) => ({
        id: index + 1,
        _id: customer._id,
        image: customer.image?.publicUrl || 'https://picsum.photos/200',
        name: customer.name || '-',
        email: customer.email || '-',
        type: customer.type || '-',
        phone: customer.phone || '-',
        language: customer.language || '-',
        category: customer.category || '-',
        about: customer.about || '-',
        website: customer.website || '-',
        country: customer.country || '-',
        city: customer.city || '-',
        state: customer.state || '-',
        address: customer.address || '-',
      })))
    }
  }, [customerListData?.data])


  useEffect(() => {
    if (customerListError) {
      enqueueSnackbar((customerListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [customerListError]);

  useEffect(() => {
    if (customerUpdateError) {
      enqueueSnackbar((customerUpdateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [customerUpdateError]);

  return <Grid item xs={12}>
    <HeaderBase
      title={t("crm.customer.customers.headerTitle")}
      rightButton={{
        title: t("crm.customer.customers.createCustomer"),
        onClick: () => {
          setOpenCustomerCreateDrawer(true);
        },
        icon: <Add sx={{ mr: 1 }} />,
      }}
    />
    <Grid container spacing={2} my={1}>
      <Grid item xs={12} md={4}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" sx={{ border: "1px solid", borderRadius: "14px" }}>
          <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between" width={"85%"} mb={-5}>
            <Typography fontSize={"18px"} fontWeight={"bold"}>Custoemers</Typography>
            <Typography fontSize={"44px"} fontWeight={"bold"}>35</Typography>
          </Stack>
          <LineChartPro
            xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
            series={[{
              data: [2, 5.5, 2, 8.5, 1.5, 5],
              color: "#F43F5E"
            }]}
            height={170}
            sx={{ mb: -5 }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" sx={{ border: "1px solid", borderRadius: "14px" }}>
          <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between" width={"85%"} mb={-2}>
            <Typography fontSize={"18px"} fontWeight={"bold"}>Related Customers</Typography>
            <Typography fontSize={"44px"} fontWeight={"bold"}>3</Typography>
          </Stack>
          <Gauge
            width={180}
            height={147}
            value={3}
            cornerRadius="50%"
            sx={(theme) => ({
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 40,
              },
              [`& .${gaugeClasses.valueArc}`]: {
                fill: theme.palette.secondary.main,
              },
              [`& .${gaugeClasses.referenceArc}`]: {
                fill: theme.palette.text.disabled,
              },
            })}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" sx={{ border: "1px solid", borderRadius: "14px" }}>
          <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between" width={"85%"} mb={-2}>
            <Typography fontSize={"18px"} fontWeight={"bold"}>B2B / B2C</Typography>
            <Typography fontSize={"44px"} fontWeight={"bold"}>&nbsp;</Typography>
          </Stack>
          <BarChart
            xAxis={[
              {
                id: 'barCategories',
                data: ['BTB', 'BTC',],
                scaleType: 'band',
              },
            ]}
            series={[
              {
                data: [2, 5],
                color: "#F43F5E"
              },
            ]}
            height={150}
            sx={{ mb: -5 }}
          />
        </Box>
      </Grid>
    </Grid>

    {customerListData?.data?.length !== 0 ? <Box height={{ xs: "500px", md: "calc(100vh - 500px)" }} mt={1} overflow={"auto"} borderRadius={"16px"}>
      <DataGridPremium
        localeText={currentLanguage === Language.tr ? trTR.components.MuiDataGrid.defaultProps.localeText : undefined}
        apiRef={apiRef}
        cellModesModel={cellModesModel}
        onCellClick={handleCellClick}
        onCellModesModelChange={handleCellModesModelChange}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        showColumnVerticalBorder
        disableAggregation
        disableRowGrouping
        hideFooter
        processRowUpdate={handleProcessRowUpdate}
        loading={customerListLoading || customerUpdateLoading || customerListFetching}
        scrollbarSize={1}
        scrollEndThreshold={10}
        pinnedColumns={{ left: ['id'], right: ['actions'] }}
        rowsLoadingMode="server"
        sx={tableStyle}
      />
    </Box> :
      <EmptyState
        content1={t("crm.customer.customers.emptyStateContent1")}
        content2={t("crm.customer.customers.emptyStateContent2")}
        button1={{
          title: t("crm.customer.customers.createCustomer"),
          onClick: () => {
            setOpenCustomerCreateDrawer(true);
          },
          leftIcon: <Add />
        }}
      />
    }


    <Modal
      open={openSelectIconset}
      onClose={() => setOpenSelectIconset(false)}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-title" variant="h6" component="h2">
          Select an Icon
        </Typography>
        <TextField
          label="Search Icons"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Box sx={{ maxHeight: 400, overflow: 'auto', mt: 2 }}>
          <Grid container spacing={2}>
            {filteredIcons.map((iconName) => {
              const IconComponent = (Icons as any)[iconName];
              return (
                <Grid item xs={2} key={iconName}>
                  <IconButton onClick={() => handleIconSelect(iconName)}>
                    <IconComponent />
                  </IconButton>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Modal>

    <CustomerDetailDrawer
        open={openDetailDrawer}
        setOpen={setOpenDetailDrawer}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        handleSelect={handleSelectCustomer}
        count={customerListData?.count}
      />

    <CustomerCreateWraper
      open={openCustomerCreateDrawer}
      setOpen={setOpenCustomerCreateDrawer}
      setOpenDepartments={setOpenDepartmentsDrawer}
    />

    <CustomerUpdateWraper
      open={openCustomerUpdateDrawer}
      setOpen={setOpenCustomerUpdateDrawer}
      selectedCustomer={selectedCustomer}
      setSelectedCustomer={setSelectedCustomer}
      setOpenDepartments={setOpenDepartmentsDrawer}
    />

    <RolesDrawer
      open={openRolesDrawer}
      setOpen={setOpenRolesDrawer}
    />

    <DeleteDialog
      open={Boolean(selectedItemForDelete)}
      onClose={() => setSelectedItemForDelete(undefined)}
      item={selectedItemForDelete}
    />

  </Grid>;
};

export default List;

const tableStyle = {
  fontSize: "12px",
  '& .MuiDataGrid-footerContainer': {
    height: '20px !important',
    maxHeight: '20px !important',
    m: 0,
  },
  '& .MuiDataGrid-toolbarContainer > button': {
    fontSize: "12px",
  },
  '& .MuiDataGrid-columnHeader .MuiIconButton-root': {
    fontSize: "12px",
    p: "2px"
  },
  '& .MuiDataGrid-columnHeader .MuiSvgIcon-root': {
    width: "16px",
    height: "16px",
  },
  '& .MuiDataGrid-cell': {
    display: 'flex',
    alignItems: 'center',
  }
}
