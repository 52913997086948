/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, Button, CircularProgress, Grid, InputLabel, Stack, SwipeableDrawer, Switch, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { convertEnums, getCurrentLanguage } from "localization";
import { Currency, IGraphqlVariables, unknownError, Language } from "corede-common";
import { IEstimateCreateInput, EstimateType } from "corede-common-cocrm";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useEstimateCreateMutation } from "../context/estimates.api";
import { validateCreateEstimatesInput } from "../validations/create.validation";
import { useProductListQuery } from "../../../../08-productsAndServices/subdomains/common/context/productsServices.api";
import { useLegalAgreementListQuery } from "../../legalAgreement";
import { useUserListQuery } from "apps/auth/context";

interface IEstimateCreateDrawer {
    open: boolean
    setOpen: (open: boolean) => void
}

export const EstimateCreateDrawer = (props: IEstimateCreateDrawer) => {
    const { t } = useTranslation();
    const currentLanguage = getCurrentLanguage();

    // queries
    const { data: productListData, isLoading: productListLoading, error: productListError } = useProductListQuery({});
    const { data: userListData, isLoading: userListLoading, error: userListError } = useUserListQuery({});
    const { data: legalAgreementListData, isLoading: legalAgreementListLoading, error: legalAgreementListError } = useLegalAgreementListQuery({
        input: {
            pagination: {
                sort: {
                    createdAt: 1
                }
            }
        }
    });
    const [loading, setLoading] = useState(false);
    const localizedCurrencies = convertEnums(Currency);
    const localizedEstimateTypes = convertEnums(EstimateType);
    const localizedLanguages = convertEnums(Language);
    const [estimateCreate, { data: estimateCreateData, isLoading: estimateCreateLoading, error: estimateCreateError }] = useEstimateCreateMutation();

    const { values, handleSubmit, handleBlur, setFieldValue, errors, touched, resetForm, isValid } =
        useFormik({
            initialValues: {
                input: {
                    prefix: "",
                    estimateId: 0,
                    timeZone: "",
                    prospectTargetEntityId: "66f5bf80a0f0ee130e89acz8", //TODO how to select as input?
                    prospectTargetEntityRef: "ProspectTarget",  //TODO how to select as input?
                    prospectType: EstimateType.individual,
                    prospectName: "",
                    prospectVAT: undefined,
                    prospectPhoneNumber: "",
                    prospectCountry: "",
                    prospectState: "",
                    prospectCity: "",
                    prospectZIP: undefined,
                    prospectAddress: undefined,
                    prospectContactFirstName: "",
                    prospectContactLastName: "",
                    prospectContactEmail: "",
                    language: Language.en,
                    date: new Date(),
                    expireDate: new Date(),
                    sendEmailOnExpiration: true,
                    expirationEmailSentDate: undefined,
                    projectOrServiceDesc: undefined,
                    scopeOfWork: undefined,
                    assumptionsAndExclusions: undefined,
                    paymentTerms: undefined,
                    currency: Currency.EUR,
                    products: [],
                    offers: undefined,
                    reference: undefined,
                    salesAgentIds: [],
                    allowComments: true,
                    useLegalNDA: true,
                    legalNDAId: undefined,
                    useLegalTermAndCond: true,
                    legalTermAndCondId: undefined,
                    signerUserId: "",
                    signatureImage: undefined, // TODO 

                },
            },
            // enableReinitialize: true,
            validationSchema: validateCreateEstimatesInput,
            onSubmit: async (values: IGraphqlVariables<IEstimateCreateInput>) => {
                setLoading(true);
                estimateCreate({ input: values.input });
                setLoading(false);
            }
        });

    useEffect(() => {
        if (estimateCreateData) {
            props.setOpen(false);
            enqueueSnackbar(t("crm.preSales.estimates.estimateCreateSuccess"), { variant: 'success' });
            resetForm();
        }
    }, [estimateCreateData, resetForm]);

    useEffect(() => {
        if (estimateCreateError) {
            enqueueSnackbar((estimateCreateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [estimateCreateError]);

    return (
        <SwipeableDrawer
            open={props.open}
            onOpen={() => props.setOpen(true)}
            onClose={() => props.setOpen(false)}
            anchor='right'
        >
            <Grid item xs={12} p={3} width={500}>
                <Typography variant="h4" gutterBottom>
                    {t("crm.preSales.estimates.createEstimate")}
                </Typography>
                <Box sx={{ mt: 4, width: 400 }} />
                <Stack direction={"column"} justifyContent={"space-between"} height={"calc(100dvh - 120px)"}>
                    <Stack direction="column" gap={1} mt={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.prefix")}*</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    name="input.prefix"
                                    value={(values as any).input.prefix}
                                    onChange={(e) => setFieldValue("input.prefix", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.prefix && (touched as any)?.input?.prefix)}
                                    helperText={(touched as any)?.input?.prefix && (errors as any)?.input?.prefix}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.timeZone")}*</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    name="input.timeZone"
                                    value={(values as any).input.timeZone}
                                    onChange={(e) => setFieldValue("input.timeZone", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.timeZone && (touched as any)?.input?.timeZone)}
                                    helperText={(touched as any)?.input?.timeZone && (errors as any)?.input?.timeZone}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.prospectType")}*</InputLabel>
                                <Autocomplete
                                    id="prospectType"
                                    size="small"
                                    fullWidth
                                    options={Object.values(EstimateType).map((prospectType) => ({
                                        name: localizedEstimateTypes[prospectType as keyof typeof EstimateType],
                                        _id: prospectType,
                                    }))}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField{...params} />}
                                    value={
                                        values.input?.prospectType
                                            ? { name: localizedEstimateTypes[values.input?.prospectType as keyof typeof EstimateType], _id: values.input?.prospectType }
                                            : null
                                    }
                                    onChange={(e, value) => {
                                        setFieldValue("input.prospectType", value?._id);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.prospectName")}*</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    name="input.prospectName"
                                    value={(values as any).input.prospectName}
                                    onChange={(e) => setFieldValue("input.prospectName", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.prospectName && (touched as any)?.input?.prospectName)}
                                    helperText={(touched as any)?.input?.prospectName && (errors as any)?.input?.prospectName}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.prospectVAT")}*</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    name="input.prospectVAT"
                                    value={(values as any).input.prospectVAT}
                                    onChange={(e) => setFieldValue("input.prospectVAT", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.prospectVAT && (touched as any)?.input?.prospectVAT)}
                                    helperText={(touched as any)?.input?.prospectVAT && (errors as any)?.input?.prospectVAT}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.prospectPhoneNumber")}*</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    name="input.prospectPhoneNumber"
                                    value={(values as any).input.prospectPhoneNumber}
                                    onChange={(e) => setFieldValue("input.prospectPhoneNumber", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.prospectPhoneNumber && (touched as any)?.input?.prospectPhoneNumber)}
                                    helperText={(touched as any)?.input?.prospectPhoneNumber && (errors as any)?.input?.prospectPhoneNumber}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.prospectCountry")}*</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    name="input.prospectCountry"
                                    value={(values as any).input.prospectCountry}
                                    onChange={(e) => setFieldValue("input.prospectCountry", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.prospectCountry && (touched as any)?.input?.prospectCountry)}
                                    helperText={(touched as any)?.input?.prospectCountry && (errors as any)?.input?.prospectCountry}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.prospectState")}*</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    name="input.prospectState"
                                    value={(values as any).input.prospectState}
                                    onChange={(e) => setFieldValue("input.prospectState", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.prospectState && (touched as any)?.input?.prospectState)}
                                    helperText={(touched as any)?.input?.prospectState && (errors as any)?.input?.prospectState}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.prospectCity")}*</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    name="input.prospectCity"
                                    value={(values as any).input.prospectCity}
                                    onChange={(e) => setFieldValue("input.prospectCity", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.prospectCity && (touched as any)?.input?.prospectCity)}
                                    helperText={(touched as any)?.input?.prospectCity && (errors as any)?.input?.prospectCity}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.prospectZIP")}*</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    name="input.prospectZIP"
                                    value={(values as any).input.prospectZIP}
                                    onChange={(e) => setFieldValue("input.prospectZIP", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.prospectZIP && (touched as any)?.input?.prospectZIP)}
                                    helperText={(touched as any)?.input?.prospectZIP && (errors as any)?.input?.prospectZIP}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.prospectAddress")}*</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    name="input.prospectAddress"
                                    value={(values as any).input.prospectAddress}
                                    onChange={(e) => setFieldValue("input.prospectAddress", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.prospectAddress && (touched as any)?.input?.prospectAddress)}
                                    helperText={(touched as any)?.input?.prospectAddress && (errors as any)?.input?.prospectAddress}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.prospectContactFirstName")}*</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    name="input.prospectContactFirstName"
                                    value={(values as any).input.prospectContactFirstName}
                                    onChange={(e) => setFieldValue("input.prospectContactFirstName", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.prospectContactFirstName && (touched as any)?.input?.prospectContactFirstName)}
                                    helperText={(touched as any)?.input?.prospectContactFirstName && (errors as any)?.input?.prospectContactFirstName}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.prospectContactLastName")}*</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    name="input.prospectContactLastName"
                                    value={(values as any).input.prospectContactLastName}
                                    onChange={(e) => setFieldValue("input.prospectContactLastName", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.prospectContactLastName && (touched as any)?.input?.prospectContactLastName)}
                                    helperText={(touched as any)?.input?.prospectContactLastName && (errors as any)?.input?.prospectContactLastName}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.prospectContactEmail")}*</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    name="input.prospectContactEmail"
                                    value={(values as any).input.prospectContactEmail}
                                    onChange={(e) => setFieldValue("input.prospectContactEmail", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.prospectContactEmail && (touched as any)?.input?.prospectContactEmail)}
                                    helperText={(touched as any)?.input?.prospectContactEmail && (errors as any)?.input?.prospectContactEmail}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.language")}*</InputLabel>
                                <Autocomplete
                                    id="language"
                                    size="small"
                                    fullWidth
                                    options={Object.values(Language).map((language) => ({
                                        name: localizedLanguages[language as keyof typeof Language],
                                        _id: language,
                                    }))}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} />}
                                    value={
                                        values.input?.language
                                            ? { name: localizedLanguages[values.input?.language as keyof typeof Language], _id: values.input?.language }
                                            : null
                                    }
                                    onChange={(e, value) => {
                                        setFieldValue("input.language", value?._id);
                                    }}
                                />
                            </Grid>


                            {/* <Grid container spacing={5}>
                            <Grid item xs={12} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.date")}*</InputLabel>
                                <TextField
                                size="small"
                                    fullWidth
                                    required
                                    name="input.date"
                                    value={(values as any).input.date}
                                    onChange={(e) => setFieldValue("input.date", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.date && (touched as any)?.input?.date)}
                                    helperText={(touched as any)?.input?.date && (errors as any)?.input?.date}
                                />
                            </Grid>
                        </Grid> */}


                            {/* <Grid container spacing={5}>
                            <Grid item xs={12} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.expireDate")}*</InputLabel>
                                <TextField
                                size="small"
                                    fullWidth
                                    required
                                    name="input.expireDate"
                                    value={(values as any).input.expireDate}
                                    onChange={(e) => setFieldValue("input.expireDate", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.expireDate && (touched as any)?.input?.expireDate)}
                                    helperText={(touched as any)?.input?.expireDate && (errors as any)?.input?.expireDate}
                                />
                            </Grid>
                        </Grid> */}
                            <Grid item xs={6}>
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{"."}</InputLabel>
                                <Box sx={{ border: "1px solid #ccc", borderRadius: "14px", p: 0.5, display: "flex", alignItems: "center" }}>
                                    <Switch
                                        name="input.sendEmailOnExpiration"
                                        checked={(values as any).input.sendEmailOnExpiration}
                                        color="secondary"
                                        size="small"
                                        onChange={(e) => setFieldValue("input.sendEmailOnExpiration", e.target.checked)}
                                    />
                                    <Typography sx={{ ml: 1, fontSize: 'small' }}>{t("crm.preSales.estimates.sendEmailOnExpiration")}</Typography>
                                </Box>
                            </Grid>


                            {/* <Grid container spacing={5}>
                            <Grid item xs={12} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.expirationEmailSentDate")}*</InputLabel>
                                <TextField
                                size="small"
                                    fullWidth
                                    required
                                    name="input.expirationEmailSentDate"
                                    value={(values as any).input.expirationEmailSentDate}
                                    onChange={(e) => setFieldValue("input.expirationEmailSentDate", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.expirationEmailSentDate && (touched as any)?.input?.expirationEmailSentDate)}
                                    helperText={(touched as any)?.input?.expirationEmailSentDate && (errors as any)?.input?.expirationEmailSentDate}
                                />
                            </Grid>
                        </Grid> */}


                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.projectOrServiceDesc")}*</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    name="input.projectOrServiceDesc"
                                    value={(values as any).input.projectOrServiceDesc}
                                    onChange={(e) => setFieldValue("input.projectOrServiceDesc", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.projectOrServiceDesc && (touched as any)?.input?.projectOrServiceDesc)}
                                    helperText={(touched as any)?.input?.projectOrServiceDesc && (errors as any)?.input?.projectOrServiceDesc}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.scopeOfWork")}*</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    name="input.scopeOfWork"
                                    value={(values as any).input.scopeOfWork}
                                    onChange={(e) => setFieldValue("input.scopeOfWork", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.scopeOfWork && (touched as any)?.input?.scopeOfWork)}
                                    helperText={(touched as any)?.input?.scopeOfWork && (errors as any)?.input?.scopeOfWork}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.assumptionsAndExclusions")}*</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    name="input.assumptionsAndExclusions"
                                    value={(values as any).input.assumptionsAndExclusions}
                                    onChange={(e) => setFieldValue("input.assumptionsAndExclusions", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.assumptionsAndExclusions && (touched as any)?.input?.assumptionsAndExclusions)}
                                    helperText={(touched as any)?.input?.assumptionsAndExclusions && (errors as any)?.input?.assumptionsAndExclusions}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.paymentTerms")}*</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    name="input.paymentTerms"
                                    value={(values as any).input.paymentTerms}
                                    onChange={(e) => setFieldValue("input.paymentTerms", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.paymentTerms && (touched as any)?.input?.paymentTerms)}
                                    helperText={(touched as any)?.input?.paymentTerms && (errors as any)?.input?.paymentTerms}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.currency")}*</InputLabel>
                                <Autocomplete
                                    id="currency"
                                    fullWidth
                                    size="small"
                                    options={Object.values(Currency).map((currency) => ({
                                        name: localizedCurrencies[currency as keyof typeof Currency],
                                        _id: currency,
                                    }))}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} />}
                                    value={
                                        values.input?.currency
                                            ? { name: localizedCurrencies[values.input?.currency as keyof typeof Currency], _id: values.input?.currency }
                                            : null
                                    }
                                    onChange={(e, value) => {
                                        setFieldValue("input.currency", value?._id);
                                    }}
                                />
                            </Grid>

                            {/* //TODO product selection */}
                            <Grid item xs={6}  >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.products")}</InputLabel>
                                <Autocomplete
                                    id="saleAgentIds"
                                    isOptionEqualToValue={(option, value) => option._id === value._id}
                                    fullWidth
                                    size="small"
                                    getOptionLabel={(option) => option.name ?? "-"}
                                    options={[...(productListData?.data || []), { _id: "add", name: t("crm.preSales.estimates.addSProduct") }]}
                                    loading={userListLoading}
                                    renderInput={(params) => <TextField {...params} />}
                                    multiple
                                    value={
                                        values.input?.products && values.input.products?.length > 0
                                            ? productListData?.data?.filter((product) =>
                                                (values as any).input.products.includes(product._id)
                                            )
                                            : []
                                    }
                                    onChange={(e, value) => {
                                        const addRoleOption = value?.find(v => v._id === "add");


                                        setFieldValue(
                                            "input.products",
                                            value?.map((product) => product._id) || []
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.offers")}*</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    name="input.offers"
                                    value={(values as any).input.offers}
                                    onChange={(e) => setFieldValue("input.offers", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.offers && (touched as any)?.input?.offers)}
                                    helperText={(touched as any)?.input?.offers && (errors as any)?.input?.offers}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.reference")}*</InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    name="input.reference"
                                    value={(values as any).input.reference}
                                    onChange={(e) => setFieldValue("input.reference", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.reference && (touched as any)?.input?.reference)}
                                    helperText={(touched as any)?.input?.reference && (errors as any)?.input?.reference}
                                />
                            </Grid>
                            <Grid item xs={6}  >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.salesAgentIds")}</InputLabel>
                                <Autocomplete
                                    id="saleAgentIds"
                                    size="small"
                                    isOptionEqualToValue={(option, value) => option._id === value._id}
                                    fullWidth
                                    getOptionLabel={(option) => option.name ?? "-"}
                                    options={[...(userListData?.data || []), { _id: "add", name: t("crm.preSales.estimates.addSalesAgent") }]}
                                    loading={userListLoading}
                                    renderInput={(params) => <TextField {...params} />}
                                    multiple
                                    value={
                                        values.input?.salesAgentIds && values.input.salesAgentIds?.length > 0
                                            ? userListData?.data?.filter((user) =>
                                                (values as any).input.salesAgentIds.includes(user._id)
                                            )
                                            : []
                                    }
                                    onChange={(e, value) => {
                                        const addRoleOption = value?.find(v => v._id === "add");


                                        setFieldValue(
                                            "input.salesAgentIds",
                                            value?.map((user) => user._id) || []
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{"."}</InputLabel>
                                <Box sx={{ border: "1px solid #ccc", borderRadius: "14px", p: 1, display: "flex", alignItems: "center" }}>
                                    <Switch
                                        name="input.allowComments"
                                        checked={(values as any).input.allowComments}
                                        color="secondary"
                                        size="small"
                                        onChange={(e) => setFieldValue("input.allowComments", e.target.checked)}
                                    />
                                    <Typography sx={{ ml: 1, fontSize: 'small' }}>{t("crm.preSales.estimates.allowComments")}</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={6}>
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{"."}</InputLabel>
                                <Box sx={{ border: "1px solid #ccc", borderRadius: "14px", p: 1, display: "flex", alignItems: "center" }}>
                                    <Switch
                                        name="input.useLegalNDA"
                                        checked={(values as any).input.useLegalNDA}
                                        color="secondary"
                                        size="small"
                                        onChange={(e) => setFieldValue("input.useLegalNDA", e.target.checked)}
                                    />
                                    <Typography sx={{ ml: 1, fontSize: 'small' }}>{t("crm.preSales.estimates.useLegalNDA")}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}  >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.legalNDAId")}</InputLabel>
                                <Autocomplete
                                    id="legalNDAId"
                                    size="small"
                                    isOptionEqualToValue={(option, value) => option._id === value._id}
                                    fullWidth
                                    getOptionLabel={(option) => option.name}
                                    options={legalAgreementListData?.data || []}
                                    loading={legalAgreementListLoading}
                                    renderInput={(params) => <TextField {...params} />}
                                    value={
                                        values.input?.legalNDAId
                                            ? legalAgreementListData?.data?.filter((legalAgreement) =>
                                                (values as any).input.legalNDAId.includes(legalAgreement._id)
                                            )[0]
                                            : undefined
                                    }
                                    onChange={(e, value) => {
                                        setFieldValue(
                                            "input.legalNDAId",
                                            value?._id
                                        );
                                    }}
                                />

                            </Grid>

                            <Grid item xs={6} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{"."}</InputLabel>
                                <Box sx={{ border: "1px solid #ccc", borderRadius: "14px", display: "flex", alignItems: "center" }}>
                                    <Switch
                                        name="input.useLegalTermAndCond"
                                        checked={(values as any).input.useLegalTermAndCond}
                                        color="secondary"
                                        size="small"
                                        onChange={(e) => setFieldValue("input.useLegalTermAndCond", e.target.checked)}
                                    />
                                    <Typography sx={{ ml: 1, fontSize: 'small' }}>{t("crm.preSales.estimates.useLegalTermAndCond")}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}  >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.legalTermAndCondId")}</InputLabel>
                                <Autocomplete
                                    id="legalTermAndCondId"
                                    size="small"
                                    isOptionEqualToValue={(option, value) => option._id === value._id}
                                    fullWidth
                                    getOptionLabel={(option) => option.name}
                                    options={legalAgreementListData?.data || []}
                                    loading={legalAgreementListLoading}
                                    renderInput={(params) => <TextField {...params} />}
                                    value={
                                        values.input?.legalTermAndCondId
                                            ? legalAgreementListData?.data?.filter((legalAgreement) =>
                                                (values as any).input.legalTermAndCondId.includes(legalAgreement._id)
                                            )[0]
                                            : undefined
                                    }
                                    onChange={(e, value) => {
                                        setFieldValue(
                                            "input.legalTermAndCondId",
                                            value?._id
                                        );
                                    }}
                                />

                            </Grid>
                            <Grid item xs={6}  >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.preSales.estimates.signerUserId")}</InputLabel>
                                <Autocomplete
                                    id="signerUserId"
                                    size="small"
                                    isOptionEqualToValue={(option, value) => option._id === value._id}
                                    fullWidth
                                    getOptionLabel={(option) => option.email}
                                    options={userListData?.data || []}
                                    loading={legalAgreementListLoading}
                                    renderInput={(params) => <TextField {...params} />}
                                    value={
                                        values.input?.signerUserId
                                            ? userListData?.data?.filter((user) =>
                                                (values as any).input.signerUserId.includes(user._id)
                                            )[0]
                                            : undefined
                                    }
                                    onChange={(e, value) => {
                                        setFieldValue(
                                            "input.signerUserId",
                                            value?._id
                                        );
                                    }}
                                />

                            </Grid>
                        </Grid>

                    </Stack>
                    <Grid container spacing={5} pt={1.5}>
                        <Grid item xs={12} >
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => handleSubmit()}
                                disabled={false} //TODO {loading || !isValid || estimateCreateLoading || !(values as any).input?.name}
                            >
                                {loading ? <CircularProgress size="1rem" /> : t("crm.preSales.estimates.create")}
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            </Grid>
        </SwipeableDrawer >
    );
};


export const colors = [
    "primary.lighter",
    "secondary.lighter",
    "success.lighter",
    "error.lighter",
    "warning.lighter",
    "info.lighter",
    "primary.light",
    "secondary.light",
    "success.light",
    "error.light",
    "warning.light",
    "info.light",
    "primary.main",
    "secondary.main",
    "success.main",
    "error.main",
    "warning.main",
    "info.main",
    "primary.dark",
    "secondary.dark",
    "success.dark",
    "error.dark",
    "warning.dark",
    "info.dark",
    "primary.darker",
    "secondary.darker",
    "success.darker",
    "error.darker",
    "warning.darker",
    "info.darker",
];
