import leadLocale from "../domains/03-lead/locales/tr";
import dashboardLocale from "../domains/01-dashboard/locales/tr";
import organizationalChartLocale from "../domains/02-organizationalChart/locales/tr";
import customerLocale from "../domains/04-customer/locales/tr";

const locale = {
  lead: {
    ...leadLocale,
  },
  dashboard: {
    ...dashboardLocale,
  },
  organizationalChart: {
    ...organizationalChartLocale,
  },
  customer: {
    ...customerLocale,
  },
  contractss: "Sözleşmeler",
  dashboardd: "Gösterge Paneli",
  leadd: "Lead",
  customerr: "Müşteri",
  customers: "Müşteriler",
  customerGroups: "Müşteri Grupları",
  customerSegments: "Müşteri Segmentleri",
  customerTags: "Müşteri Etiketleri",
  marketing: "Pazarlama",
  campaigns: "Kampanyalar",
  emails: "E-postalar",
  sms: "SMS",
  socialMedia: "Sosyal Medya",
  preSales: "Ön Satış",
  proposals: "Teklifler",
  estimates: "Tahminler",
  sales: "Satışlar",
  invoices: "Faturalar",
  payments: "Ödemeler",
  contracts: "Sözleşmeler",
  productsServices: "Ürünler ve Hizmetler",
  expenses: "Giderler",
  projects: "Projeler",
  tasks: "Görevler",
  businessIntelligence: "İş Zekası",
  calendar: "Takvim",
  reports: "Raporlar",
  support: "Destek",
  accountMenu: "Hesap Menüsü",
  profile: "Profil",
  myAccount: "Hesabım",
  darkMode: "Karanlık Mod",
  lightMode: "Aydınlık Mod",
  languageSelectLabel: "Dil Seçimi",
  english: "İngilizce",
  turkish: "Türkçe",
  settings: "Ayarlar",
  logout: "Çıkış",
  searchPlaceholder: '"bir şeyler" aramayı deneyin',
  mustCreateOrganizationTitle: "Organizasyon Oluştur",
  mustCreateOrganizationContent: "Paneli kullanabilmek için bir organizasyon oluşturmanız gerekmektedir.",
  mustCreateOrganizationSuccess: "Organizasyon oluşturuldu",
  name: "Ad",
  cancel: "İptal",
  create: "Oluştur",
  companyName: "Şirket Adı",
  companyEmail: "Şirket E-postası",
  companyPhone: "Şirket Telefonu",
  country: "Ülke",
  city: "İl",
  industry: "Sektör",
  timezone: "Saat Zamanı",
  address: "Adres",
  website: "Web Sitesi",
  notes: "Notlar",
  organizationalChartt: "Organizasyon Şeması",
  preSaless: "Ön Satış",
  saless: "Satış",
  expensess: "Giderler",
  productsAndServicess: "Ürünler ve Hizmetler",
  projectss: "Projeler",
  taskss: "Görevler",
  calendarr: "Takvim",
  reportss: "Raporlar",
  supportt: "Destek",
  companyLogo: "Şirket Logosu",
};

export default locale;
