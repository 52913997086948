const locale = {
  customers: "Customers",
  headerTitle: "Customers",
  createCustomer: "Create Customer",
  columnId: "#",
  columnName: "Name",
  columnIcon: "Icon",
  columnDepartment: "Department",
  columnParentCategory: "Parent Category",
  columnActions: "Actions",
  emptyStateContent1: "Customers not found",
  emptyStateContent2: "Please create a new customer",
  selectIconTitle: "Select an Icon",
  searchIcons: "Search Icons",
  relatedCustomers: "Related Customers",
  b2bB2c: "B2B / B2C",
  createCustomerTitle: "Create Customer",
  customerNameLabel: "Customer Name",
  customerEmailLabel: "Customer Email",
  customerPhoneLabel: "Customer Phone",
  customerTypeLabel: "Customer Type",
  customerPictureLabel: "Customer Picture",
  departmentLabel: "Department",
  categoryLabel: "Category",
  customerLanguageLabel: "Customer Language",
  countryLabel: "Country",
  stateLabel: "State",
  cityLabel: "City",
  uploadPictureText: "Upload a picture",
  createCustomerButton: "Create Customer",
  createSuccess: "Customer created successfully",
  delete: "Delete",
  deleteConfirm: "Are you sure you want to delete this customer? This action cannot be undone.",
  deletedSuccessfully: "Customer deleted successfully",
  cancel: "Cancel",
  id: "#",
  name: "Name",
  email: "Email",
  type: "Type",
  phone: "Phone",
  language: "Language",
  category: "Category",
  about: "About",
  website: "Website",
  country: "Country",
  city: "City",
  actions: "Actions",
  updateCustomerTitle: "Update Customer",
  updateCustomerButton: "Update Customer",
  
};

export default locale;
