/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowDownward, ArrowUpward, Close, NoteAdd } from '@mui/icons-material'
import { SwipeableDrawer, Box, Stack, Avatar, Typography, Button, Divider, Grid, IconButton, SvgIcon } from '@mui/material'
import { useNoteCreateMutation, useNoteUpdateMutation, useNoteDeleteMutation, useLazyNoteListQuery, useLeadDetailQuery } from 'apps/crm/domains/03-lead/subdomains/lead'
import { useLeadStatusesQuery } from 'apps/crm/domains/03-lead/subdomains/leadStatuses'
import { MyTab } from 'components/tab'
import { useNavigate } from 'react-router-dom'
import * as Icons from '@mui/icons-material';
import moment from 'moment'
import { memo, useEffect, useState } from 'react'
import ReactQuill from 'react-quill-new'
import { useFormik } from 'formik'
import { DataGridPremium, GridColDef, useGridApiRef } from '@mui/x-data-grid-premium'
import { enqueueSnackbar } from 'notistack'
import { convertEnums, getCurrentLanguage } from 'localization'
import { Language, unknownError } from 'corede-common'
import { trTR } from '@mui/x-data-grid/locales';
import { useTranslation } from 'react-i18next'
import { LeadQualification, LeadType } from 'corede-common-cocrm'
import { Iconify, OutlinedButton } from 'components'
import { skipToken } from '@reduxjs/toolkit/query'

interface ILeadDetailDrawer {
    open: any
    setOpen: any
    selectedLead: any
    setSelectedLead: any
    handleSelect: any
    count: any
}

const LeadDetailDrawer = memo((props: ILeadDetailDrawer) => {

    const { t } = useTranslation();
    const currentLanguage = getCurrentLanguage();
    const apiRef = useGridApiRef();
    const { data: leadStatusesData, isLoading: leadStatusesLoading, error: leadStatusesError } = useLeadStatusesQuery({})
    const [noteListQuery, { data: noteListData, isLoading: noteListLoading, error: noteListError }] = useLazyNoteListQuery()
    const { data: leadDetailData, isLoading: leadDetailLoading, error: leadDetailError } = useLeadDetailQuery(
        props?.selectedLead?._id ? {
            input: {
                _id: props?.selectedLead?._id
            }
        } : skipToken
    )
    const [noteCreate, { isLoading: noteCreateLoading, error: noteCreateError }] = useNoteCreateMutation();
    const [noteUpdate, { isLoading: noteUpdateLoading, error: noteUpdateError }] = useNoteUpdateMutation();
    const [noteDelete, { isLoading: noteDeleteLoading, error: noteDeleteError }] = useNoteDeleteMutation();
    const navigate = useNavigate();
    const [selectedTabName, setSelectedTabName] = useState("Info");
    const [selectedNote, setSelectedNote] = useState<any>();
    const localizedLeadQualifications = convertEnums(LeadQualification);
    const localizedLeadTypes = convertEnums(LeadType);
    const localizedLanguages = convertEnums(Language);


    const [rows, setRows] = useState<any[]>(noteListData?.data?.map((note, index) => ({
        id: index + 1,
        _id: note._id,
        title: note.title || '-',
        content: note.content || '-',
    })) || []);

    const columns: GridColDef[] = [
        { field: 'id', headerName: '#', width: 50, disableColumnMenu: true, filterable: false, align: 'center', headerAlign: 'center' },
        { field: 'title', headerName: 'Title', width: 300, editable: true },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 90,
            filterable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: any) => {
                return (
                    <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"center"} mt={{ xs: 0, md: 2 }}>
                        <IconButton
                            onClick={() => setSelectedNote(params.row)}
                            color="info"
                            sx={{ p: "4px", }}
                        >
                            <Icons.RemoveRedEyeOutlined color="info" fontSize="small" />
                        </IconButton>
                        <IconButton
                            onClick={() => handleNoteDelete(params.row._id)}
                            color="error"
                            sx={{ p: "4px", }}
                        >
                            <Icons.DeleteOutlineRounded color="error" fontSize="small" />
                        </IconButton>
                    </Stack>
                );
            },
        },
    ];

    function handleProcessRowUpdate(newRow: any, oldRow: any) {
        if (newRow.name !== oldRow.name) {
            console.log({ filter: { _id: newRow._id }, input: { name: newRow.name } });
        }
        if (newRow.icon !== oldRow.icon) {
            console.log({ filter: { _id: newRow._id }, input: { icon: newRow.icon } });
        }
        if (newRow.color !== oldRow.color) {
            console.log({ filter: { _id: newRow._id }, input: { color: newRow.color } });
        }
        return newRow;
    };


    async function handleNoteDelete(id: string) {
        await noteDelete({ input: { _id: id } }).then(() => {
            enqueueSnackbar(t("Note deleted successfully!"), { variant: 'success' });
        }).catch(() => {
            enqueueSnackbar(t("Note delete failed!"), { variant: 'error' });
        })
    }

    const { values, handleSubmit, handleBlur, setFieldValue, errors, resetForm } = useFormik({
        initialValues: {
            input: {
                title: selectedNote?.title || "",
                content: selectedNote?.content || "",
                targetId: leadDetailData?._id,
                targetType: "lead",
            }
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            selectedNote ?
                noteUpdate({
                    ...values,
                    filter: {
                        _id: selectedNote?._id
                    },
                    input: {
                        ...values.input,
                        title: new DOMParser().parseFromString(values.input.content, 'text/html').body.textContent?.substring(0, 20) + "..."
                    }
                }).then(() => {
                    enqueueSnackbar(t("Note updated successfully!"), { variant: 'success' });
                    resetForm();
                    setSelectedNote(null);
                }).catch(() => {
                    enqueueSnackbar(t("Note update failed!"), { variant: 'error' });
                })
                :
                noteCreate({
                    ...values,
                    input: {
                        ...values.input,
                        title: new DOMParser().parseFromString(values.input.content, 'text/html').body.textContent?.substring(0, 20) + "..."

                    }
                }).then(() => {
                    enqueueSnackbar(t("Note created successfully!"), { variant: 'success' });
                    resetForm();
                }).catch(() => {
                    enqueueSnackbar(t("Note creation failed!"), { variant: 'error' });
                })

        }
    })


    useEffect(() => {
        if (leadDetailData) {
            setFieldValue("input.targetId", props?.selectedLead?._id)
            noteListQuery({
                input: {
                    filter: {
                        targetIds: [leadDetailData?._id || '']
                    }
                }
            })
        }
    }, [leadDetailData])

    useEffect(() => {
        if (noteListError) {
            enqueueSnackbar((noteListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [noteListError]);

    useEffect(() => {
        if (noteUpdateError) {
            enqueueSnackbar((noteUpdateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [noteUpdateError]);

    useEffect(() => {
        if (noteCreateError) {
            enqueueSnackbar((noteCreateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [noteCreateError]);

    useEffect(() => {
        if (noteDeleteError) {
            enqueueSnackbar((noteDeleteError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    }, [noteDeleteError]);

    useEffect(() => {
        if (noteListData) {
            setRows(noteListData?.data?.map((note, index) => ({
                id: index + 1,
                _id: note._id,
                title: note.title || '-',
                content: note.content || '-',
            })))
        }
    }, [noteListData]);

    useEffect(() => {
        if (leadStatusesError) {
            enqueueSnackbar((leadStatusesError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
    })

    return <>
        <SwipeableDrawer
            open={props.open}
            onClose={() => props.setOpen(false)}
            onOpen={() => props.setOpen(true)}
            anchor="right"
            variant='persistent'
            PaperProps={{
                sx: {
                    backgroundColor: 'background.primary',
                    right: '1020px',
                    top: '10px',
                    width: "auto",
                    height: "auto",
                    padding: 0,
                    borderRadius: 20,
                    boxShadow: 3,
                    zIndex: 1250,
                }
            }}
        >
            <Stack direction="column" >
                <IconButton size="small" sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} onClick={() => props.handleSelect("back")}>
                    <ArrowUpward />
                </IconButton>
                <Divider sx={{ width: '100%' }} />
                <IconButton size="small" sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} onClick={() => props.handleSelect("next")}>
                    <ArrowDownward />
                </IconButton>
            </Stack>
        </SwipeableDrawer>
        <SwipeableDrawer
            open={props.open}
            onClose={() => props.setOpen(false)}
            onOpen={() => props.setOpen(true)}
            anchor="right"
            className='user-detail-nav'
            sx={{ zIndex: 1249 }}
        >
            <Box minWidth={260} width={1000} bgcolor={"background.primary"} height={"calc(100dvh)"}>
                <Stack direction="column" alignItems="center">
                    <Box sx={{ py: 1, height: 20, width: '100%', borderBottom: "2px solid", borderColor: "grey.400" }}>
                        <Stack direction="row" alignItems="center" gap={1} px={2} justifyContent={"space-between"}>
                            <Typography fontSize={"small"} fontWeight={"bold"}>
                                {props?.selectedLead?.id} of {props.count} leads
                            </Typography>
                            <Stack direction="row" alignItems="center" gap={0.5} onClick={() => props.setOpen(false)} sx={{ cursor: 'pointer' }}>
                                <Typography fontSize={"small"} fontWeight={"bold"} color={"secondary.main"}>
                                    Close
                                </Typography>
                                <Close sx={{ fontSize: "16px" }} color='secondary' />
                            </Stack>
                        </Stack>
                    </Box>
                    <Grid container width={"100%"}>
                        <Grid item xs={12} md={3} sx={{ height: '100%', borderRight: "2px solid", borderColor: "grey.400", p: 2 }}>
                            <Stack direction="column" gap={1} height={"calc(100dvh - 70px)"}>
                                {/* Personal */}
                                <Stack direction="column" alignItems={"flex-start"} gap={1}>
                                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} gap={1} width={"100%"}>
                                        <Box
                                            sx={{
                                                border: "2px solid",
                                                borderColor: "secondary.main",
                                                borderRadius: '10px',
                                                py: 0.5,
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "flex-start",
                                                px: 1,
                                            }}
                                        >
                                            <Typography fontSize={"12px"} textAlign={"center"} fontWeight={"bold"} color={"secondary.main"}>
                                                {leadDetailData?.businessType?.toString().toUpperCase()}
                                            </Typography>
                                        </Box>
                                        {leadDetailData?.isJunk && <Box
                                            sx={{
                                                border: "1px solid",
                                                borderColor: "warning.main",
                                                borderRadius: '10px',
                                                backgroundColor: "warning.lighter",
                                                py: 0.5,
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "flex-start",
                                                px: 1,
                                            }}
                                        >
                                            <Typography fontSize={"12px"} textAlign={"center"} fontWeight={"bold"}>
                                                JUNK
                                            </Typography>
                                        </Box>}
                                    </Stack>

                                    <Typography sx={{ fontWeight: "bold", fontSize: "24px", color: "primary.main" }}>
                                        {leadDetailData?.fullName}
                                    </Typography>
                                    <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "primary.main" }}>
                                        {localizedLeadQualifications[(leadDetailData as any)?.qualification as keyof typeof localizedLeadQualifications] ?? ''}
                                    </Typography>
                                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{ height: 40, fontSize: "13px", borderRadius: 100 }}
                                            onClick={() => navigate("/")}
                                        >
                                            <NoteAdd sx={{ mr: 1 }} /> Appointment
                                        </Button>
                                        <Button
                                            variant='outlined'
                                            sx={{
                                                height: 40, width: 40, fontSize: "13px", minWidth: 0, borderRadius: 100
                                            }}
                                        >
                                            <Icons.MoreVert />
                                        </Button>
                                    </Stack>
                                    <Box sx={{ mt: 2, width: '100%', p: 2, bgcolor: "background.fourth", borderRadius: 2, boxSizing: 'border-box' }}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "14px", color: "text.secondary" }}>
                                            Proposal Sent
                                        </Typography>
                                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-end"}>
                                            <Typography sx={{ fontWeight: 400, fontSize: "20px", color: "primary.main" }}>
                                                $312,000
                                            </Typography>
                                            <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "secondary.main", cursor: "pointer" }}>
                                                View
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Stack>


                                {/* Contact Details */}
                                <Stack direction={"column"} width={"100%"} gap={2} mt={2}>
                                    <Stack direction={"column"} width={"100%"} gap={0.5}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                                            Contact Details
                                        </Typography>
                                        <Divider />
                                    </Stack>
                                    <Stack direction={"row"} gap={1} alignItems={"center"} width={"100%"}>
                                        <Avatar alt={leadDetailData?.fullName} src={""} sx={{ width: 40, height: 40 }} />
                                        <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.primary" }}>
                                            {leadDetailData?.fullName}
                                        </Typography>
                                    </Stack>
                                    <Stack direction={"row"} gap={1} alignItems={"center"} width={"100%"}>
                                        <Box sx={{ width: 40, height: 40, bgcolor: "background.fourth", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Icons.EmailOutlined sx={{ color: "text.secondary" }} />
                                        </Box>
                                        <Stack direction={"column"}>
                                            <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.secondary" }}>
                                                Email Address
                                            </Typography>
                                            <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.primary" }}>
                                                {leadDetailData?.email}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"row"} gap={1} alignItems={"center"} width={"100%"}>
                                        <Box sx={{ width: 40, height: 40, bgcolor: "background.fourth", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Icons.PhoneOutlined sx={{ color: "text.secondary" }} />
                                        </Box>
                                        <Stack direction={"column"}>
                                            <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.secondary" }}>
                                                Phone
                                            </Typography>
                                            <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.primary" }}>
                                                {leadDetailData?.phoneNumber ?? "-"}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"row"} gap={1} alignItems={"center"} width={"100%"}>
                                        <Box sx={{ width: 40, height: 40, bgcolor: "background.fourth", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Icons.PublicOutlined sx={{ color: "text.secondary" }} />
                                        </Box>
                                        <Stack direction={"column"}>
                                            <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.secondary" }}>
                                                Website
                                            </Typography>
                                            <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.primary" }}>
                                                {leadDetailData?.website ?? "-"}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"row"} gap={1} alignItems={"center"} width={"100%"}>
                                        <Box sx={{ width: 40, height: 40, bgcolor: "background.fourth", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Icons.Tag sx={{ color: "text.secondary" }} />
                                        </Box>
                                        <Stack direction={"column"}>
                                            <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.secondary" }}>
                                                Tags
                                            </Typography>
                                            <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.primary" }}>
                                                {leadDetailData?.tags ? leadDetailData?.tags?.length > 0 ? leadDetailData?.tags?.map((tag) => tag).join(", ") : "-" : "-"}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>


                                {/* Assigneds */}
                                <Stack direction={"column"} width={"100%"} gap={2} mt={2}>
                                    <Stack direction={"column"} width={"100%"} gap={0.5}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                                            Assignees
                                        </Typography>
                                        <Divider />
                                    </Stack>
                                    <Stack direction={"column"} width={"100%"} gap={1}>
                                        {leadDetailData?.assigned && leadDetailData?.assigned?.length > 0 ? leadDetailData?.assigned?.map((assignee) => (
                                            <Stack direction={"row"} gap={1} alignItems={"center"} width={"100%"} key={assignee?._id}>
                                                <Avatar alt={assignee?.surname} src={assignee?.profileImage?.thumbnailPublicUrl} sx={{ width: 40, height: 40 }} />
                                                <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.primary" }}>
                                                    {assignee?.name} {assignee?.surname}
                                                </Typography>
                                            </Stack>
                                        )) : <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.primary" }}>No Assignees</Typography>}
                                    </Stack>

                                </Stack>


                                <Stack direction={"column"} position={"absolute"} bottom={10} left={10} gap={0.5} alignItems={"flex-end"}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "12px", color: "text.secondary" }}>
                                        {leadDetailData?.createdBy?.name?.slice(0, 1)}. {leadDetailData?.createdBy?.surname} has created at {moment(leadDetailData?.createdAt).format("DD/MM/YYYY")}.
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Stack direction={"column"} gap={0} width={"100%"}>
                                {/* Last Contact  */}
                                <Stack direction={"column"} gap={1} width={"100%"} sx={{ borderBottom: "2px solid", borderColor: "grey.400", p: 2, boxSizing: "border-box" }}>
                                    <Stack direction="row" spacing={0} sx={{ borderRadius: '16px', overflow: 'hidden', mb: 1 }}>
                                        {/* {leadStatusesData?.data?.map((status, index) => (
                                            <Box
                                                key={status.name}
                                                sx={{
                                                    backgroundColor: status.name === leadDetailData?.status.name ? status?.color : 'primary.light',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    position: 'relative',
                                                    justifyContent: 'center',
                                                    padding: '4px 16px',
                                                    pl: index !== 0 ? 3 : 1,
                                                    ...(index === 0 && { borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px' }),
                                                    ...(index === leadStatusesData?.data?.length - 1 && { borderTopRightRadius: '16px', borderBottomRightRadius: '16px' }),
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        display: index === leadStatusesData?.data?.length - 1 ? 'none' : 'block',
                                                        top: 0,
                                                        right: -6,
                                                        width: 0,
                                                        height: 0,
                                                        borderTop: '12px solid transparent',
                                                        borderBottom: '12px solid transparent',
                                                        borderLeft: `8px solid`,
                                                        borderLeftColor: 'background.secondary',
                                                        zIndex: 2
                                                    }}
                                                />
                                                <SvgIcon component={(Icons as any)[status.icon]} sx={{ fontSize: '16px', color: 'white', mr: 1 }} />
                                                <Typography variant="body2" fontSize={'12px'} sx={{ color: 'white', textTransform: 'capitalize' }}>
                                                    {status.name}
                                                </Typography>
                                            </Box>
                                        ))} */}

                                        <Typography fontSize={"14px"} fontWeight={600} color={"text.primary"}>Last contacted: {moment(leadDetailData?.updatedAt).format("DD/MM/YYYY")} (Total: 3)</Typography>
                                    </Stack>
                                    <Grid container sx={{ mb: 2 }}>
                                        <Grid item xs={4}>
                                            <Stack direction={"row"} gap={1} alignItems={"center"} mt={1}>
                                                <Box sx={{ width: 40, height: 40, bgcolor: "background.fourth", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Iconify icon={"fluent-mdl2:party-leader"} sx={{ color: "text.secondary" }} width={24} />
                                                </Box>
                                                <Stack direction={"column"} gap={0.5}>
                                                    <Typography fontSize={"12px"} fontWeight={600} color={"text.secondary"}>
                                                        Lead Type
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            border: "1px solid",
                                                            borderRadius: '30px',
                                                            py: 0.5,
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "flex-start",
                                                            px: 1,
                                                        }}
                                                    >
                                                        <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                                            {localizedLeadTypes[(leadDetailData as any)?.type as keyof typeof localizedLeadTypes] ?? ''}
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Stack direction={"row"} gap={1} alignItems={"center"} mt={1}>
                                                <Box sx={{ width: 40, height: 40, bgcolor: "background.fourth", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Iconify icon={"f7:status"} sx={{ color: "text.secondary" }} width={24} />                                                </Box>
                                                <Stack direction={"column"} gap={0.5}>
                                                    <Typography fontSize={"12px"} fontWeight={600} color={"text.secondary"}>
                                                        Status
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            border: "1px solid",
                                                            borderColor: leadDetailData?.status?.color,
                                                            borderRadius: '30px',
                                                            py: 0.5,
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "flex-start",
                                                            px: 1,
                                                        }}
                                                    >
                                                        <SvgIcon component={(Icons as any)[(leadDetailData as any)?.status?.icon]} fontSize="small" sx={{ mr: 0.5, color: leadDetailData?.status?.color }} />
                                                        <Typography fontSize={"12px"} textAlign={"center"} fontWeight={"bold"} textTransform={"capitalize"} color={leadDetailData?.status.color}>
                                                            {leadDetailData?.status?.name}
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Stack direction={"row"} gap={1} alignItems={"center"} mt={1}>
                                                <Box sx={{ width: 40, height: 40, bgcolor: "background.fourth", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Iconify icon={"tabler:source-code"} sx={{ color: "text.secondary" }} width={24} />
                                                </Box>
                                                <Stack direction={"column"} gap={0.5}>
                                                    <Typography fontSize={"12px"} fontWeight={600} color={"text.secondary"}>
                                                        Source
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            border: "1px solid",
                                                            borderColor: leadDetailData?.source?.color,
                                                            borderRadius: '30px',
                                                            py: 0.5,
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "flex-start",
                                                            px: 1,
                                                        }}
                                                    >
                                                        <SvgIcon component={(Icons as any)[(leadDetailData as any)?.source?.icon]} fontSize="small" sx={{ mr: 0.5, color: leadDetailData?.source?.color }} />
                                                        <Typography fontSize={"12px"} textAlign={"center"} fontWeight={"bold"} textTransform={"capitalize"} color={leadDetailData?.source?.color}>
                                                            {leadDetailData?.source?.name}
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Stack>
                                {/* Tabbar */}
                                <Box sx={{ px: 1 }}>
                                    <MyTab
                                        labels={["Info", "Estimates", "Proposals", "Tasks", "Attachments", "Notes", "Activities"]}
                                        setSelectedTab={setSelectedTabName}
                                    />
                                </Box>
                                <Divider sx={{ width: "100%", borderBottomWidth: 2, borderColor: "grey.400" }} />

                                {/* INFO TAB */}
                                {selectedTabName === "Info" && <Box sx={{ overflow: "auto" }}>

                                    {/* Company */}

                                    <Stack direction={"column"} sx={{ px: 2, py: 1, mt: 1, mb: 2, position: "relative" }}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                                            Company
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Stack direction={"row"} gap={2} mt={1} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                                                    <Stack direction={"row"} gap={2} alignItems={"center"} justifyContent={"flex-start"}>
                                                        <Box sx={{ width: 30, height: 30, bgcolor: "secondary.main", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <Icons.BusinessCenterOutlined sx={{ color: "primary.lighter", fontSize: "medium" }} />
                                                        </Box>
                                                        <Stack direction={"column"}>
                                                            <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                                                Company Name
                                                            </Typography>
                                                            <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                                                {leadDetailData?.company}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                                <Stack direction={"row"} gap={2} mt={2} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                                                    <Stack direction={"row"} gap={2} alignItems={"flex-start"} justifyContent={"flex-start"}>
                                                        <Box sx={{ width: 30, height: 30, bgcolor: "primary.light", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <Icons.KeyboardDoubleArrowRight sx={{ color: "primary.lighter", fontSize: "medium" }} />
                                                        </Box>
                                                        <Stack direction={"column"}>
                                                            <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                                                Sector
                                                            </Typography>
                                                            <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                                                {leadDetailData?.sector}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Stack direction={"row"} gap={2} mt={1} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                                                    <Stack direction={"row"} gap={2} alignItems={"center"} justifyContent={"flex-start"}>
                                                        <Box sx={{ width: 30, height: 30, bgcolor: "secondary.main", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <Icons.RadarOutlined sx={{ color: "primary.lighter", fontSize: "medium" }} />
                                                        </Box>
                                                        <Stack direction={"column"}>
                                                            <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                                                Position
                                                            </Typography>
                                                            <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                                                {leadDetailData?.position}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                                <Stack direction={"row"} gap={2} mt={2} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                                                    <Stack direction={"row"} gap={2} alignItems={"flex-start"} justifyContent={"flex-start"}>
                                                        <Box sx={{ width: 30, height: 30, bgcolor: "primary.light", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <Icons.PublicOutlined sx={{ color: "primary.lighter", fontSize: "medium" }} />
                                                        </Box>
                                                        <Stack direction={"column"}>
                                                            <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                                                Website
                                                            </Typography>
                                                            <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                                                {leadDetailData?.website}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Stack>

                                    {/* Localization */}

                                    <Stack direction={"column"} sx={{ px: 2, py: 1, mt: 1, mb: 2, position: "relative" }}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                                            Localization
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Stack direction={"row"} gap={2} mt={1} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                                                    <Stack direction={"row"} gap={2} alignItems={"center"} justifyContent={"flex-start"}>
                                                        <Box sx={{ width: 30, height: 30, bgcolor: "secondary.main", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <Icons.RoomOutlined sx={{ color: "primary.lighter", fontSize: "medium" }} />
                                                        </Box>
                                                        <Stack direction={"column"}>
                                                            <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                                                Country
                                                            </Typography>
                                                            <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                                                {leadDetailData?.country ?? '-'}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                                <Stack direction={"row"} gap={2} mt={2} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                                                    <Stack direction={"row"} gap={2} alignItems={"flex-start"} justifyContent={"flex-start"}>
                                                        <Box sx={{ width: 30, height: 30, bgcolor: "primary.light", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <Icons.GpsFixedOutlined sx={{ color: "primary.lighter", fontSize: "medium" }} />
                                                        </Box>
                                                        <Stack direction={"column"}>
                                                            <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                                                State
                                                            </Typography>
                                                            <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                                                {leadDetailData?.state ?? '-'}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Stack direction={"row"} gap={2} mt={1} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                                                    <Stack direction={"row"} gap={2} alignItems={"center"} justifyContent={"flex-start"}>
                                                        <Box sx={{ width: 30, height: 30, bgcolor: "secondary.main", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <Icons.PinDropOutlined sx={{ color: "primary.lighter", fontSize: "medium" }} />
                                                        </Box>
                                                        <Stack direction={"column"}>
                                                            <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                                                City
                                                            </Typography>
                                                            <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                                                {leadDetailData?.city ?? '-'}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                                <Stack direction={"row"} gap={2} mt={2} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                                                    <Stack direction={"row"} gap={2} alignItems={"flex-start"} justifyContent={"flex-start"}>
                                                        <Box sx={{ width: 30, height: 30, bgcolor: "primary.light", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <Icons.LanguageOutlined sx={{ color: "primary.lighter", fontSize: "medium" }} />
                                                        </Box>
                                                        <Stack direction={"column"}>
                                                            <Typography fontSize={"12px"} fontWeight={600} color={"text.primary"}>
                                                                Language
                                                            </Typography>
                                                            <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                                                {localizedLanguages[(leadDetailData as any)?.language as keyof typeof localizedLanguages] ?? ''}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Stack>

                                    {/* Appointments */}
                                    <Stack direction={"column"} sx={{ px: 2, py: 1, mb: 2, position: "relative" }}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                                            Appointments
                                        </Typography>
                                        {false ? <Box sx={{ width: "100%", border: "2px solid", borderColor: "grey.400", borderRadius: 2, p: 2, mt: 1, boxSizing: "border-box" }}>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <Stack direction={"column"} gap={1}>
                                                        <Typography fontSize={"12px"} fontWeight={600} color={"secondary.main"}>
                                                            Monday
                                                        </Typography>
                                                        <Typography fontSize={"15px"} fontWeight={500} color={"text.primary"}>
                                                            September 3, 2024
                                                        </Typography>
                                                        <Typography fontSize={"12px"} fontWeight={500} color={"text.secondary"}>
                                                            10 AM | 10.30 AM
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={8} sx={{ borderLeft: "1px solid", borderColor: "grey.400", pl: 2 }}>
                                                    <Stack direction={"column"} gap={1}>
                                                        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                                            <Icons.Circle color='secondary' sx={{ fontSize: "10px", ml: 0.5 }} />
                                                            <Typography fontSize={"12px"} fontWeight={400}>
                                                                On site estimate with <strong>User One</strong>
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                                            <Icons.LocationOnOutlined sx={{ fontSize: "15px", color: "primary.light" }} />
                                                            <Typography fontSize={"12px"} fontWeight={400}>
                                                                4517 Bornova, İzmir Türkiye
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                                            <Avatar alt={leadDetailData?.fullName} src={"https://loremflickr.com/480/480"} sx={{ width: 15, height: 15 }} />
                                                            <Typography fontSize={"12px"} fontWeight={400}>
                                                                Selçuk Madra
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box> :
                                            <Box sx={{ width: "100%", p: 2, mt: 1, boxSizing: "border-box", display: 'flex', flexDirection: 'column', gap: 1, justifyContent: "center", alignItems: "center" }}>
                                                <Typography fontSize={"14px"} fontWeight={400} textAlign={"center"} color={"text.secondary"}>
                                                    There is no appointments
                                                </Typography>
                                                <OutlinedButton
                                                    title='Make an Appointment'
                                                    onClick={() => { }}
                                                    color='secondary'
                                                />
                                            </Box>
                                        }
                                    </Stack>

                                </Box>}

                                {/* PROPOSALS TAB */}
                                {selectedTabName === "Proposals" && <Box sx={{ overflow: "auto" }}>

                                    {/* Proposals */}
                                    <Stack direction={"column"} sx={{ px: 2, py: 1, position: "relative" }}>
                                        <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                                            Proposals
                                        </Typography>
                                        <Box sx={{ width: "100%", border: "2px solid", borderColor: "grey.400", borderRadius: 2, p: 2, mt: 1, boxSizing: "border-box" }}>
                                            <Grid container>
                                                <Grid item xs={7} >
                                                    <Stack direction={"column"} gap={1}>
                                                        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                                            <Icons.Circle color='secondary' sx={{ fontSize: "10px", ml: 0.5 }} />
                                                            <Typography fontSize={"12px"} fontWeight={400}>
                                                                On site estimate with <strong>User One</strong>
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                                            <Icons.LocationOnOutlined sx={{ fontSize: "15px", color: "primary.light" }} />
                                                            <Typography fontSize={"12px"} fontWeight={400}>
                                                                4517 Bornova, İzmir Türkiye
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                                                            <Avatar alt={leadDetailData?.fullName} src={"https://loremflickr.com/480/480"} sx={{ width: 15, height: 15 }} />
                                                            <Typography fontSize={"12px"} fontWeight={400}>
                                                                Selçuk Madra
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={5} sx={{ borderLeft: "1px solid", borderColor: "grey.400", pl: 2 }}>
                                                    <Stack direction={"column"} gap={1}>
                                                        <Typography fontSize={"12px"} fontWeight={600} color={"secondary.main"}>
                                                            Monday
                                                        </Typography>
                                                        <Typography fontSize={"15px"} fontWeight={500} color={"text.primary"}>
                                                            September 3, 2024
                                                        </Typography>
                                                        <Typography fontSize={"12px"} fontWeight={500} color={"text.secondary"}>
                                                            10 AM | 10.30 AM
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Stack>
                                </Box>}


                                {/* ACTIVITIES TAB */}
                                {selectedTabName === "Activities" && <Box sx={{ overflow: "auto" }}>

                                    {/* Latest Activity */}

                                    <Stack direction={"column"} sx={{ px: 2, py: 1, mt: 1, mb: 2, position: "relative" }}>
                                        <Box height={"45%"} borderLeft={"1px dashed"} borderColor={"grey.400"} position={"absolute"} top={60} left={30} zIndex={0} />
                                        <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                                            Latest Activity
                                        </Typography>
                                        <Stack direction={"row"} gap={2} mt={1} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                                            <Stack direction={"row"} gap={2} alignItems={"flex-start"} justifyContent={"flex-start"}>
                                                <Box sx={{ width: 30, height: 30, bgcolor: "secondary.main", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Icons.EmailRounded sx={{ color: "primary.lighter", fontSize: "medium" }} />
                                                </Box>
                                                <Stack direction={"column"} gap={0.5}>
                                                    <Typography fontSize={"12px"} fontWeight={600} mt={0.5} color={"text.primary"}>
                                                        Email delivered: Proposal sent
                                                    </Typography>
                                                    <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                                        Proposal has been sent to the client. We'll be in touch soon!
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            <Typography fontSize={"11px"} fontWeight={400} mt={0.5} color={"text.secondary"}>
                                                1 day ago
                                            </Typography>
                                        </Stack>
                                        <Stack direction={"row"} gap={2} mt={2} alignItems={"flex-start"} justifyContent={"space-between"} sx={{ width: "100%", zIndex: 1 }}>
                                            <Stack direction={"row"} gap={2} alignItems={"flex-start"} justifyContent={"flex-start"}>
                                                <Box sx={{ width: 30, height: 30, bgcolor: "primary.light", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Icons.KeyboardDoubleArrowRight sx={{ color: "primary.lighter", fontSize: "medium" }} />
                                                </Box>
                                                <Stack direction={"column"} gap={0.5}>
                                                    <Typography fontSize={"12px"} fontWeight={600} mt={0.5} color={"text.primary"}>
                                                        Stage request: Request recieved
                                                    </Typography>
                                                    <Typography fontSize={"11px"} fontWeight={400} color={"text.secondary"}>
                                                        Proposal has been sent to the client.
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            <Typography fontSize={"11px"} fontWeight={400} mt={0.5} color={"text.secondary"}>
                                                2 day ago
                                            </Typography>
                                        </Stack>
                                    </Stack>

                                </Box>}

                                {/* NOTES TAB */}
                                {selectedTabName === "Notes" && <Box sx={{ overflow: "auto" }}>

                                    <Stack direction={"column"} sx={{ px: 2, py: 1, mt: 1, mb: 2, position: "relative" }}>
                                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} gap={1}>
                                            <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "text.primary" }}>
                                                {selectedNote ? "Update Note" : "Add New Note"}
                                            </Typography>
                                            {selectedNote ?
                                                <Stack direction={"row"} gap={1}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{ height: 40, fontSize: "13px" }}
                                                        onClick={() => handleSubmit()}
                                                        disabled={noteUpdateLoading}
                                                    >
                                                        <Icons.Update sx={{ mr: 1, fontSize: "16px" }} />
                                                        Update
                                                    </Button>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        sx={{ height: 40, fontSize: "13px" }}
                                                        onClick={() => {
                                                            setSelectedNote(null)
                                                            resetForm()
                                                        }}
                                                        disabled={noteUpdateLoading}
                                                    >
                                                        <Icons.Cancel sx={{ mr: 1, fontSize: "16px" }} />
                                                        Cancel
                                                    </Button>
                                                </Stack>
                                                : <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ height: 40, fontSize: "13px" }}
                                                    onClick={() => handleSubmit()}
                                                    disabled={noteCreateLoading}
                                                >
                                                    <Icons.AddCircle sx={{ mr: 1, fontSize: "16px" }} />
                                                    Add
                                                </Button>}
                                        </Stack>
                                        <Box sx={{ bgcolor: 'background.secondary', borderRadius: 2, height: "160px", mt: 1, border: "2px solid", borderColor: "grey.400" }}>
                                            <ReactQuill
                                                theme="snow"
                                                id='editor-lead-detail'
                                                value={(values as any).input.content}
                                                onChange={(content, delta, source, editor) => {
                                                    setFieldValue("input.content", editor.getHTML())
                                                }}
                                                onBlur={() => handleBlur("input.content")}
                                                placeholder={"Some text here..."}
                                                style={{ height: 130, boxSizing: "border-box" }}
                                            />
                                        </Box>
                                        {(errors as any).input?.content && (
                                            <Typography color="error" variant="caption">
                                                {(errors as any).input?.content}
                                            </Typography>
                                        )}

                                        <Typography sx={{ fontWeight: 600, fontSize: "16px", mt: 2, mb: 1, color: "text.primary" }}>
                                            Note List
                                        </Typography>

                                        <DataGridPremium
                                            localeText={currentLanguage === Language.tr ? trTR.components.MuiDataGrid.defaultProps.localeText : undefined}
                                            apiRef={apiRef || undefined}
                                            rows={rows}
                                            columns={columns}
                                            disableRowSelectionOnClick
                                            showColumnVerticalBorder
                                            disableAggregation
                                            disableRowGrouping
                                            hideFooter
                                            processRowUpdate={handleProcessRowUpdate}
                                            loading={leadStatusesLoading || noteCreateLoading || noteUpdateLoading || noteDeleteLoading || noteListLoading}
                                            onCellDoubleClick={(params, event) => {
                                                if (params.field === 'icon') {
                                                    event.stopPropagation();
                                                }
                                                if (params.field === 'color') {
                                                    event.stopPropagation();
                                                }
                                            }}
                                            scrollbarSize={1}
                                            scrollEndThreshold={10}
                                            rowsLoadingMode="server"
                                            sx={[tableStyle, {
                                                backgroundColor: 'background.secondary', minHeight: "280px", maxHeight: "280px",
                                                '&. .MuiDataGrid-topContainer': {
                                                    border: "2px solid #000"
                                                },
                                                '& .MuiDataGrid-row--borderBottom': {
                                                    background: "none !important",

                                                },
                                            }]}
                                        />
                                    </Stack>
                                </Box>
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack >
            </Box >
        </SwipeableDrawer >
    </>
});


const tableStyle = {
    fontSize: "12px",
    borderRadius: "16px",
    border: "2px solid",
    borderColor: "grey.400",

    '& .MuiDataGrid-toolbarContainer > button': {
        fontSize: "12px",
    },
    '& .MuiDataGrid-columnHeader .MuiIconButton-root': {
        fontSize: "12px",
        p: "2px",
        border: "none !important",
    },
    '& .MuiDataGrid-columnHeader .MuiSvgIcon-root': {
        width: "16px",
        height: "16px",
    },
    '& .MuiDataGrid-footerContainer': {
        height: '20px !important',
        maxHeight: '20px !important',
        m: 0,
    }
};

export default LeadDetailDrawer