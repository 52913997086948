/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, IconButton, Stack, SvgIcon, TextField, Typography, } from "@mui/material";
import { useAppDispatch, } from "context";
import { leadActions } from "../../../context";
import { useEffect, useState } from "react";
import { useLeadStatusCreateMutation, useLeadStatusesQuery, useLeadStatusUpdateMutation, } from "../context/leadStatuses.api";
import { Cancel, DeleteOutlineRounded, EditNoteRounded, Save } from "@mui/icons-material";
import { DataGridPremium, GridColDef, GridRowModes, GridRowModesModel, useGridApiRef } from "@mui/x-data-grid-premium";
import { enqueueSnackbar } from "notistack";
import * as Icons from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import Delete from "./Delete";
import { IBaseLeadStatusEntity } from "corede-common-cocrm";
import { Language } from "corede-common";
import { trTR } from '@mui/x-data-grid/locales';
import { getCurrentLanguage } from "localization";
import { LeadStatusUpdateDrawer } from "./LeadStatusUpdateDrawer";
import LeadStatusCreateWrapper from "./LeadStatusCreateWrapper";


const List = () => {

  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const dispatch = useAppDispatch();
  const apiRef = useGridApiRef();
  const { data: leadStatusesData, isLoading: leadStatusesLoading, isFetching: leadStatusesFetching } = useLeadStatusesQuery({
    input: {
      pagination: {
        sort: {
          createdAt: 1
        }
      }
    }
  });
  const [leadStatusUpdate, { isLoading: leadStatusUpdateLoading, error: leadStatusUpdateError }] = useLeadStatusUpdateMutation();
  const [leadStatusCreate, { isLoading: leadStatusCreateLoading, error: leadStatusCreateError }] = useLeadStatusCreateMutation();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [addStatusAvailable, setAddStatusAvailable] = useState(true);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [searchQuery, setSearchQuery] = useState('');
  const [openStatusCreateDrawer, setOpenStatusCreateDrawer] = useState(false);
  const [openLeadStatusUpdateDrawer, setOpenLeadStatusUpdateDrawer] = useState(false);
  const [selectedItemForDelete, setSelectedItemForDelete] = useState<any>();

  const [rows, setRows] = useState<any[]>(leadStatusesData?.data?.map((status, index) => ({
    id: index + 1,
    _id: status._id,
    name: status.name || '-',
    icon: status.icon || '-',
    color: status.color || '-',
  })) || []);

  const columns: GridColDef[] = [
    { field: 'id', headerName: '#', width: 50, disableColumnMenu: true, filterable: false, align: 'center', headerAlign: 'center' },
    {
      field: 'name', headerName: 'Name', width: 160, editable: true,
      renderEditCell: (params) => {
        return (
          <TextField
            size="small"
            value={params.value || ''}
            onChange={(e) => {
              const newValue = e.target.value;
              apiRef.current.setEditCellValue({ ...params, value: newValue });  // Grid'deki değeri güncelle
            }}
            onBlur={() => apiRef.current.stopCellEditMode({ id: params.id, field: params.field })}
          />
        );
      },
    },
    {
      field: 'icon', headerName: 'Icon', width: 100, editable: true,
      renderCell: (params) => {
        return (
          <SvgIcon component={(Icons as any)[params.value]} fontSize="small" sx={{ mt: 2, ml: 0.5 }} />
        )
      },
      renderEditCell: () => null
    },
    {
      field: 'color', headerName: 'Color', width: 100, editable: true,
      renderCell: (params) => {
        return (
          <Box sx={{ mt: 2, ml: 0.5, width: 20, height: 20, borderRadius: '50%', backgroundColor: params.value ?? 'background.primary' }} />
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 120,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

        return <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"center"} mt={{ xs: 0, md: 2 }}>
          <IconButton
            onClick={() => {
              setSelectedRow(params.row);
              setOpenLeadStatusUpdateDrawer(true);
            }}
            color="info"
            sx={{ p: "4px", }}
          >
            <EditNoteRounded color="info" fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => handleDeleteRow(params?.row)}
            color="error"
            sx={{ p: "4px", }}
          >
            <DeleteOutlineRounded color="error" fontSize="small" />
          </IconButton>
        </Stack>
      },
    },
  ];


  const handleIconSelect = (iconName: string) => {
    const updatedRow = {
      ...selectedRow,
      icon: iconName,
    };

    apiRef.current.updateRows([updatedRow]);
    handleProcessRowUpdate(updatedRow, selectedRow);
  };

  const handleColorSelect = (color: string) => {
    const updatedRow = {
      ...selectedRow,
      color: color,
    };

    apiRef.current.updateRows([updatedRow]);
    handleProcessRowUpdate(updatedRow, selectedRow);
  };

  const handleDeleteRow = (item: IBaseLeadStatusEntity) => {
    setSelectedItemForDelete(item);
  }

  const filteredIcons = Object.keys(Icons).filter((iconName) =>
    iconName.endsWith('Outlined') && iconName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  function handleProcessRowUpdate(newRow: any, oldRow: any) {
    if (newRow.name !== oldRow.name && addStatusAvailable) {
      leadStatusUpdate({ filter: { _id: newRow._id }, input: { name: newRow.name } });
    }
    if (newRow.icon !== oldRow.icon && addStatusAvailable) {
      leadStatusUpdate({ filter: { _id: newRow._id }, input: { icon: newRow.icon } });
    }
    if (newRow.color !== oldRow.color && addStatusAvailable) {
      leadStatusUpdate({ filter: { _id: newRow._id }, input: { color: newRow.color } });
    }
    return newRow;
  };

  useEffect(() => {
    if (leadStatusesData && leadStatusesData.data) {
      setRows(leadStatusesData.data.map((status, index) => ({
        id: index + 1,
        _id: status._id,
        name: status.name || '-',
        icon: status.icon || '-',
        color: status.color || '-',
      })));
    }
  }, [leadStatusesData]);

  useEffect(() => {
    if (leadStatusUpdateError) {
      enqueueSnackbar('Lead Status Update Failed', { variant: 'error' });
    }
  }, [leadStatusUpdateError])

  useEffect(() => {
    if (leadStatusCreateError) {
      enqueueSnackbar('Lead Status Creation Failed', { variant: 'error' });
    }
  }, [leadStatusCreateError])



  return (
    <Grid item xs={12} height={"calc(100vh - 260px)"}>
      <HeaderBase
        title={t(`crm.lead.leadStatuses.title`)}
        rightButton={{
          icon: <Icons.AddCircle sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.lead.leadStatuses.create`),
          onClick: () => setOpenStatusCreateDrawer(true),
        }}
      />
      <Box minHeight={"350px"} maxHeight={"calc(100vh - 200px)"} mt={1} overflow={"auto"} borderRadius={"16px"}>
        <DataGridPremium
          localeText={currentLanguage === Language.tr ? trTR.components.MuiDataGrid.defaultProps.localeText : undefined}
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          showColumnVerticalBorder
          disableAggregation
          disableRowGrouping
          hideFooter
          processRowUpdate={handleProcessRowUpdate}
          loading={leadStatusesLoading || leadStatusUpdateLoading || leadStatusCreateLoading || leadStatusesFetching}
          onCellDoubleClick={(params, event) => {
            if (params.field === 'icon') {
              setOpenLeadStatusUpdateDrawer(true);
              setSelectedRow(params.row);
              event.stopPropagation();
            }
            if (params.field === 'color') {
              setOpenLeadStatusUpdateDrawer(true);
              setSelectedRow(params.row);
              event.stopPropagation();
            }
          }}
          scrollbarSize={1}
          scrollEndThreshold={10}
          pinnedColumns={{ left: ['id'], right: ['actions'] }}
          rowsLoadingMode="server"
          sx={[tableStyle, {
            backgroundColor: 'background.secondary', minHeight: "350px",
            '&. .MuiDataGrid-topContainer': {
              border: "2px solid #000"
            },
          }]}
        />
      </Box>


      <LeadStatusCreateWrapper
        open={openStatusCreateDrawer}
        setOpen={setOpenStatusCreateDrawer}
      />

      <LeadStatusUpdateDrawer
        open={openLeadStatusUpdateDrawer}
        setOpen={setOpenLeadStatusUpdateDrawer}
        selectedRow={selectedRow}
      />

      <Delete
        open={!!selectedItemForDelete}
        onClose={() => setSelectedItemForDelete(null)}
        item={selectedItemForDelete}
      />
    </Grid >
  );
};

export default List;



export const tableStyle = {
  fontSize: "12px",
  borderRadius: "16px",

  '& .MuiDataGrid-toolbarContainer > button': {
    fontSize: "12px",
  },
  '& .MuiDataGrid-columnHeader .MuiIconButton-root': {
    fontSize: "12px",
    p: "2px",
    border: "none !important",
  },
  '& .MuiDataGrid-columnHeader .MuiSvgIcon-root': {
    width: "16px",
    height: "16px",
  },
  '& .MuiDataGrid-footerContainer': {
    height: '20px !important',
    maxHeight: '20px !important',
    m: 0,
  }
};



export const colors = [
  "primary.lighter",
  "secondary.lighter",
  "success.lighter",
  "error.lighter",
  "warning.lighter",
  "info.lighter",
  "primary.light",
  "secondary.light",
  "success.light",
  "error.light",
  "warning.light",
  "info.light",
  "primary.main",
  "secondary.main",
  "success.main",
  "error.main",
  "warning.main",
  "info.main",
  "primary.dark",
  "secondary.dark",
  "success.dark",
  "error.dark",
  "warning.dark",
  "info.dark",
  "primary.darker",
  "secondary.darker",
  "success.darker",
  "error.darker",
  "warning.darker",
  "info.darker",
]