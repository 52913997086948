/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Box, Checkbox, Chip, Divider, FormControlLabel, Grid, IconButton, Stack, styled, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { memo, useEffect, useState } from "react";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { listEmployeesRoute } from "../../../routes/organizationalChart.base.route";
import { useUserDetailQuery } from "apps/auth/context";
import { ArrowForwardIosSharp, Close, ExpandMore, InfoOutlined, Lock, LockOpen, Update } from "@mui/icons-material";
import { usePermissionListQuery } from "../../permissions";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { MyTab, OutlinedButton } from "components";
import moment from "moment";
import { useUserPermissionDetailQuery, useUserPermissionUpdateMutation } from "../context/employees.api";
import TestInfo from "components/testInfoDrawer/TestInfoDrawer";

interface IPermission {
  _id: string;
  action: string;
  subject: string;
  actionScope: string;
  description: string;
  domain: string;
  subdomain: string;
}



const UserDetail = memo(() => {
  // general
  const { t } = useTranslation();
  const navigate = useNavigate();
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down("lg"));
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const { id } = useParams();
  if (!id) {
    navigate(listEmployeesRoute());
  }
  const [selectedTab, setSelectedTab] = useState("Profile");
  const [expandedAccordions, setExpandedAccordions] = useState<Record<string, boolean>>({});
  const [selectedPermissionIds, setSelectedPermissionIds] = useState<string[]>([]);
  const [prohibitedPermissionIds, setProhibitedPermissionIds] = useState<string[]>([]);
  const [openedPermissions, setOpenedPermissions] = useState<any>([])

  //TODO Skeleton ayarlanacak
  //TODO success ve error mesajları ayarlanacak.

  // queries
  const { data: userData, isLoading: userLoading, isFetching: userFetching, error: userError } = useUserDetailQuery({
    input: {
      _id: id || ""
    }
  });
  const { data: permissionListData, isLoading: permissionListLoading, error: permissionListError } = usePermissionListQuery({
    "input": {
      "filter": {
        "actions": ["manage", "view", "import", "export"]
      }
    }
  } as any);
  const { data: permissionListAllData, isLoading: permissionListAllLoading, error: permissionListAllError } = usePermissionListQuery({
    "input": {
      "filter": {
        "actions": ["import", "export", "list", "create", "update", "delete", "detail"]
      }
    }
  } as any);
  const { data: userPermissionData, isLoading: userPermissionLoading, isFetching: userPermissionFetching, error: userPermissionError } = useUserPermissionDetailQuery({
    input: {
      _id: id || ""
    }
  })
  const [userUpdatePermission, { data: userUpdatePermissionData, isLoading: userUpdatePermissionLoading, error: userUpdatePermissionError }] = useUserPermissionUpdateMutation()


  const permissions = permissionListData?.data || [];
  const permissionsAll = permissionListAllData?.data || [];

  const groupBySubject = (permissions: IPermission[]) => {
    return permissions.reduce((acc, permission) => {
      if (!acc[permission.subject]) {
        acc[permission.subject] = [];
      }
      acc[permission.subject].push(permission);
      return acc;
    }, {} as Record<string, IPermission[]>);
  };


  const groupByDomain = (permissions: IPermission[]) => {
    return permissions.reduce((acc, permission) => {
      if (!acc[permission.domain]) {
        acc[permission.domain] = [];
      }
      acc[permission.domain].push(permission);
      return acc;
    }, {} as Record<string, IPermission[]>);
  };


  const mainGroupedPermissions = groupByDomain(permissions as IPermission[]);
  const groupedPermissions = groupBySubject(permissions as IPermission[]);
  const groupedPermissionsAll = groupBySubject(permissionsAll as IPermission[]);

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: downMd ? 0 : theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));



  const isIncludesRole = (permissionId: IPermission['_id']) => {
    const inRolePermissions = userPermissionData?.rolePermissions.some(p => p._id === permissionId);
    return inRolePermissions
  };


  const isProhibited = (permissionId: IPermission['_id']) => {
    const inProhibitedPermissions = userPermissionData?.userProhibitedPermissions.some(p => p._id === permissionId);
    return inProhibitedPermissions;
  };

  const handleCheckboxChange = (permissionId: IPermission['_id']) => {
    if (!isProhibited(permissionId)) {
      setSelectedPermissionIds(prev => {
        if (prev.includes(permissionId)) {
          if (isIncludesRole(permissionId)) {
            setProhibitedPermissionIds(prev => [...prev, permissionId]);
          }
          return prev.filter(id => id !== permissionId);
        } else {
          if (isIncludesRole(permissionId)) {
            setProhibitedPermissionIds(prohibitedPermissionIds.filter(id => id !== permissionId));
          }
          return [...prev, permissionId];
        }
      })
    } else {
      setSelectedPermissionIds(prev => {
        if (prev.includes(permissionId)) {
          if (isIncludesRole(permissionId)) {
            setProhibitedPermissionIds(prev => [...prev, permissionId]);
          }
          return prev.filter(id => id !== permissionId);
        } else {
          if (isIncludesRole(permissionId)) {
            setProhibitedPermissionIds(prohibitedPermissionIds.filter(id => id !== permissionId));
          }
          return [...prev, permissionId];
        }
      })
    }
  }

  const handleUpdatePermissions = () => {
    if (userPermissionData) {
      userUpdatePermission({
        input: {
          permissionIds: selectedPermissionIds.filter(id => !isIncludesRole(id)),
          prohibitedPermissionIds: prohibitedPermissionIds
        },
        filter: {
          _id: id || ""
        }
      })
    }
  }


  useEffect(() => {
    if (userPermissionData) {
      // prohibitedta olanları buradan çıkar
      setSelectedPermissionIds(
        userPermissionData?.rolePermissions.map(p => p._id)
          .concat(userPermissionData?.userAllowedPermissions.map(p => p._id))
          .filter(id => !userPermissionData?.userProhibitedPermissions.some(p => p._id === id))
      )
      setProhibitedPermissionIds(userPermissionData?.userProhibitedPermissions.map(p => p._id))
    }
  }, [userPermissionData]);

  return <Grid item xs={12}>
    <TestInfo testMessages={["aaaa", "bbb"]} />

    <HeaderBase
      title={t("crm.organizationalChart.employees.userDetail")}
      showBackButton={true}
    />
    <Stack direction={"column"} mt={2} borderRadius={"20px"} sx={{ boxShadow: "0px 0px 20px #ccc" }} >
      <Box
        width={"100%"}
        component={"img"}
        src={"https://picsum.photos/1000/200"}
        sx={{ borderTopLeftRadius: "20px", borderTopRightRadius: "20px", height: "200px", filter: "brightness(0.8)" }}
      />
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mx={2}>
        <Stack direction={"row"} alignItems={"center"} sx={{ mt: "-120px", }}>
          <Avatar
            sx={{ width: "120px", height: "120px", ml: 2, border: "3px solid #fff", boxShadow: "0px 0px 5px #bbb" }}
            src={"https://picsum.photos/500/500"}
          />
          <Stack direction={"column"}>
            <Typography fontSize={"24px"} fontWeight={600} ml={4} sx={{ zIndex: 1 }} color={"#fff"}>{userData?.name + " " + userData?.surname} </Typography>
            <Typography fontSize={"14px"} fontWeight={600} ml={4} sx={{ zIndex: 1 }} color={"#fff"}>{userData?.department?._id} </Typography>
          </Stack>
        </Stack>
        <MyTab
          labels={["Profile", "Permissions", "Associated Users", "History"]}
          setSelectedTab={setSelectedTab}
        />
      </Stack>
    </Stack>

    {selectedTab === "Profile" && <Stack direction={"column"} mt={2} borderRadius={"20px"} p={2} sx={{ boxShadow: "0px 0px 20px #ccc" }} >
      <Typography fontSize={"24px"} fontWeight={600}>About</Typography>
      <Grid container sx={{ mt: 2 }}>
        <Grid item display="flex" flexDirection="column" gap={1}>
          {[
            t("crm.organizationalChart.employees.email"),
            t("crm.organizationalChart.employees.phoneNumber"),
            t("crm.organizationalChart.employees.country"),
            t("crm.organizationalChart.employees.city"),
            t("crm.organizationalChart.employees.address"),
            t("crm.organizationalChart.employees.birthDate"),
            t("crm.organizationalChart.employees.gender"),
            t("crm.organizationalChart.employees.language"),
            t("crm.organizationalChart.employees.description"),
            t("crm.organizationalChart.employees.lastLoginDate"),
          ].map((label, index) => (
            <Box display="flex" alignItems="center" minHeight={35} key={index}>
              <Typography fontSize={"14px"} fontWeight={600}>
                {label}
              </Typography>
            </Box>
          ))}
        </Grid>

        <Grid item display="flex" flexDirection="column" gap={1}>
          {[
            userData?.email,
            (userData as any)?.phoneNumber,
            userData?.country,
            userData?.city,
            userData?.address,
            moment(userData?.birthDate).format("DD/MM/YYYY") || "Invalid date",
            userData?.gender,
            userData?.language,
            userData?.description,
            moment((userData as any)?.lastLoginDate).format("DD/MM/YYYY") || "Invalid date",
          ].map((value, index) => (
            <Box display="flex" alignItems="center" minHeight={35} key={index}>
              <Typography fontSize={"14px"} fontWeight={400}>
                : {value}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Stack>}

    {selectedTab === "Permissions" && <Stack direction={"column"} mt={2} borderRadius={"20px"} p={2} sx={{ boxShadow: "0px 0px 20px #ccc" }} >
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ width: "100%" }}>
        <Typography fontSize={"24px"} fontWeight={600} mb={2}>Permissions</Typography>
        <OutlinedButton
          title="Update"
          leftIcon={<Update sx={{ mr: 1 }} />}
          onClick={() => {
            handleUpdatePermissions()
          }}
        />
      </Stack>

      {Object.keys(mainGroupedPermissions).reverse().map((domain, index) => (
        <Accordion key={domain} expanded={expandedAccordions[domain] || false} className="accordion-domain"
          onChange={() => {
            setExpandedAccordions(prevState => ({
              ...prevState,
              [domain]: !prevState[domain]
            }));
          }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={`panel-${domain}-content`}
            id={`panel-${domain}-header`}
          >
            <Stack direction={{ xs: "column", md: "row" }} alignItems="center" justifyContent={"space-between"} sx={{ width: "100%" }}>
              <Typography fontSize={"large"} fontWeight={"bold"}>
                {domain?.slice(0, 1).toUpperCase() + domain?.slice(1)}
              </Typography>
              {!downLg && <Stack direction="row" spacing={1}>
                {mainGroupedPermissions[domain].reduce((uniqueSubdomains: string[], permission: IPermission) => {
                  if (!uniqueSubdomains.includes(permission.subdomain)) {
                    uniqueSubdomains.push(permission.subdomain);
                  }
                  return uniqueSubdomains;
                }, []).map(subdomain => (
                  <Chip
                    key={subdomain}
                    label={subdomain?.slice(0, 1).toUpperCase() + subdomain?.slice(1)}
                    // size="small"
                    sx={{ fontWeight: 'bold', opacity: 0.7 }}
                    variant="outlined"
                    color="primary"
                  />
                ))}
              </Stack>}
            </Stack>
          </AccordionSummary>
          <AccordionDetails
            sx={{

            }}>
            {!downLg && <Stack direction="row" alignItems="center" justifyContent={"flex-end"} sx={{ width: "100%" }}>
              <Stack direction={"row"} width={"150px"} alignItems={"center"}>
                <Typography fontSize={"small"} fontWeight={"bold"}>
                  Organization Level
                </Typography>
                <Tooltip title="The level of the organization in which the user is located. The level of the organization in which the user is located.">
                  <InfoOutlined sx={{ fontSize: "14px", ml: "2px" }} />
                </Tooltip>
              </Stack>
              <Stack direction={"row"} width={"150px"} alignItems={"center"}>
                <Typography fontSize={"small"} fontWeight={"bold"}>
                  Department Level
                </Typography>
                <Tooltip title="The level of the department in which the user is located. The level of the department in which the user is located.">
                  <InfoOutlined sx={{ fontSize: "14px", ml: "2px" }} />
                </Tooltip>
              </Stack>
              <Stack direction={"row"} width={"160px"} alignItems={"center"}>
                <Typography fontSize={"small"} fontWeight={"bold"}>
                  User Level
                </Typography>
                <Tooltip title="The level of the user in which the user is located. The level of the user in which the user is located.">
                  <InfoOutlined sx={{ fontSize: "14px", ml: "2px" }} />
                </Tooltip>
              </Stack>
            </Stack>}
            {Object.keys(groupedPermissions)
              .filter((subject) => groupedPermissions[subject][0]?.domain === domain)
              .map((subject) => (
                <Accordion key={subject} expanded={false}>
                  <AccordionSummary
                    expandIcon={null}>
                    <Stack direction={{ xs: "column", lg: "row" }} alignItems="center" justifyContent={"space-between"} sx={{ width: "100%" }}>
                      <Typography fontSize={"medium"} fontWeight={"bold"}>
                        {subject?.slice(0, 1).toUpperCase() + subject?.slice(1)}
                      </Typography>
                      {downLg && <Stack direction="row" alignItems="center" justifyContent={"center"} sx={{ width: "100%" }}>
                        <Stack width={{ xs: "75px", sm: "100px", lg: "150px" }}>
                          <Typography fontSize={"10px"} textAlign={"center"} fontWeight={"bold"} sx={{ opacity: 0.5 }}>
                            Organization
                          </Typography>
                        </Stack>
                        <Stack width={{ xs: "75px", sm: "100px", lg: "150px" }}>
                          <Typography fontSize={"10px"} textAlign={"center"} fontWeight={"bold"} sx={{ opacity: 0.5 }}>
                            Department
                          </Typography>
                        </Stack>
                        <Stack width={{ xs: "75px", sm: "100px", lg: "150px" }}>
                          <Typography fontSize={"10px"} textAlign={"center"} fontWeight={"bold"} sx={{ opacity: 0.5 }}>
                            User
                          </Typography>
                        </Stack>
                      </Stack>}
                      {downLg && <Divider sx={{ width: { xs: '80%', sm: '300px' }, mr: { xs: "0px", sm: "50px" }, mt: 0.5, mb: 0.5 }} />}
                      <Stack direction="row" alignItems="center" justifyContent={"flex-end"}>
                        <Stack direction="row">
                          <Stack width={{ xs: "75px", sm: "100px", lg: "150px" }} >
                            {groupedPermissions[subject].filter((permission) => permission.actionScope === 'org').map((permission) => {
                              return <Stack direction={"row"} alignItems={"center"} key={permission._id}>
                                <FormControlLabel
                                  key={permission._id}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                  control={
                                    <Checkbox
                                      checked={selectedPermissionIds.includes(permission._id)}
                                      disabled={isIncludesRole(permission._id) && !openedPermissions.includes(permission._id)}
                                      onChange={() => {
                                        handleCheckboxChange(permission._id)
                                      }}
                                      icon={isIncludesRole(permission._id) ? <Close sx={{ fontSize: "15px", m:"2.5px", border: "2px solid", borderRadius: "3px"}} /> : undefined}
                                    />
                                  }
                                  label={permission.action?.slice(0, 1).toUpperCase() + permission.action?.slice(1)}
                                  sx={{
                                    ml: { xs: 0, sm: "10px" },
                                    ".MuiFormControlLabel-label": {
                                      fontSize: { xs: "11px", md: "small" },
                                      fontWeight: "bold",
                                    }
                                  }}
                                />
                                {isIncludesRole(permission._id) && <IconButton
                                  size="small"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    openedPermissions?.includes(permission._id) ? setOpenedPermissions(openedPermissions.filter((id: any) => id !== permission._id)) : setOpenedPermissions([...openedPermissions, permission._id]);
                                  }}
                                  sx={{ ml: -2 }}
                                >
                                  {openedPermissions?.includes(permission._id) ? <Lock sx={{ fontSize: "16px", color: "primary" }} /> : <LockOpen sx={{ fontSize: "16px", color: "primary" }} />}
                                </IconButton>}
                              </Stack>
                            })}
                          </Stack>
                          <Stack width={{ xs: "75px", sm: "100px", lg: "150px" }} >
                            {groupedPermissions[subject].filter((permission) => permission.actionScope === 'dep').map((permission) => {
                              return <Stack direction={"row"} alignItems={"center"} key={permission._id}>
                                <FormControlLabel
                                  key={permission._id}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                  control={
                                    <Checkbox
                                      checked={selectedPermissionIds.includes(permission._id)}
                                      disabled={isIncludesRole(permission._id) && !openedPermissions.includes(permission._id)}
                                      onChange={() => {
                                        handleCheckboxChange(permission._id)
                                      }}
                                    />
                                  }
                                  label={permission.action?.slice(0, 1).toUpperCase() + permission.action?.slice(1)}
                                  sx={{
                                    ml: { xs: 0, sm: "10px" },
                                    ".MuiFormControlLabel-label": {
                                      fontSize: { xs: "11px", md: "small" },
                                      fontWeight: "bold",
                                    }
                                  }}
                                />
                                {isIncludesRole(permission._id) && <IconButton
                                  size="small"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    openedPermissions?.includes(permission._id) ? setOpenedPermissions(openedPermissions.filter((id: any) => id !== permission._id)) : setOpenedPermissions([...openedPermissions, permission._id]);
                                  }}
                                  sx={{ ml: -2 }}
                                >
                                  {openedPermissions?.includes(permission._id) ? <Lock sx={{ fontSize: "16px", color: "primary" }} /> : <LockOpen sx={{ fontSize: "16px", color: "primary" }} />}
                                </IconButton>}
                              </Stack>
                            })}
                          </Stack>
                          <Stack width={{ xs: "75px", sm: "100px", lg: "150px" }}>
                            {groupedPermissions[subject].filter((permission) => permission.actionScope === 'own').map((permission) => {
                              return <Stack direction={"row"} alignItems={"center"} key={permission._id}>
                                <FormControlLabel
                                  key={permission._id}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                  control={
                                    <Checkbox
                                      checked={selectedPermissionIds.includes(permission._id)}
                                      disabled={isIncludesRole(permission._id) && !openedPermissions.includes(permission._id)}
                                      onChange={() => {
                                        handleCheckboxChange(permission._id)
                                      }}
                                    />
                                  }
                                  label={permission.action?.slice(0, 1).toUpperCase() + permission.action?.slice(1)}
                                  sx={{
                                    ml: { xs: 0, sm: "10px" },
                                    ".MuiFormControlLabel-label": {
                                      fontSize: { xs: "11px", md: "small" },
                                      fontWeight: "bold",
                                    }
                                  }}
                                />
                                {isIncludesRole(permission._id) && <IconButton
                                  size="small"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    openedPermissions?.includes(permission._id) ? setOpenedPermissions(openedPermissions.filter((id: any) => id !== permission._id)) : setOpenedPermissions([...openedPermissions, permission._id]);
                                  }}
                                  sx={{ ml: -2 }}
                                >
                                  {openedPermissions?.includes(permission._id) ? <Lock sx={{ fontSize: "16px", color: "primary" }} /> : <LockOpen sx={{ fontSize: "16px", color: "primary" }} />}
                                </IconButton>}
                              </Stack>
                            })}
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </AccordionSummary>
                </Accordion>
              ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>}

  </Grid>
});

export default UserDetail;


const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)',
  }),
}));
