/* eslint-disable react-hooks/exhaustive-deps */
import { Add, EditNoteRounded } from "@mui/icons-material";
import { Autocomplete, Box, Button, Chip, Grid, IconButton, Popover, Stack, TextField, Tooltip, } from "@mui/material";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import { memo, useCallback, useEffect, useState } from "react";
import { DataGridPremium, GridColDef, useGridApiRef, GridCellModes, GridCellModesModel, GridCellParams } from "@mui/x-data-grid-premium";
import { tableStyle } from "apps/crm/domains/03-lead/subdomains/leadStatuses";
import { trTR } from '@mui/x-data-grid/locales';
import { getCurrentLanguage } from "localization";
import { Language, unknownError } from "corede-common";
import { useDepartmentListQuery, useDepartmentUpdateMutation } from "../context/departments.api";
import * as Icons from '@mui/icons-material';
import { IBaseLeadSourceEntity } from "corede-common-cocrm";
import EmptyState from "components/emptyState/EmptyState";
import { useRoleListQuery, RolesDrawer } from "../../permissions";
import { useNavigate } from "react-router-dom";
import { listPermissionsRoute } from "../../../routes/organizationalChart.base.route";
import { DeleteDialog } from "./DeleteDialog";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import DepartmentCreateWrapper from "./DepartmentCreateWrapper";
import DepartmentUpdateWrapper from "./DepartmentUpdateWrapper";

const List = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const apiRef = useGridApiRef();
  const [openDepartmentCreateDrawer, setOpenDepartmentCreateDrawer] = useState(false);
  const [openDepartmentUpdateDrawer, setOpenDepartmentUpdateDrawer] = useState(false);
  const [openRolesDrawer, setOpenRolesDrawer] = useState(false);
  const [selectedItemForDelete, setSelectedItemForDelete] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any>();
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleCellClick = useCallback((params: GridCellParams, event: React.MouseEvent) => {
    if (params.field === 'roles') {
      setAnchorEl(event.currentTarget as HTMLButtonElement);
      setSelectedRow(params.row);
    }

    if (params.field === "name") {
      setCellModesModel((prevModel) => {
        return {
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View }
                }),
                {}
              )
            }),
            {}
          ),
          [params.id]: {
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit }
          }
        };
      });
    }

  }, []);

  const handleClickRolesMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseRolesMenu = () => {
    setAnchorEl(null);
  };

  const handleCellModesModelChange = useCallback((newModel: any) => {
    setCellModesModel(newModel);
  }, []);

  const { data: roleListData, isLoading: roleListLoading, error: roleListError } = useRoleListQuery({})
  const { data: departmentListData, isLoading: departmentListLoading, isFetching: departmentListFetching, error: departmentListError } = useDepartmentListQuery({})
  const [departmentUpdate, { isLoading: departmentUpdateLoading, error: departmentUpdateError }] = useDepartmentUpdateMutation()

  const [rows, setRows] = useState<any[]>(departmentListData?.data?.map((department, index) => ({
    id: index + 1,
    _id: department._id,
    name: department.name || '-',
    roles: department.roles || undefined,
  })) || []);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const columns: GridColDef[] = [
    { field: 'id', headerName: t("crm.organizationalChart.departments.columnId"), width: 50, disableColumnMenu: true, filterable: false, align: 'center', headerAlign: 'center' },
    { field: 'name', headerName: t("crm.organizationalChart.departments.columnName"), width: 160, editable: true },
    {
      field: 'roles', headerName: t("crm.organizationalChart.departments.columnRoles"), width: 200, editable: true,
      renderCell: (params: any) => {
        return (
          params.row?.roles?.map((role: any) => (
            <Chip
              key={role._id}
              label={roleListData?.data?.find((item: any) => item._id === role._id)?.name}
              variant="outlined"
              size="small"
              sx={{ mr: 1, fontSize: "11px", fontWeight: "bold" }}
              color="default"
            />
          ))
        );
      },
      renderEditCell(params) {
        return (
          <Button aria-describedby={id} variant="contained" onClick={handleClickRolesMenu} sx={{ display: "none" }}>
            {t("crm.organizationalChart.departments.rolesButton")}
          </Button>
        )
      },

    },
    {
      field: 'actions',
      headerName: t("crm.organizationalChart.departments.columnActions"),
      sortable: false,
      width: 120,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"center"} mt={{ xs: 0, md: 2 }}>
            <Tooltip title={t("crm.organizationalChart.departments.edit")}>
              <IconButton
                onClick={() => {
                  setSelectedRow(params.row);
                  setOpenDepartmentUpdateDrawer(true);
                }}
                color="info"
                sx={{ p: "4px", }}
              >
                <EditNoteRounded color="info" fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("crm.organizationalChart.departments.edit")}>
              <IconButton
                onClick={() => handleDeleteRow(params?.row)}
                color="error"
                sx={{ p: "4px", }}
              >
                <Icons.DeleteOutlineRounded color="error" fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  const handleDeleteRow = (item: IBaseLeadSourceEntity) => {
    setSelectedItemForDelete(item);
  }

  const handleProcessRowUpdate = (newRow: any, oldRow: any) => {
    if (newRow.name !== oldRow.name) {
      departmentUpdate({ filter: { _id: newRow._id }, input: { name: newRow.name } });
    }
    if (newRow.roles !== oldRow.roles) {
      departmentUpdate({ filter: { _id: newRow._id }, input: { roleIds: newRow.roles?.map((role: any) => role._id) } });
    }
    return newRow;
  };

  useEffect(() => {
    if (departmentListData?.data) {
      setRows(departmentListData?.data?.map((department, index) => ({
        id: index + 1,
        _id: department._id,
        name: department.name || '-',
        roles: department.roles || undefined,
      })))
    }
  }, [departmentListData?.data])

  useEffect(() => {
    if (roleListError) {
      enqueueSnackbar((roleListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [roleListError]);

  useEffect(() => {
    if (departmentListError) {
      enqueueSnackbar((departmentListError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [departmentListError]);

  useEffect(() => {
    if (departmentUpdateError) {
      enqueueSnackbar((departmentUpdateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [departmentUpdateError]);

  return <Grid item xs={12}>
    <HeaderBase
      title={t("crm.organizationalChart.departments.headerTitle")}
      rightButton={{
        title: t("crm.organizationalChart.departments.createDepartment"),
        onClick: () => {
          setOpenDepartmentCreateDrawer(true);
        },
        icon: <Add sx={{ mr: 1 }} />,
      }}
    />

    {departmentListData?.data?.length !== 0 ? <Box minHeight={"350px"} maxHeight={"calc(100vh - 200px)"} mt={1} overflow={"auto"} borderRadius={"16px"}>
      <DataGridPremium
        localeText={currentLanguage === Language.tr ? trTR.components.MuiDataGrid.defaultProps.localeText : undefined}
        apiRef={apiRef}
        cellModesModel={cellModesModel}
        onCellClick={handleCellClick}
        onCellModesModelChange={handleCellModesModelChange}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        showColumnVerticalBorder
        disableAggregation
        disableRowGrouping
        hideFooter
        processRowUpdate={handleProcessRowUpdate}
        loading={departmentListLoading || departmentUpdateLoading || roleListLoading || departmentListFetching}
        onCellDoubleClick={(params, event) => {
          if (params.field === 'icon') {
            setSelectedRow(params.row);
            event.stopPropagation();
          }
          if (params.field === 'color') {
            setSelectedRow(params.row);
            event.stopPropagation();
          }
        }}
        scrollbarSize={1}
        scrollEndThreshold={10}
        pinnedColumns={{ left: ['id'], right: ['actions'] }}
        rowsLoadingMode="server"
        sx={[tableStyle, {
          backgroundColor: 'background.secondary', minHeight: "350px",
          '&. .MuiDataGrid-topContainer': {
            border: "2px solid #000"
          },
        }]}
      />
    </Box> :
      <EmptyState
        content1={t("crm.organizationalChart.departments.emptyStateContent1")}
        content2={t("crm.organizationalChart.departments.emptyStateContent2")}
        button1={{
          title: t("crm.organizationalChart.departments.createDepartment"),
          onClick: () => {
            setOpenDepartmentCreateDrawer(true);
          },
          leftIcon: <Add />
        }}
      />
    }

    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleCloseRolesMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.4)'
        },
        '& .MuiPaper-root': {
          borderRadius: '16px'
        }
      }}
    >
      {roleListData && (
        <Box sx={{ p: 2, width: 300 }}>
          <Autocomplete
            multiple
            options={[...roleListData?.data, { _id: 'add_new', name: t("crm.organizationalChart.departments.addNewRole") }]} // 'Add New Role' seçeneği eklendi
            getOptionLabel={(option: any) => option.name}
            defaultValue={
              roleListData?.data?.filter((role: any) =>
                selectedRow?.roles?.some((selectedRole: any) => selectedRole._id === role._id)
              ) || []
            }
            onChange={(event, newValue) => {
              // "Add New Role" seçeneğine tıklandığında yönlendirme yap
              if (newValue.some((role: any) => role._id === 'add_new')) {
                navigate(listPermissionsRoute({ redirect: 'list-roles' }));
              } else {
                // Seçilen rolleri ve ilgili satırı güncelle
                const updatedRow = { ...selectedRow, roles: newValue };
                setSelectedRow(updatedRow);
                handleProcessRowUpdate(updatedRow, selectedRow);
              }
            }}
            renderTags={(value: any[], getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option.name}
                  {...getTagProps({ index })}
                  key={option._id}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label={t("crm.organizationalChart.departments.rolesLabel")} />
            )}
          />
        </Box>
      )}
    </Popover>

    <DepartmentCreateWrapper
      open={openDepartmentCreateDrawer}
      setOpen={setOpenDepartmentCreateDrawer}
      setOpenRoles={setOpenRolesDrawer}
    />

    <DepartmentUpdateWrapper
      open={openDepartmentUpdateDrawer}
      setOpen={setOpenDepartmentUpdateDrawer}
      setOpenRoles={setOpenRolesDrawer}
      selectedDepartment={selectedRow}
      setSelectedDepartment={setSelectedRow}
    />

    <RolesDrawer
      open={openRolesDrawer}
      setOpen={setOpenRolesDrawer}
    />
    <DeleteDialog
      open={Boolean(selectedItemForDelete)}
      onClose={() => setSelectedItemForDelete(undefined)}
      item={selectedItemForDelete}
    />


  </Grid>;
});

export default List;
