/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Chip, CircularProgress, Grid, InputLabel, Stack, SwipeableDrawer, Switch, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { convertEnums, getCurrentLanguage } from "localization";
import { Currency, IGraphqlVariables, unknownError, Language } from "corede-common";
import { IInvoiceCreateInput, CustomerType, RecurringFrequency } from "corede-common-cocrm";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useInvoiceCreateMutation } from "../context/invoices.api";
import { validateCreateInvoicesInput } from "../validations/create.validation";
import { useProductListQuery } from "../../../../08-productsAndServices/subdomains/common/context/productsServices.api";

import { useUserListQuery } from "apps/auth/context";
import { useLegalAgreementListQuery } from "apps/crm/domains/05-preSales/subdomains/legalAgreement";
import { ExpandMore, BusinessCenterOutlined } from "@mui/icons-material";

import countries from 'datas/countries.json';
import Turkey from 'datas/turkey.json';
import { ICity, ICountry, IState } from "datas/interfaces";

interface IInvoiceCreateDrawer {
  open: boolean
  setOpen: (open: boolean) => void
}

export const InvoiceCreateDrawer = (props: IInvoiceCreateDrawer) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries
  const { data: productListData, isLoading: productListLoading, error: productListError } = useProductListQuery({});
  const { data: userListData, isLoading: userListLoading, error: userListError } = useUserListQuery({});
  const { data: legalAgreementListData, isLoading: legalAgreementListLoading, error: legalAgreementListError } = useLegalAgreementListQuery({
    input: {
      pagination: {
        sort: {
          createdAt: 1
        }
      }
    }
  });
  const [loading, setLoading] = useState(false);
  const [inputValueAllowedPaymentMethod, setInputValueAllowedPaymentMethod] = useState('');
  const [cityOptions, setCityOptions] = useState<ICity[]>([]);
  const [billingCityOptions, setBillingCityOptions] = useState<ICity[]>([]);
  const [shippingCityOptions, setShippingCityOptions] = useState<ICity[]>([]);
  const localizedCurrencies = convertEnums(Currency);
  const localizedCustomerTypes = convertEnums(CustomerType);
  const localizedLanguages = convertEnums(Language);
  const localizedRecurringFrequencies = convertEnums(RecurringFrequency);
  const [invoiceCreate, { data: invoiceCreateData, isLoading: invoiceCreateLoading, error: invoiceCreateError }] = useInvoiceCreateMutation();

  const { values, handleSubmit, handleBlur, setFieldValue, errors, touched, resetForm, isValid } =
    useFormik({
      initialValues: {
        input: {
          invoiceId: 0,
          prefix: "",
          timezone: "",
          customerId: "",
          customerName: "",
          customerType: CustomerType.individual,
          customerCity: "",
          customerCountry: "",
          customerContactEmail: "",
          customerContactLastName: "",
          customerContactFirstName: "",
          billingInfo: {
            fullName: "",
            phone: "",
            address: "",
            country: "",
            state: "",
            city: "",
            street: "",
            zip: "",
            vat: "",
            taxOffice: "",
          },
          shippingInfo: {
            fullName: "",
            phone: "",
            address: "",
            country: "",
            state: "",
            city: "",
            street: "",
            zip: "",
          },
          isRecurring: false,
          recurringFrequency: RecurringFrequency.everyday,
          recurringDueDate: new Date(),
          recurringCount: 0,
          language: Language.en,
          date: new Date(),
          expireDate: new Date(),
          sendEmailOnExpiration: true,
          expirationEmailSentDate: new Date(),
          allowedPaymentMethods: [],
          paymentTerms: "",
          currency: Currency.EUR,
          products: [],
          totalPrice: {
            extraDiscount: 0,
            shippingTotal: 0
          },
          salesAgentIds: [],
          allowComments: true,
          useLegalNDA: true,
          legalNDAId: undefined,
          useLegalTermAndCond: true,
          legalTermAndCondId: undefined,
          signerUserId: undefined,
          signatureImage: {
            fileId: "",
            name: "",
            isPrivate: false,
            mimeType: "",
            s3Key: "",
            publicUrl: "",
            createdAt: undefined,
            updatedAt: undefined,
          }
        },
      },
      // enableReinitialize: true,
      validationSchema: validateCreateInvoicesInput,
      onSubmit: async (values: IGraphqlVariables<IInvoiceCreateInput>) => {
        setLoading(true);
        invoiceCreate({ input: values.input });
        setLoading(false);
      }
    });

  useEffect(() => {
    if (invoiceCreateData) {
      props.setOpen(false);
      enqueueSnackbar(t("crm.sales.invoices.invoiceCreateSuccess"), { variant: 'success' });
      resetForm();
    }
  }, [invoiceCreateData, resetForm]);

  useEffect(() => {
    if (invoiceCreateError) {
      console.log("invoiceCreateError", (invoiceCreateError as any)?.error?.message.en)
      enqueueSnackbar((invoiceCreateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [invoiceCreateError]);

  useEffect(() => {
    const selectedState = Turkey.states.find((state) => state.name === (values as any).input.customerCountry);
    if (selectedState && selectedState.cities) {
      setCityOptions(selectedState.cities);
    } else {
      setCityOptions([]);
    }
    setFieldValue("input.customerCity", '');
  }, [(values as any).input.customerCountry]);

  useEffect(() => {
    const selectedState = Turkey.states.find((state) => state.name === (values as any).input.billingInfo.country);
    if (selectedState && selectedState.cities) {
      setBillingCityOptions(selectedState.cities);
    } else {
      setBillingCityOptions([]);
    }
    setFieldValue("input.billingInfo.city", '');
  }, [(values as any).input.billingInfo.country]);

  useEffect(() => {
    const selectedState = Turkey.states.find((state) => state.name === (values as any).input.shippingInfo.country);
    if (selectedState && selectedState.cities) {
      setShippingCityOptions(selectedState.cities);
    } else {
      setShippingCityOptions([]);
    }
    setFieldValue("input.shippingInfo.city", '');
  }, [(values as any).input.shippingInfo.country]);


  return (
    <SwipeableDrawer
      open={props.open}
      onOpen={() => props.setOpen(true)}
      onClose={() => props.setOpen(false)}
      anchor='right'
    >
      <Grid item xs={12} width={600} bgcolor={"background.drawer"}>
        <Stack px={3} pt={2} pb={1} bgcolor={"background.paper"} sx={{ borderBottom: 2, borderColor: 'divider' }}>
          <Typography variant="h4" gutterBottom>
            {t("crm.sales.invoices.createInvoice")}
          </Typography>
        </Stack>
        <Stack direction="column" gap={1} p={3} bgcolor={"background.drawer"}>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={6}>
              <Grid item xs={12} >
                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.prefix")}*</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  required
                  name="input.prefix"
                  value={(values as any).input.prefix}
                  onChange={(e) => setFieldValue("input.prefix", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                  onBlur={handleBlur}
                  error={!!((errors as any)?.input?.prefix && (touched as any)?.input?.prefix)}
                  helperText={(touched as any)?.input?.prefix && (errors as any)?.input?.prefix}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid item xs={12} >
                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.timeZone")}*</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  required
                  name="input.timeZone"
                  value={(values as any).input.timeZone}
                  onChange={(e) => setFieldValue("input.timeZone", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                  onBlur={handleBlur}
                  error={!!((errors as any)?.input?.timeZone && (touched as any)?.input?.timeZone)}
                  helperText={(touched as any)?.input?.timeZone && (errors as any)?.input?.timeZone}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.language")}*</InputLabel>
              <Autocomplete
                size="small"
                id="language"
                fullWidth
                options={Object.values(Language).map((language) => ({
                  name: localizedLanguages[language as keyof typeof Language],
                  _id: language,
                }))}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} />}
                value={
                  values.input?.language
                    ? { name: localizedLanguages[values.input?.language as keyof typeof Language], _id: values.input?.language }
                    : null
                }
                onChange={(e, value) => {
                  setFieldValue("input.language", value?._id);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{"."}</InputLabel>
              <Box sx={{ border: "1px solid #ccc", borderRadius: "14px", p: 1, display: "flex", alignItems: "center" }}>
                <Switch
                  name="input.sendEmailOnExpiration"
                  checked={(values as any).input.sendEmailOnExpiration}
                  color="secondary"
                  size="small"
                  onChange={(e) => setFieldValue("input.sendEmailOnExpiration", e.target.checked)}
                />
                <Typography sx={{ ml: 1, fontSize: 'small' }}>{t("crm.sales.invoices.sendEmailOnExpiration")}</Typography>
              </Box>
            </Grid>


            {/*      <Grid item xs={12} sm={6}>
                            <Grid item xs={12} >
                                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.expirationEmailSentDate")}*</InputLabel>
                                <TextField
                                    fullWidth
                                    required
                                    name="input.expirationEmailSentDate"
                                    value={(values as any).input.expirationEmailSentDate}
                                    onChange={(e) => setFieldValue("input.expirationEmailSentDate", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    onBlur={handleBlur}
                                    error={!!((errors as any)?.input?.expirationEmailSentDate && (touched as any)?.input?.expirationEmailSentDate)}
                                    helperText={(touched as any)?.input?.expirationEmailSentDate && (errors as any)?.input?.expirationEmailSentDate}
                                />
                            </Grid>
                        </Grid> */}

            <Grid item xs={12} sm={6}>

                    //TODO product selection
              <Grid item xs={12}  >
                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.products")}</InputLabel>
                <Autocomplete
                  size="small"
                  id="saleAgentIds"
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  fullWidth
                  getOptionLabel={(option) => option.name ?? "-"}
                  options={[...(productListData?.data || []), { _id: "add", name: t("crm.sales.invoices.addSProduct") }]}
                  loading={userListLoading}
                  renderInput={(params) => <TextField {...params} />}
                  multiple
                  value={
                    values.input?.products && values.input.products.length > 0
                      ? productListData?.data?.filter((product) =>
                        (values as any).input.products.includes(product._id)
                      )
                      : []
                  }
                  onChange={(e, value) => {
                    const addRoleOption = value?.find(v => v._id === "add");


                    setFieldValue(
                      "input.products",
                      value?.map((product) => product._id) || []
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid item xs={12} >
                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.offers")}*</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  required
                  name="input.offers"
                  value={(values as any).input.offers}
                  onChange={(e) => setFieldValue("input.offers", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                  onBlur={handleBlur}
                  error={!!((errors as any)?.input?.offers && (touched as any)?.input?.offers)}
                  helperText={(touched as any)?.input?.offers && (errors as any)?.input?.offers}
                />
              </Grid>
            </Grid>


            <Grid item xs={12} sm={6}>
              <Grid item xs={12} >
                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.reference")}*</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  required
                  name="input.reference"
                  value={(values as any).input.reference}
                  onChange={(e) => setFieldValue("input.reference", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                  onBlur={handleBlur}
                  error={!!((errors as any)?.input?.reference && (touched as any)?.input?.reference)}
                  helperText={(touched as any)?.input?.reference && (errors as any)?.input?.reference}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid item xs={12}  >
                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.salesAgentIds")}</InputLabel>
                <Autocomplete
                  size="small"
                  id="saleAgentIds"
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  fullWidth
                  getOptionLabel={(option) => option.name ?? "-"}
                  options={[...(userListData?.data || []), { _id: "add", name: t("crm.sales.invoices.addSalesAgent") }]}
                  loading={userListLoading}
                  renderInput={(params) => <TextField {...params} />}
                  multiple
                  value={
                    values.input?.salesAgentIds && values.input.salesAgentIds.length > 0
                      ? userListData?.data?.filter((user) =>
                        (values as any).input.salesAgentIds.includes(user._id)
                      )
                      : []
                  }
                  onChange={(e, value) => {
                    const addRoleOption = value?.find(v => v._id === "add");


                    setFieldValue(
                      "input.salesAgentIds",
                      value?.map((user) => user._id) || []
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{"."}</InputLabel>
              <Box sx={{ border: "1px solid #ccc", borderRadius: "14px", p: 1, display: "flex", alignItems: "center" }}>
                <Switch
                  name="input.allowComments"
                  checked={(values as any).input.allowComments}
                  color="secondary"
                  size="small"
                  onChange={(e) => setFieldValue("input.allowComments", e.target.checked)}
                />
                <Typography sx={{ ml: 1, fontSize: 'small' }}>{t("crm.sales.invoices.allowComments")}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid item xs={12}  >
                <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.signerUserId")}</InputLabel>
                <Autocomplete
                  size="small"
                  id="signerUserId"
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  fullWidth
                  getOptionLabel={(option) => option.email}
                  options={userListData?.data || []}
                  loading={legalAgreementListLoading}
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    values.input?.signerUserId
                      ? userListData?.data?.filter((user) =>
                        (values as any).input.signerUserId.includes(user._id)
                      )[0]
                      : undefined
                  }
                  onChange={(e, value) => {
                    setFieldValue(
                      "input.signerUserId",
                      value?._id
                    );
                  }}
                />

              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Accordion sx={{
                width: '100%',
                '&.MuiAccordion-root': {
                  boxShadow: 'none',
                  borderRadius: "16px",
                  border: '1px dashed',
                  borderColor: 'secondary.main',
                  '&.Mui-expanded': {
                    borderStyle: 'solid',
                    borderColor: 'primary.main',
                  },
                }
              }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{
                  borderRadius: "16px",
                  '&.Mui-expanded': {
                    bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                  },
                }}>
                  <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
                  <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>Customer Options</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12}  >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.customerId")}</InputLabel>
                        <Autocomplete
                          size="small"
                          id="customerId"
                          isOptionEqualToValue={(option, value) => option._id === value._id}
                          fullWidth
                          getOptionLabel={(option) => option.email}
                          options={userListData?.data || []}
                          loading={legalAgreementListLoading}
                          renderInput={(params) => <TextField {...params} />}
                          value={
                            values.input?.customerId
                              ? userListData?.data?.filter((user) =>
                                (values as any).input.customerId.includes(user._id)
                              )[0]
                              : undefined
                          }
                          onChange={(e, value) => {
                            setFieldValue(
                              "input.customerId",
                              value?._id
                            );
                          }}
                        />

                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.customerType")}*</InputLabel>
                      <Autocomplete
                        size="small"
                        id="customerType"
                        fullWidth
                        options={Object.values(CustomerType).map((customerType) => ({
                          name: localizedCustomerTypes[customerType as keyof typeof CustomerType],
                          _id: customerType,
                        }))}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} />}
                        value={
                          values.input?.customerType
                            ? { name: localizedCustomerTypes[values.input?.customerType as keyof typeof CustomerType], _id: values.input?.customerType }
                            : null
                        }
                        onChange={(e, value) => {
                          setFieldValue("input.customerType", value?._id);
                        }}
                      />
                    </Grid>


                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.customerName")}*</InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          required
                          name="input.customerName"
                          value={(values as any).input.customerName}
                          onChange={(e) => setFieldValue("input.customerName", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                          onBlur={handleBlur}
                          error={!!((errors as any)?.input?.customerName && (touched as any)?.input?.customerName)}
                          helperText={(touched as any)?.input?.customerName && (errors as any)?.input?.customerName}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.customerCountry")}</InputLabel>
                      <Autocomplete
                        size="small"
                        options={countries}
                        fullWidth
                        id="customerCountry"
                        getOptionLabel={(option: ICountry) => option.name}
                        onChange={(event: any, newValue: ICountry | null) => {
                          if (newValue) {
                            setFieldValue("input.customerCountry", newValue.name);
                          }
                        }}
                        value={countries.find((country) => country.name === (values as any).input.customerCountry)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.customerCity")}</InputLabel>
                      <Autocomplete
                        size="small"
                        options={Turkey.states}
                        fullWidth
                        id="customerCity"
                        getOptionLabel={(option: IState) => option.name}
                        onChange={(event: any, newValue: IState | null) => {
                          if (newValue) {
                            setFieldValue("input.customerCity", newValue.name);

                          } else {
                            setFieldValue("input.customerCity", '');

                          }
                        }}
                        value={Turkey.states.find((state) => state.name === (values as any).input.customerCity) || null}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.customerContactEmail")}*</InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          required
                          name="input.customerContactEmail"
                          value={(values as any).input.customerContactEmail}
                          onChange={(e) => setFieldValue("input.customerContactEmail", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                          onBlur={handleBlur}
                          error={!!((errors as any)?.input?.customerContactEmail && (touched as any)?.input?.customerContactEmail)}
                          helperText={(touched as any)?.input?.customerContactEmail && (errors as any)?.input?.customerContactEmail}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.customerContactFirstName")}*</InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          required
                          name="input.customerContactFirstName"
                          value={(values as any).input.customerContactFirstName}
                          onChange={(e) => setFieldValue("input.customerContactFirstName", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                          onBlur={handleBlur}
                          error={!!((errors as any)?.input?.customerContactFirstName && (touched as any)?.input?.customerContactFirstName)}
                          helperText={(touched as any)?.input?.customerContactFirstName && (errors as any)?.input?.customerContactFirstName}
                        />
                      </Grid>
                    </Grid>


                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.customerContactLastName")}*</InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          required
                          name="input.customerContactLastName"
                          value={(values as any).input.customerContactLastName}
                          onChange={(e) => setFieldValue("input.customerContactLastName", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                          onBlur={handleBlur}
                          error={!!((errors as any)?.input?.customerContactLastName && (touched as any)?.input?.customerContactLastName)}
                          helperText={(touched as any)?.input?.customerContactLastName && (errors as any)?.input?.customerContactLastName}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.customerContactEmail")}*</InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          required
                          name="input.customerContactEmail"
                          value={(values as any).input.customerContactEmail}
                          onChange={(e) => setFieldValue("input.customerContactEmail", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                          onBlur={handleBlur}
                          error={!!((errors as any)?.input?.customerContactEmail && (touched as any)?.input?.customerContactEmail)}
                          helperText={(touched as any)?.input?.customerContactEmail && (errors as any)?.input?.customerContactEmail}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>


            <Grid item xs={12}>
              <Accordion sx={{
                width: '100%',
                '&.MuiAccordion-root': {
                  boxShadow: 'none',
                  borderRadius: "16px",
                  border: '1px dashed',
                  borderColor: 'secondary.main',
                  '&.Mui-expanded': {
                    borderStyle: 'solid',
                    borderColor: 'primary.main',
                  },
                }
              }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{
                  borderRadius: "16px",
                  '&.Mui-expanded': {
                    bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                  },
                }}>
                  <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
                  <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>Recurring Options</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{"."}</InputLabel>
                      <Box sx={{ border: "1px solid #ccc", borderRadius: "14px", p: 1, display: "flex", alignItems: "center" }}>
                        <Switch
                          name="input.isRecurring"
                          checked={(values as any).input.isRecurring}
                          color="secondary"
                          size="small"
                          onChange={(e) => {
                            setFieldValue("input.isRecurring", e.target.checked)
                            const a = values;

                          }}
                        />
                        <Typography sx={{ ml: 1, fontSize: 'small' }}>{t("crm.sales.invoices.isRecurring")}</Typography>
                      </Box>
                    </Grid>

                    {values.input?.isRecurring ??
                      <>
                        <Grid item xs={12} sm={6}>
                          <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.recurringFrequency")}*</InputLabel>
                          <Autocomplete
                            size="small"
                            id="recurringFrequency"
                            fullWidth
                            options={Object.values(RecurringFrequency).map((recurringFrequency) => ({
                              name: localizedRecurringFrequencies[recurringFrequency as keyof typeof RecurringFrequency],
                              _id: recurringFrequency,
                            }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} />}
                            value={
                              values.input?.recurringFrequency
                                ? { name: localizedRecurringFrequencies[values.input?.recurringFrequency as keyof typeof RecurringFrequency], _id: values.input?.recurringFrequency }
                                : null
                            }
                            onChange={(e, value) => {
                              setFieldValue("input.recurringFrequency", value?._id);
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Grid item xs={12} >
                            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.recurringCount")}*</InputLabel>
                            <TextField
                              fullWidth
                              required
                              type="number"
                              name="input.recurringCount"
                              value={(values as any).input.recurringCount}
                              onChange={(e) => setFieldValue("input.recurringCount", Number(e.target.value))}
                              onBlur={handleBlur}
                              error={!!((errors as any)?.input?.recurringCount && (touched as any)?.input?.recurringCount)}
                              helperText={(touched as any)?.input?.recurringCount && (errors as any)?.input?.recurringCount}
                            />
                          </Grid>
                        </Grid></>}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Accordion sx={{
                width: '100%',
                '&.MuiAccordion-root': {
                  boxShadow: 'none',
                  borderRadius: "16px",
                  border: '1px dashed',
                  borderColor: 'secondary.main',
                  '&.Mui-expanded': {
                    borderStyle: 'solid',
                    borderColor: 'primary.main',
                  },
                }
              }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{
                  borderRadius: "16px",
                  '&.Mui-expanded': {
                    bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                  },
                }}>
                  <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
                  <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>Payment Terms and Options</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.allowedPaymentMethods")}</InputLabel>
                      <Autocomplete
                        size="small"
                        multiple
                        freeSolo
                        id="allowedPaymentMethods"
                        options={[]}
                        value={(values as any).input.allowedPaymentMethods}
                        inputValue={inputValueAllowedPaymentMethod}
                        onInputChange={(event, newInputValue) => {
                          setInputValueAllowedPaymentMethod(newInputValue);
                        }}
                        onChange={(event, newValue) => {
                          setFieldValue("input.allowedPaymentMethods", newValue);
                        }}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter' || event.key === ',') {
                            event.preventDefault();
                            const inputValueTrimmed = inputValueAllowedPaymentMethod.trim();
                            if (inputValueTrimmed !== '') {
                              setFieldValue("input.allowedPaymentMethods", [...(values as any).input.allowedPaymentMethods, inputValueTrimmed]);
                              setInputValueAllowedPaymentMethod('');
                            }
                          }
                        }}
                        renderTags={(value, getAllowedPaymentMethodsProps) =>
                          value.map((option, index) => {
                            const { key, ...tagProps } = getAllowedPaymentMethodsProps({ index });
                            return (
                              <Chip
                                key={key}
                                size="small"
                                variant="outlined"
                                label={option}
                                {...tagProps}
                              />
                            );
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={t("crm.sales.invoices.allowedPaymentMethodsPlaceholder")}
                            error={(touched as any).input?.allowedPaymentMethods && Boolean((errors as any).input?.allowedPaymentMethods)}
                            helperText={(touched as any).input?.allowedPaymentMethods && (errors as any).input?.allowedPaymentMethods}
                          />
                        )}
                      />

                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.currency")}*</InputLabel>
                      <Autocomplete
                        size="small"
                        id="currency"
                        fullWidth
                        options={Object.values(Currency).map((currency) => ({
                          name: localizedCurrencies[currency as keyof typeof Currency],
                          _id: currency,
                        }))}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} />}
                        value={
                          values.input?.currency
                            ? { name: localizedCurrencies[values.input?.currency as keyof typeof Currency], _id: values.input?.currency }
                            : null
                        }
                        onChange={(e, value) => {
                          setFieldValue("input.currency", value?._id);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.paymentTerms")}*</InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          required
                          name="input.paymentTerms"
                          value={(values as any).input.paymentTerms}
                          onChange={(e) => setFieldValue("input.paymentTerms", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                          onBlur={handleBlur}
                          error={!!((errors as any)?.input?.paymentTerms && (touched as any)?.input?.paymentTerms)}
                          helperText={(touched as any)?.input?.paymentTerms && (errors as any)?.input?.paymentTerms}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{"."}</InputLabel>
                      <Box sx={{ border: "1px solid #ccc", borderRadius: "14px", p: 1, display: "flex", alignItems: "center" }}>
                        <Switch
                          name="input.useLegalNDA"
                          checked={(values as any).input.useLegalNDA}
                          color="secondary"
                          size="small"
                          onChange={(e) => setFieldValue("input.useLegalNDA", e.target.checked)}
                        />
                        <Typography sx={{ ml: 1, fontSize: 'small' }}>{t("crm.sales.invoices.useLegalNDA")}</Typography>
                      </Box>
                    </Grid>


                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12}  >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.legalNDAId")}</InputLabel>
                        <Autocomplete
                          size="small"
                          id="legalNDAId"
                          isOptionEqualToValue={(option, value) => option._id === value._id}
                          fullWidth
                          getOptionLabel={(option) => option.name}
                          options={legalAgreementListData?.data || []}
                          loading={legalAgreementListLoading}
                          renderInput={(params) => <TextField {...params} />}
                          value={
                            values.input?.legalNDAId
                              ? legalAgreementListData?.data?.filter((legalAgreement) =>
                                (values as any).input.legalNDAId.includes(legalAgreement._id)
                              )[0]
                              : undefined
                          }
                          onChange={(e, value) => {
                            setFieldValue(
                              "input.legalNDAId",
                              value?._id
                            );
                          }}
                        />

                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{"."}</InputLabel>
                      <Box sx={{ border: "1px solid #ccc", borderRadius: "14px", p: 1, display: "flex", alignItems: "center" }}>
                        <Switch
                          name="input.useLegalTermAndCond"
                          checked={(values as any).input.useLegalTermAndCond}
                          color="secondary"
                          size="small"
                          onChange={(e) => setFieldValue("input.useLegalTermAndCond", e.target.checked)}
                        />
                        <Typography sx={{ ml: 1, fontSize: 'small' }}>{t("crm.sales.invoices.useLegalTermAndCond")}</Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12}  >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.legalTermAndCondId")}</InputLabel>
                        <Autocomplete
                          size="small"
                          id="legalTermAndCondId"
                          isOptionEqualToValue={(option, value) => option._id === value._id}
                          fullWidth
                          getOptionLabel={(option) => option.name}
                          options={legalAgreementListData?.data || []}
                          loading={legalAgreementListLoading}
                          renderInput={(params) => <TextField {...params} />}
                          value={
                            values.input?.legalTermAndCondId
                              ? legalAgreementListData?.data?.filter((legalAgreement) =>
                                (values as any).input.legalTermAndCondId.includes(legalAgreement._id)
                              )[0]
                              : undefined
                          }
                          onChange={(e, value) => {
                            setFieldValue(
                              "input.legalTermAndCondId",
                              value?._id
                            );
                          }}
                        />
                      </Grid>
                    </Grid>

                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Accordion sx={{
                width: '100%',
                '&.MuiAccordion-root': {
                  boxShadow: 'none',
                  borderRadius: "16px",
                  border: '1px dashed',
                  borderColor: 'secondary.main',
                  '&.Mui-expanded': {
                    borderStyle: 'solid',
                    borderColor: 'primary.main',
                  },
                }
              }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{
                  borderRadius: "16px",
                  '&.Mui-expanded': {
                    bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                  },
                }}>
                  <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
                  <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>Shipping Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>

                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.shippingInfo.fullName")}*</InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          required
                          name="input.shippingInfo.fullName"
                          value={(values as any).input?.shippingInfo.fullName}
                          onChange={(e) => setFieldValue("input.shippingInfo.fullName", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                          onBlur={handleBlur}
                          error={!!((errors as any)?.input?.shippingInfo.fullName && (touched as any)?.input?.shippingInfo.fullName)}
                          helperText={(touched as any)?.input?.shippingInfo.fullName && (errors as any)?.input?.shippingInfo.fullName}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.shippingInfo.phone")}*</InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          required
                          name="input.shippingInfo.phone"
                          value={(values as any).input.shippingInfo.phone}
                          onChange={(e) => setFieldValue("input.shippingInfo.phone", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                          onBlur={handleBlur}
                          error={!!((errors as any)?.input?.shippingInfo.phone && (touched as any)?.input?.shippingInfo.phone)}
                          helperText={(touched as any)?.input?.shippingInfo.phone && (errors as any)?.input?.shippingInfo.phone}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.shippingInfo.address")}*</InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          required
                          name="input.shippingInfo.address"
                          value={(values as any).input.shippingInfo.address}
                          onChange={(e) => setFieldValue("input.shippingInfo.address", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                          onBlur={handleBlur}
                          error={!!((errors as any)?.input?.shippingInfo.address && (touched as any)?.input?.shippingInfo.address)}
                          helperText={(touched as any)?.input?.shippingInfo.address && (errors as any)?.input?.shippingInfo.address}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.shippingInfo.country")}</InputLabel>
                      <Autocomplete
                        size="small"
                        options={countries}
                        fullWidth
                        id="shippingInfo.country"
                        getOptionLabel={(option: ICountry) => option.name}
                        onChange={(event: any, newValue: ICountry | null) => {
                          if (newValue) {
                            setFieldValue("input.shippingInfo.country", newValue.name);
                          }
                        }}
                        value={countries.find((country) => country.name === (values as any).input.shippingInfo.country)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.shippingInfo.state")}</InputLabel>
                      <Autocomplete
                        size="small"
                        options={Turkey.states}
                        fullWidth
                        id="shippingInfo.state"
                        getOptionLabel={(option: IState) => option.name}
                        onChange={(event: any, newValue: IState | null) => {
                          if (newValue) {
                            setFieldValue("input.shippingInfo.state", newValue.name);
                            setFieldValue("input.shippingInfo.city", '');
                          } else {
                            setFieldValue("input.shippingInfo.state", '');
                            setFieldValue("input.shippingInfo.city", '');
                          }
                        }}
                        value={Turkey.states.find((state) => state.name === (values as any).input.shippingInfo.state) || null}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.shippingInfo.city")}</InputLabel>
                      <Autocomplete
                        size="small"
                        options={cityOptions}
                        fullWidth
                        id="shippingInfo.city"
                        getOptionLabel={(option: ICity) => option.name}
                        onChange={(event: any, newValue: ICity | null) => {
                          if (newValue) {
                            setFieldValue("input.shippingInfo.city", newValue.name);
                          } else {
                            setFieldValue("input.shippingInfo.city", '');
                          }
                        }}
                        value={shippingCityOptions.find((city) => city.name === (values as any).input.shippingInfo.city) || null}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.shippingInfo.street")}*</InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          required
                          name="input.shippingInfo.street"
                          value={(values as any).input.shippingInfo.street}
                          onChange={(e) => setFieldValue("input.shippingInfo.street", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                          onBlur={handleBlur}
                          error={!!((errors as any)?.input?.shippingInfo.street && (touched as any)?.input?.shippingInfo.street)}
                          helperText={(touched as any)?.input?.shippingInfo.street && (errors as any)?.input?.shippingInfo.street}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.shippingInfo.zip")}*</InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          required
                          name="input.shippingInfo.zip"
                          value={(values as any).input.shippingInfo.zip}
                          onChange={(e) => setFieldValue("input.shippingInfo.zip", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                          onBlur={handleBlur}
                          error={!!((errors as any)?.input?.shippingInfo.zip && (touched as any)?.input?.shippingInfo.zip)}
                          helperText={(touched as any)?.input?.shippingInfo.zip && (errors as any)?.input?.shippingInfo.zip}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Accordion sx={{
                width: '100%',
                '&.MuiAccordion-root': {
                  boxShadow: 'none',
                  borderRadius: "16px",
                  border: '1px dashed',
                  borderColor: 'secondary.main',
                  '&.Mui-expanded': {
                    borderStyle: 'solid',
                    borderColor: 'primary.main',
                  },
                }
              }}>
                <AccordionSummary expandIcon={<ExpandMore />} sx={{
                  borderRadius: "16px",
                  '&.Mui-expanded': {
                    bgcolor: 'primary.lighter', borderBottomLeftRadius: 0, borderBottomRightRadius: 0
                  },
                }}>
                  <BusinessCenterOutlined sx={{ mr: 1 }} fontSize="small" color="secondary" />
                  <Typography fontSize={"small"} fontWeight={500} sx={{ color: 'secondary.main' }}>Billing Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>

                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.billingInfo.fullName")}*</InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          required
                          name="input.billingInfo.fullName"
                          value={(values as any).input?.billingInfo.fullName}
                          onChange={(e) => setFieldValue("input.billingInfo.fullName", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                          onBlur={handleBlur}
                          error={!!((errors as any)?.input?.billingInfo.fullName && (touched as any)?.input?.billingInfo.fullName)}
                          helperText={(touched as any)?.input?.billingInfo.fullName && (errors as any)?.input?.billingInfo.fullName}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.billingInfo.phone")}*</InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          required
                          name="input.billingInfo.phone"
                          value={(values as any).input.billingInfo.phone}
                          onChange={(e) => setFieldValue("input.billingInfo.phone", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                          onBlur={handleBlur}
                          error={!!((errors as any)?.input?.billingInfo.phone && (touched as any)?.input?.billingInfo.phone)}
                          helperText={(touched as any)?.input?.billingInfo.phone && (errors as any)?.input?.billingInfo.phone}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.billingInfo.address")}*</InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          required
                          name="input.billingInfo.address"
                          value={(values as any).input.billingInfo.address}
                          onChange={(e) => setFieldValue("input.billingInfo.address", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                          onBlur={handleBlur}
                          error={!!((errors as any)?.input?.billingInfo.address && (touched as any)?.input?.billingInfo.address)}
                          helperText={(touched as any)?.input?.billingInfo.address && (errors as any)?.input?.billingInfo.address}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.billingInfo.country")}</InputLabel>
                      <Autocomplete
                        size="small"
                        options={countries}
                        fullWidth
                        id="billingInfo.country"
                        getOptionLabel={(option: ICountry) => option.name}
                        onChange={(event: any, newValue: ICountry | null) => {
                          if (newValue) {
                            setFieldValue("input.billingInfo.country", newValue.name);
                          }
                        }}
                        value={countries.find((country) => country.name === (values as any).input.billingInfo.country)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.billingInfo.state")}</InputLabel>
                      <Autocomplete
                        size="small"
                        options={Turkey.states}
                        fullWidth
                        id="billingInfo.state"
                        getOptionLabel={(option: IState) => option.name}
                        onChange={(event: any, newValue: IState | null) => {
                          if (newValue) {
                            setFieldValue("input.billingInfo.state", newValue.name);
                            setFieldValue("input.billingInfo.city", '');
                          } else {
                            setFieldValue("input.billingInfo.state", '');
                            setFieldValue("input.billingInfo.city", '');
                          }
                        }}
                        value={Turkey.states.find((state) => state.name === (values as any).input.billingInfo.state) || null}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.billingInfo.city")}</InputLabel>
                      <Autocomplete
                        size="small"
                        options={billingCityOptions}
                        fullWidth
                        id="billingInfo.city"
                        getOptionLabel={(option: ICity) => option.name}
                        onChange={(event: any, newValue: ICity | null) => {
                          if (newValue) {
                            setFieldValue("input.billingInfo.city", newValue.name);
                          } else {
                            setFieldValue("input.billingInfo.city", '');
                          }
                        }}
                        value={billingCityOptions.find((city) => city.name === (values as any).input.billingInfo.city) || null}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.billingInfo.street")}*</InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          required
                          name="input.billingInfo.street"
                          value={(values as any).input.billingInfo.street}
                          onChange={(e) => setFieldValue("input.billingInfo.street", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                          onBlur={handleBlur}
                          error={!!((errors as any)?.input?.billingInfo.street && (touched as any)?.input?.billingInfo.street)}
                          helperText={(touched as any)?.input?.billingInfo.street && (errors as any)?.input?.billingInfo.street}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.billingInfo.zip")}*</InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          required
                          name="input.billingInfo.zip"
                          value={(values as any).input.billingInfo.zip}
                          onChange={(e) => setFieldValue("input.billingInfo.zip", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                          onBlur={handleBlur}
                          error={!!((errors as any)?.input?.billingInfo.zip && (touched as any)?.input?.billingInfo.zip)}
                          helperText={(touched as any)?.input?.billingInfo.zip && (errors as any)?.input?.billingInfo.zip}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.billingInfo.vat")}*</InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          required
                          name="input.billingInfo.vat"
                          value={(values as any).input.billingInfo.vat}
                          onChange={(e) => setFieldValue("input.billingInfo.vat", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                          onBlur={handleBlur}
                          error={!!((errors as any)?.input?.billingInfo.vat && (touched as any)?.input?.billingInfo.vat)}
                          helperText={(touched as any)?.input?.billingInfo.vat && (errors as any)?.input?.billingInfo.vat}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid item xs={12} >
                        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.sales.invoices.billingInfo.taxOffice")}*</InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          required
                          name="input.billingInfo.taxOffice"
                          value={(values as any).input.billingInfo.taxOffice}
                          onChange={(e) => setFieldValue("input.billingInfo.taxOffice", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                          onBlur={handleBlur}
                          error={!!((errors as any)?.input?.billingInfo.taxOffice && (touched as any)?.input?.billingInfo.taxOffice)}
                          helperText={(touched as any)?.input?.billingInfo.taxOffice && (errors as any)?.input?.billingInfo.taxOffice}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid container spacing={5} pt={1.5}>
              <Grid item xs={12} >
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => handleSubmit()}
                  disabled={false} //TODO {loading || !isValid || invoiceCreateLoading || !(values as any).input?.name}
                >
                  {loading ? <CircularProgress size="1rem" /> : t("crm.sales.invoices.create")}
                </Button>
              </Grid>
            </Grid>
          </Grid >
        </Stack>
      </Grid>
    </SwipeableDrawer>
  );
};


export const colors = [
  "primary.lighter",
  "secondary.lighter",
  "success.lighter",
  "error.lighter",
  "warning.lighter",
  "info.lighter",
  "primary.light",
  "secondary.light",
  "success.light",
  "error.light",
  "warning.light",
  "info.light",
  "primary.main",
  "secondary.main",
  "success.main",
  "error.main",
  "warning.main",
  "info.main",
  "primary.dark",
  "secondary.dark",
  "success.dark",
  "error.dark",
  "warning.dark",
  "info.dark",
  "primary.darker",
  "secondary.darker",
  "success.darker",
  "error.darker",
  "warning.darker",
  "info.darker",
];
