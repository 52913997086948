import { IMenuItem } from "interfaces/menu-item.interface";
import { useTranslation } from "react-i18next"; // useTranslation hook'unu import ediyoruz
import {
  basePreSalesRoute,
  listEstimatesRoute,
  listLegalAgreementsRoute,
  listProposalsRoute,
} from "../routes/preSales.base.route";

export const usePreSalesDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation(); // useTranslation hook'unu kullanıyoruz

  return {
    title: t("preSales"), // getTranslatedText yerine t fonksiyonunu kullanıyoruz
    path: basePreSalesRoute(),
    children: [
      {
        title: t("estimates"),
        path: listEstimatesRoute(),
      },
      {
        title: t("proposals"),
        path: listProposalsRoute(),
      },

      //  Todo For Generation
      // {
      //   title: t("employees"),
      //   path: listEmployeesRoute(),
      // },
    ],
  };
};
