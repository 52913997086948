/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, IconButton, Modal, Stack, SvgIcon, TextField, Typography, } from "@mui/material";
import { useAppDispatch, } from "context";
import { leadActions } from "../../../context";
import { useEffect, useState } from "react";
import { useLeadSourceCreateMutation, useLeadSourcesQuery, useLeadSourceUpdateMutation, } from "../context/leadSources.api";
import { Cancel, DeleteOutlineRounded, EditNoteRounded, Save } from "@mui/icons-material";
import { DataGridPremium, GridColDef, GridRowModes, GridRowModesModel, useGridApiRef } from "@mui/x-data-grid-premium";
import { enqueueSnackbar } from "notistack";
import * as Icons from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { HeaderBase } from "components/contentHeaders/crm/HeaderBase";
import Delete from "./Delete";
import { IBaseLeadSourceEntity } from "corede-common-cocrm";
import { trTR } from '@mui/x-data-grid/locales';
import { Language } from "corede-common";
import { getCurrentLanguage } from "localization";
import { LeadSourceUpdateDrawer } from "./LeadSourcesUpdateDrawer";
import LeadSourcesCreateWrapper from "./LeadSourceCreateWraper";
import LeadSourcesCreateWraper from "./LeadSourceCreateWraper";


const List = () => {

  // general
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentLanguage = getCurrentLanguage();
  const apiRef = useGridApiRef();
  const { data: leadSourcesData, isLoading: leadSourcesLoading, isFetching: leadSourcesFetching } = useLeadSourcesQuery({
    input: {
      pagination: {
        sort: {
          createdAt: 1
        }
      }
    }
  });
  const [leadSourceUpdate, { isLoading: leadSourceUpdateLoading, error: leadSourceUpdateError }] = useLeadSourceUpdateMutation();
  const [leadSourceCreate, { isLoading: leadSourceCreateLoading, error: leadSourceCreateError }] = useLeadSourceCreateMutation();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [openSourceCreateDrawer, setOpenSourceCreateDrawer] = useState(false);
  const [openLeadSourceUpdateDrawer, setOpenLeadSourceUpdateDrawer] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState<any>('');
  const [addSourceAvailable, setAddSourceAvailable] = useState(true);
  const [openSelectIconset, setOpenSelectIconset] = useState(false);
  const [openColorModal, setOpenColorModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedItemForDelete, setSelectedItemForDelete] = useState<any>();

  const [rows, setRows] = useState<any[]>(leadSourcesData?.data?.map((source, index) => ({
    id: index + 1,
    _id: source._id,
    name: source.name || '-',
    icon: source.icon || '-',
    color: source.color || '-',
  })) || []);

  const columns: GridColDef[] = [
    { field: 'id', headerName: '#', width: 50, disableColumnMenu: true, filterable: false, align: 'center', headerAlign: 'center' },
    {
      field: 'name', headerName: 'Name', width: 160, editable: true,
      renderEditCell: (params) => {
        return (
          <TextField
            size="small"
            value={params.value || ''}
            onChange={(e) => {
              const newValue = e.target.value;
              setSelectedName(newValue);  // selectedName'i güncelle
              apiRef.current.setEditCellValue({ ...params, value: newValue });  // Grid'deki değeri güncelle
            }}
            onBlur={() => apiRef.current.stopCellEditMode({ id: params.id, field: params.field })}
          />
        );
      },
    },
    {
      field: 'icon', headerName: 'Icon', width: 100, editable: true,
      renderCell: (params) => {
        return (
          <SvgIcon component={(Icons as any)[params.value]} fontSize="small" sx={{ mt: 2, ml: 0.5 }} />
        )
      },
      renderEditCell: () => null
    },
    {
      field: 'color', headerName: 'Color', width: 100, editable: true,
      renderCell: (params) => {
        return (
          <Box sx={{ mt: 2, ml: 0.5, width: 20, height: 20, borderRadius: '50%', backgroundColor: params.value ?? 'background.primary' }} />
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 120,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

        return (
          <Stack direction={{ xs: "column", md: "row" }} alignItems={"center"} justifyContent={"center"} mt={{ xs: 0, md: 2 }}>
            <IconButton
              onClick={() => {
                setSelectedRow(params.row);
                setOpenLeadSourceUpdateDrawer(true);
              }}
              color="info"
              sx={{ p: "4px", }}
            >
              <EditNoteRounded color="info" fontSize="small" />
            </IconButton>
            <IconButton
              onClick={() => handleDeleteRow(params?.row)}
              color="error"
              sx={{ p: "4px", }}
            >
              <DeleteOutlineRounded color="error" fontSize="small" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];


  const handleCancelRow = (id: number) => {
    setRows((oldRows) => oldRows.filter((row) => row.id !== id));
    setRowModesModel((oldModel) => ({ ...oldModel, [id]: { mode: GridRowModes.View } }));
    setAddSourceAvailable(true);
  }


  const handleSaveRow = (id: number) => {
    const row = rows.find((r) => r.id === id);

    if (row.isNew) {
      leadSourceCreate({
        input: {
          name: selectedName,
          icon: selectedIcon,
          color: selectedColor
        }
      })
    }

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View },
    }));
    setAddSourceAvailable(true);
  }

  const handleIconSelect = (iconName: string) => {
    const updatedRow = {
      ...selectedRow,
      icon: iconName,
    };

    apiRef.current.updateRows([updatedRow]);
    handleProcessRowUpdate(updatedRow, selectedRow);
    setSelectedIcon(iconName);
    setOpenSelectIconset(false);
  };

  const handleColorSelect = (color: string) => {
    const updatedRow = {
      ...selectedRow,
      color: color,
    };

    apiRef.current.updateRows([updatedRow]);
    handleProcessRowUpdate(updatedRow, selectedRow);
    setSelectedColor(color);
    setOpenColorModal(false);
  };

  const handleDeleteRow = (item: IBaseLeadSourceEntity) => {
    setSelectedItemForDelete(item);
  }

  const filteredIcons = Object.keys(Icons).filter((iconName) =>
    iconName.endsWith('Outlined') && iconName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  function handleProcessRowUpdate(newRow: any, oldRow: any) {
    if (newRow.name !== oldRow.name && addSourceAvailable) {
      leadSourceUpdate({ filter: { _id: newRow._id }, input: { name: newRow.name } });
    }
    if (newRow.icon !== oldRow.icon && addSourceAvailable) {
      leadSourceUpdate({ filter: { _id: newRow._id }, input: { icon: newRow.icon } });
    }
    if (newRow.color !== oldRow.color && addSourceAvailable) {
      leadSourceUpdate({ filter: { _id: newRow._id }, input: { color: newRow.color } });
    }
    return newRow;
  };

  useEffect(() => {
    if (leadSourcesData && leadSourcesData.data) {
      setRows(leadSourcesData.data.map((source, index) => ({
        id: index + 1,
        _id: source._id,
        name: source.name || '-',
        icon: source.icon || '-',
        color: source.color || '-',
      })));
    }
  }, [leadSourcesData]);

  useEffect(() => {
    if (leadSourceUpdateError) {
      enqueueSnackbar('Lead Source Update Failed', { variant: 'error' });
    }
  }, [leadSourceUpdateError])

  useEffect(() => {
    if (leadSourceCreateError) {
      enqueueSnackbar('Lead Source Creation Failed', { variant: 'error' });
    }
  }, [leadSourceCreateError])


  //initial useEffects

  useEffect(() => {
    dispatch(leadActions.setTitle(t("crm.lead.lead.sources")));
    dispatch(leadActions.setBackButton(false));
    dispatch(leadActions.setRightButton(null));
  }, [dispatch]);



  return (
    <Grid item xs={12} height={"calc(100vh - 260px)"}>
      <HeaderBase
        title={t(`crm.lead.leadSources.title`)}
        rightButton={{
          icon: <Icons.AddCircle sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.lead.leadSources.create`),
          onClick: () => setOpenSourceCreateDrawer(true)
        }}
      />
      <Box minHeight={"350px"} maxHeight={"calc(100vh - 200px)"} mt={1} overflow={"auto"} borderRadius={"16px"}>
        <DataGridPremium
          localeText={currentLanguage === Language.tr ? trTR.components.MuiDataGrid.defaultProps.localeText : undefined}
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          showColumnVerticalBorder
          disableAggregation
          disableRowGrouping
          hideFooter
          processRowUpdate={handleProcessRowUpdate}
          loading={leadSourcesLoading || leadSourceUpdateLoading || leadSourceCreateLoading || leadSourcesFetching}
          onCellDoubleClick={(params, event) => {
            if (params.field === 'icon') {
              setOpenLeadSourceUpdateDrawer(true);
              setSelectedRow(params.row);
              event.stopPropagation();
            }
            if (params.field === 'color') {
              setOpenLeadSourceUpdateDrawer(true);
              setSelectedRow(params.row);
              event.stopPropagation();
            }
          }}
          scrollbarSize={1}
          scrollEndThreshold={10}
          pinnedColumns={{ left: ['id'], right: ['actions'] }}
          rowsLoadingMode="server"
          sx={[tableStyle, {
            backgroundColor: 'background.secondary', minHeight: "350px",
            '&. .MuiDataGrid-topContainer': {
              border: "2px solid #000"
            },
          }]}
        />
      </Box>


      <LeadSourcesCreateWraper
        open={openSourceCreateDrawer}
        setOpen={setOpenSourceCreateDrawer}
      />

      <LeadSourceUpdateDrawer
        open={openLeadSourceUpdateDrawer}
        setOpen={setOpenLeadSourceUpdateDrawer}
        selectedRow={selectedRow}
      />

      <Delete
        open={!!selectedItemForDelete}
        onClose={() => setSelectedItemForDelete(null)}
        item={selectedItemForDelete}
      />
    </Grid >
  );
};

export default List;



export const tableStyle = {
  fontSize: "12px",
  borderRadius: "16px",

  '& .MuiDataGrid-toolbarContainer > button': {
    fontSize: "12px",
  },
  '& .MuiDataGrid-columnHeader .MuiIconButton-root': {
    fontSize: "12px",
    p: "2px",
    border: "none !important",
  },
  '& .MuiDataGrid-columnHeader .MuiSvgIcon-root': {
    width: "16px",
    height: "16px",
  },
  '& .MuiDataGrid-footerContainer': {
    height: '20px !important',
    maxHeight: '20px !important',
    m: 0,
  }
};

export const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export const colors = [
  "primary.lighter",
  "secondary.lighter",
  "success.lighter",
  "error.lighter",
  "warning.lighter",
  "info.lighter",
  "primary.light",
  "secondary.light",
  "success.light",
  "error.light",
  "warning.light",
  "info.light",
  "primary.main",
  "secondary.main",
  "success.main",
  "error.main",
  "warning.main",
  "info.main",
  "primary.dark",
  "secondary.dark",
  "success.dark",
  "error.dark",
  "warning.dark",
  "info.dark",
  "primary.darker",
  "secondary.darker",
  "success.darker",
  "error.darker",
  "warning.darker",
  "info.darker",
]