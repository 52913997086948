
import { Backdrop } from '@mui/material';
import { useState, lazy, Suspense } from 'react';

const LeadDetailDrawer = lazy(() => import('./DetailDrawer'));

const LeadDetailWrapper = ({
    open,
    setOpen,
    selectedLead,
    setSelectedLead,
    handleSelect,
    count,
}: any) => {
    const [mounted, setMounted] = useState(false);

    if (open && !mounted) {
        setMounted(true);
    }

    return (
        <>
            {mounted && (
                <Suspense>
                    <LeadDetailDrawer
                        open={open}
                        setOpen={setOpen}
                        selectedLead={selectedLead}
                        setSelectedLead={setSelectedLead}
                        handleSelect={handleSelect}
                        count={count}
                    />
                </Suspense>
            )}
        </>
    );
};

export default LeadDetailWrapper;
