import { IMenuItem } from "interfaces/menu-item.interface";
import { useTranslation } from "react-i18next"; // useTranslation hook'unu import ediyoruz
import {
  baseProductsAndServicesRoute,
  listProductsRoute,
  listServicesRoute,
} from "../routes/productsAndServices.base.route";

export const useProductsAndServicesDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation(); // useTranslation hook'unu kullanıyoruz

  return {
    title: t("productsAndServices"), // getTranslatedText yerine t fonksiyonunu kullanıyoruz
    path: baseProductsAndServicesRoute(),
    children: [
      {
        title: t("products"),
        path: listProductsRoute(),
      },
      {
        title: t("services"),
        path: listServicesRoute(),
      },
      // Todo
      // {
      //   title: t("employees"),
      //   path: listEmployeesRoute(),
      // },
    ],
  };
};
