/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box, Stack, TextField, InputAdornment, Typography, useMediaQuery, Theme,
  Tooltip, Avatar, Divider, ListItemIcon, Menu, MenuItem, FormControl, Select, ToggleButton, ClickAwayListener,
  Dialog, DialogTitle, DialogContent, Grid, InputLabel, DialogActions, Button,
  CircularProgress
} from '@mui/material'
import LogoMini from "assets/images/logo-mini.png";
import { Iconify } from 'components';
import { useState, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { styled } from '@mui/system';
import { Settings, Logout } from '@mui/icons-material';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { commonApi, useAppDispatch, useAppSelector } from 'context';
import { removeUser } from 'apps/auth';
import { setRoute, setThemeMode } from 'context/slices';
import { Outlet } from "react-router-dom";
import { changeLanguage, getCurrentLanguage } from 'localization';
import { IGraphqlVariables, Language, unknownError } from "corede-common";
import { useTranslation } from "react-i18next";
import { IOrganizationCreateOwnInput } from "corede-common-cocrm";
import { useFormik } from "formik";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { useLogoutMutation, useOrganizationCreateOwnMutation, useUserDetailOwnQuery } from "apps/auth/context";
import { validateCreateOrganizationOwnInput } from "apps/crm/validations/createOrganizationOwn.validation";
import { enqueueSnackbar } from 'notistack';


const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '25px',
    width: '300px',
    height: "45px",
    backgroundColor: theme.palette.background.secondary,
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiInputBase-input': {
    padding: '10px 10px',
    fontWeight: 600,
    fontSize: '14px',
  },
}));



const AccountLayout = () => {


  const { data, isLoading } = useUserDetailOwnQuery({});
  const { route } = useAppSelector(state => state.route);
  const { organization, refreshToken } = useAppSelector(state => state.auth);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentLanguage = getCurrentLanguage();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [isTreeVisible, setTreeVisible] = useState(!downMd);
  const [mainMenuWidth, setMainMenuWidth] = useState(100);
  const [treeMenuOpacity, setTreeMenuOpacity] = useState(1);
  const [anchorElAccountMenu, setAnchorElAccountMenu] = useState<null | HTMLElement>(null);
  const openAccountMenu = Boolean(anchorElAccountMenu);
  const [language, setLanguage] = useState<Language>(currentLanguage);
  const [isDarkMode, setDarkMode] = useState(false);
  const [treeMenuDisplay, setTreeMenuDisplay] = useState("block");
  const [contentWidth, setContentWidth] = useState("calc(100%)");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [organizationCreateOwn, { isLoading: organizationCreateOwnLoading, error: organizationCreateOwnError }] = useOrganizationCreateOwnMutation();
  const [logout] = useLogoutMutation();



  const handleClickAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAccountMenu(event.currentTarget);
  };

  const handleCloseAccountMenu = () => {
    setAnchorElAccountMenu(null);
  };

  const toggleDarkMode = (checked: boolean) => {
    setDarkMode(checked);
    dispatch(setThemeMode(checked ? "dark" : "light"));
  };


  const handleLogout = () => {

    logout({
      input: { refreshToken: refreshToken }
    }).then(() => {
      dispatch(removeUser());
      dispatch(commonApi.util.resetApiState());
    }).catch(() => {
      dispatch(removeUser());
      dispatch(commonApi.util.resetApiState());
    })
  };


  const handleButtonClick = (param: string) => {
    (param !== "close" && param !== "clickaway") && dispatch(setRoute(param));
    if (param === route && isTreeVisible) {
      setTreeVisible(false);
      setMainMenuWidth(90);
      setTreeMenuOpacity(-1);
      setContentWidth(downMd ? 'calc(100dvw - 50px)' : 'calc(100dvw - 115px)');
    } else {
      setTreeMenuOpacity(1);
      setTreeVisible(true);
      setContentWidth(downMd ? 'calc(100dvw - 50px)' : 'calc(100dvw - 390px)');
    }
    if (param === "close") {
      setTreeVisible(false);
      setMainMenuWidth(90);
      setTreeMenuOpacity(-1);
      setContentWidth(downMd ? 'calc(100dvw - 50px)' : 'calc(100dvw - 115px)');
    }
    if (param === "clickaway") {
      setTreeVisible(false);
      setMainMenuWidth(90);
      setTreeMenuOpacity(-1);
      setContentWidth(downMd ? 'calc(100dvw - 50px)' : 'calc(100dvw - 115px)');
    }


  };

  const mainMenuStyle = useSpring({
    width: downMd ? 40 : mainMenuWidth,
  });


  const contentStyle = useSpring({
    width: contentWidth,
  });

  const { values, handleSubmit, handleBlur, setFieldValue, errors, touched } =
    useFormik({
      initialValues: {
        input: {
          name: "",
        },
      },
      enableReinitialize: true,
      validationSchema: validateCreateOrganizationOwnInput,
      onSubmit: async (values: IGraphqlVariables<IOrganizationCreateOwnInput>) => {
        const transformedValues =
          RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
            input: values,
          });
        await organizationCreateOwn(transformedValues as IGraphqlVariables<IOrganizationCreateOwnInput>)
      }
    });

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      dispatch(setThemeMode(theme as "light" | "dark"));
      setDarkMode(theme === "dark");
    }
  }, [dispatch]);

  useEffect(() => {
    if (treeMenuOpacity === -1) {
      setTimeout(() => {
        setTreeMenuDisplay("none");
      }, 100);
    } else {
      setTreeMenuDisplay("block");
    }
  }, [treeMenuOpacity]);

  useEffect(() => {
    if (!organization && data?.type !== "cocrm") {
      setOpenBackdrop(true);
    }
    if (data?.organization && !isLoading) {
      setOpenBackdrop(false);
    }
  }, [organization]);

  useEffect(() => {
    if (organizationCreateOwnError) {
      enqueueSnackbar((organizationCreateOwnError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
    }
  }, [organizationCreateOwnError]);

  return (
    <Box sx={{ display: 'flex', width: '100%', bgcolor: 'background.default' }}>
      <Dialog
        open={openBackdrop}
        onClose={() => null}
      >
        <DialogTitle align="center">
          {t("crm.mustCreateOrganizationTitle")}
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: downMd ? 300 : 500,
            mt: 1,
          }}
        >
          <Grid container spacing={2} sx={{ px: { xs: 1, md: 5 } }}>
            <Grid item xs={12} sx={{ marginTop: 0, paddingTop: 0 }} >
              <Typography>
                {t("crm.mustCreateOrganizationContent")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="stretch" sx={{ cursor: 'pointer' }}>
                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.companyLogo")}*</InputLabel>
                  <Box sx={{ width: 'calc(100% - 12px)', border: '1px dashed', borderColor: "primary.light", borderRadius: '16px', p: 1, flexGrow: 1 }}>
                    <Stack direction="column" justifyContent="center" alignItems="center" gap={1} sx={{ width: '100%', height: '100%' }}>
                      <Avatar
                        sx={{ width: 50, height: 50, padding: 2, backgroundColor: "background.default" }}
                        src={"https://cdn-icons-png.flaticon.com/128/9261/9261196.png"}
                      />
                      <Typography variant="body2"> {t("crm.uploadLogo")}</Typography>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.companyName")}*</InputLabel>
                      <TextField
                        fullWidth
                        name="input.name"
                        value={(values as any).input.name}
                        onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                        onBlur={handleBlur}
                        error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                        helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.companyEmail")}*</InputLabel>
                      <TextField
                        fullWidth
                        name="input.name"
                        value={(values as any).input.name}
                        onChange={(e) => setFieldValue("input.email", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                        onBlur={handleBlur}
                        error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                        helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>


            <Grid item xs={6} >
              <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.companyPhone")}*</InputLabel>
              <TextField
                fullWidth
                name="input.name"
                value={(values as any).input.name}
                onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                onBlur={handleBlur}
                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
              />
            </Grid>
            <Grid item xs={6} >
              <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.country")}*</InputLabel>
              <TextField
                fullWidth
                name="input.name"
                value={(values as any).input.name}
                onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                onBlur={handleBlur}
                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
              />
            </Grid>
            <Grid item xs={6} >
              <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.city")}*</InputLabel>
              <TextField
                fullWidth
                name="input.name"
                value={(values as any).input.name}
                onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                onBlur={handleBlur}
                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
              />
            </Grid>
            <Grid item xs={6} >
              <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.industry")}*</InputLabel>
              <TextField
                fullWidth
                name="input.name"
                value={(values as any).input.name}
                onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                onBlur={handleBlur}
                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
              />
            </Grid>
            <Grid item xs={6} >
              <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.timezone")}*</InputLabel>
              <TextField
                fullWidth
                name="input.name"
                value={(values as any).input.name}
                onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                onBlur={handleBlur}
                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
              />
            </Grid>
            <Grid item xs={6} >
              <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("crm.language")}*</InputLabel>
              <TextField
                fullWidth
                name="input.name"
                value={(values as any).input.name}
                onChange={(e) => setFieldValue("input.name", e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                onBlur={handleBlur}
                error={!!((errors as any)?.input?.name && (touched as any)?.input?.name)}
                helperText={(touched as any)?.input?.name && (errors as any)?.input?.name}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleLogout();
              setOpenBackdrop(false);
            }}
          >
            {t("crm.cancel")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={organizationCreateOwnLoading}
            onClick={() => {
              handleSubmit();
            }}
          >
            {organizationCreateOwnLoading ? <CircularProgress size={"1rem"} /> : t("crm.create")}
          </Button>

        </DialogActions>
      </Dialog>

      <Stack direction={"row"} sx={{ width: '100%' }}>

        {/* Drawer */}
        <ClickAwayListener onClickAway={() => {
          if (treeMenuDisplay === "block" && downMd && (route === "crm" || route === "leadPlus" || route === "community" || route === "marketing" || route === "window"))
            handleButtonClick("clickaway")
        }} >
          <animated.div style={mainMenuStyle}>
            <Stack direction="row" id={"main-menu"}
              sx={{
                p: { xs: "5px", md: "20px" },
                height: { xs: 'calc(100dvh - 10px)', md: 'calc(100dvh - 40px)' },
                gap: { xs: 1, md: 2 }
              }}>
              {/* Route Menu */}
              <Stack direction="column" id={"route-menu"} justifyContent={"space-between"} alignItems={"center"} gap={{ xs: 1, md: 3 }} zIndex={11}>
                <Stack direction="column" justifyContent={"flex-start"} alignItems={"center"} gap={{ xs: 1, md: 3 }} my={{ xs: "5px", md: "10px" }}>
                  <img src={LogoMini} alt="logo" style={{ width: downMd ? "30px" : "45px", height: downMd ? "30px" : '45px', marginBottom: downMd ? "5px" : '10px' }} />
                  <Tooltip title="CRM">
                    <Box
                      sx={{
                        width: downMd ? "30px" : "45px", height: downMd ? "30px" : "45px", borderRadius: "23px",
                        display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                        bgcolor: route === "crm" ? "secondary.main" : "background.third"
                      }}
                      onClick={() => handleButtonClick("crm")}>
                      <Iconify icon={"gravity-ui:nodes-up"} width={downMd ? 16 : 24} sx={{ color: route === "crm" ? "text.white" : "text.primary" }} />
                    </Box>
                  </Tooltip>
                  <Tooltip title="Lead +">
                    <Box sx={{
                      width: downMd ? "30px" : "45px", height: downMd ? "30px" : "45px", borderRadius: "23px",
                      display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                      bgcolor: route === "leadPlus" ? "secondary.main" : "background.third"
                    }}
                      onClick={() => handleButtonClick("leadPlus")}>
                      <Iconify icon={"gravity-ui:circles-3-plus"} width={downMd ? 16 : 24} sx={{ color: route === "leadPlus" ? "text.white" : "text.primary" }} />
                    </Box>
                  </Tooltip>
                  <Tooltip title="Marketing">
                    <Box sx={{
                      width: downMd ? "30px" : "45px", height: downMd ? "30px" : "45px", borderRadius: "23px",
                      display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                      bgcolor: route === "marketing" ? "secondary.main" : "background.third"
                    }}
                      onClick={() => handleButtonClick("marketing")}>
                      <Iconify icon={"nimbus:marketing"} width={downMd ? 16 : 24} sx={{ color: route === "marketing" ? "text.white" : "text.primary" }} />
                    </Box>
                  </Tooltip>
                  <Tooltip title="Window">
                    <Box sx={{
                      width: downMd ? "30px" : "45px", height: downMd ? "30px" : "45px", borderRadius: "23px",
                      display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                      bgcolor: route === "window" ? "secondary.main" : "background.third"
                    }}
                      onClick={() => handleButtonClick("window")}>
                      <Iconify icon={"material-symbols:window-outline"} width={downMd ? 16 : 24} sx={{ color: route === "window" ? "text.white" : "text.primary" }} />
                    </Box>
                  </Tooltip>
                  <Tooltip title="Community">
                    <Box sx={{
                      width: downMd ? "30px" : "45px", height: downMd ? "30px" : "45px", borderRadius: "23px",
                      display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                      bgcolor: route === "community" ? "secondary.main" : "background.third"
                    }}
                      onClick={() => handleButtonClick("community")}>
                      <Iconify icon={"gravity-ui:persons-lock"} width={downMd ? 16 : 24} sx={{ color: route === "community" ? "text.white" : "text.primary" }} />
                    </Box>
                  </Tooltip>
                </Stack>
                <Stack direction="column" justifyContent={"flex-start"} alignItems={"center"} gap={{ xs: 1, md: 3 }} mb={{ xs: "5px", md: "0px" }}>
                  <Box
                    onClick={() => handleButtonClick("disk")}
                    sx={{
                      width: downMd ? "30px" : "45px", height: downMd ? "30px" : "45px", borderRadius: "23px",
                      bgcolor: "background.third",
                      display: 'flex', justifyContent: 'center', alignItems: 'center',
                      pointer: 'cursor',
                    }}>
                    <Iconify icon={"clarity:hard-disk-outline-badged"} width={downMd ? 16 : 24} sx={{ color: "text.primary" }} />
                  </Box>
                  <Box
                    onClick={() => handleButtonClick("account")}
                    sx={{
                      cursor: 'pointer',
                      width: downMd ? "30px" : "45px", height: downMd ? "30px" : "45px", borderRadius: "23px",
                      bgcolor: route === "account" ? "secondary.main" : "background.third",
                      display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}>
                    <Iconify icon={"gravity-ui:gear"} width={downMd ? 16 : 24} sx={{ color: route === "account" ? "text.white" : "text.primary" }} />
                  </Box>
                </Stack>
              </Stack>


            </Stack>
          </animated.div>
        </ClickAwayListener >

        {/* Main */}
        < Stack direction="column" sx={{ width: '100%', mr: { xs: "5px", md: "30px" } }}>

          {/* Header*/}
          < Stack direction="row" sx={{
            height: { xs: "50px", md: '100px' }, width: '100%', display: 'flex',
            justifyContent: 'space-between', alignItems: 'center',
          }}>

            {/* Search Bar */}
            {
              !downMd && <Stack direction={"row"} gap={1} alignItems={"center"}>
                {!isTreeVisible && <Iconify icon={"tabler:layout-sidebar-right-collapse-filled"} width={24}
                  onClick={() => handleButtonClick(route)}
                  sx={{ cursor: 'pointer', color: 'text.primary' }} />}
                <CustomTextField
                  variant="outlined"
                  placeholder={t("crm.searchPlaceholder")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon={"mynaui:search"} width={24} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            }

            {
              downMd && <Stack direction={"row"} alignItems={"center"} ml={1} gap={1} position={"relative"}>
                <Typography fontSize={"12px"} fontWeight={"bold"}>My Company</Typography>
                {/* <Iconify icon={"gravity-ui:layout-split-columns"} width={12} sx={{ bgcolor: '#fff', p: "4px", position: 'absolute', bottom:-30, borderRadius: '6px' }} /> */}
              </Stack>
            }

            {/* Action Buttons */}
            <Stack direction={"row"} gap={{ xs: 1, md: 2 }} sx={{ mr: { xs: 1, md: 0 } }}>
              {downMd && <Box sx={{
                width: "30px", height: "30px", borderRadius: "15px",
                display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                bgcolor: 'background.third'
              }}>
                <Iconify icon={"mynaui:search"} width={16} sx={{ color: "text.primary" }} />
              </Box>}


              <Tooltip title="Account Menu">
                <Stack direction={"row"} gap={{ xs: 1, md: 2 }} bgcolor={"background.third"}
                  borderRadius={"23px"} px={"10px"}
                  onClick={handleClickAccountMenu}
                  aria-controls={openAccountMenu ? 'account-menu' : undefined}
                  aria-expanded={openAccountMenu ? 'true' : undefined}
                  sx={{ cursor: 'pointer' }}
                  alignItems={"center"} justifyContent={"center"}>
                  <Iconify icon={"gravity-ui:bars"} width={downMd ? 12 : 18} sx={{ color: "text.primary" }} />
                  <img src={"https://picsum.photos/100/100"} alt="profile" style={{ width: downMd ? '15px' : '30px', height: downMd ? '15px' : '30px', borderRadius: "15px" }} />
                </Stack>
              </Tooltip>
              <Menu
                anchorEl={anchorElAccountMenu}
                id={t("crm.accountMenu")}
                open={openAccountMenu}
                onClose={handleCloseAccountMenu}
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      borderRadius: '15px',
                      mt: 1.5,
                      minWidth: "200px",
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 20,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={handleCloseAccountMenu} sx={{ fontSize: "14px" }}>
                  <Avatar /> {t("crm.profile")}
                </MenuItem>
                <MenuItem onClick={handleCloseAccountMenu} sx={{ fontSize: "14px" }}>
                  <Avatar /> {t("crm.myAccount")}
                </MenuItem>
                <Divider />

                <MenuItem sx={{ fontSize: "14px" }} onClick={() => toggleDarkMode(!isDarkMode)}>
                  <ToggleButton
                    value="check"
                    selected={isDarkMode}
                    size="small"
                    fullWidth
                    onChange={() => toggleDarkMode(!isDarkMode)}
                    sx={{ borderRadius: "12px", height: "37px", borderColor: '#C4C4C4 !important' }}
                  >
                    <Stack direction={"row"} gap={1} alignItems={"center"} sx={{ width: '100%' }} justifyContent={"flex-start"}>
                      <DarkModeSwitch checked={isDarkMode} size={20} onChange={toggleDarkMode} />
                      {isDarkMode ? t("crm.darkMode") : t("crm.lightMode")}
                    </Stack>
                  </ToggleButton>
                </MenuItem>
                <MenuItem>
                  <FormControl fullWidth>
                    <Select
                      labelId={t("crm.languageSelectLabel")}
                      id="demo-simple-select"
                      value={language}
                      onChange={(e) => {
                        setLanguage(e.target.value as Language);
                        changeLanguage(e.target.value as Language);
                      }}
                      size="small"
                      sx={{
                        fontSize: "14px",
                        borderRadius: "12px",
                        fontWeight: 600,
                        bgcolor: 'background.third',
                      }}
                    >
                      <MenuItem value={"en"} sx={{ fontSize: "14px" }}>
                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                          <Iconify icon={"circle-flags:uk"} sx={{ fontSize: "14px" }} />
                          {t("crm.english")}
                        </Stack>
                      </MenuItem>
                      <MenuItem value={"tr"} sx={{ fontSize: "14px" }}>
                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                          <Iconify icon={"circle-flags:tr"} sx={{ fontSize: "14px" }} />
                          {t("crm.turkish")}
                        </Stack>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </MenuItem>
                <MenuItem onClick={handleCloseAccountMenu} sx={{ fontSize: "14px" }}>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  {t("crm.account")}
                </MenuItem>
                <MenuItem onClick={handleLogout} sx={{ fontSize: "14px" }}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  {t("crm.logout")}
                </MenuItem>
              </Menu>


              <Tooltip title="New Task">
                <Box sx={{
                  width: { xs: "30px", md: "45px" }, height: { xs: "30px", md: "45px" }, borderRadius: "23px",
                  display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                  bgcolor: "secondary.main"
                }}
                  onClick={() => setRoute("leadPlus")}>
                  <Iconify icon={"gravity-ui:plus"} width={downMd ? 16 : 24} sx={{ color: "#FFF" }} />
                </Box>
              </Tooltip>

            </Stack>

          </Stack >

          {/* Content */}
          <animated.div style={contentStyle} >
            <Stack direction="row" sx={{
              bgcolor: 'background.secondary',
              overflow: "hidden", borderRadius: "30px",
              height: { xs: 'calc(100dvh - 60px)', md: 'calc(100dvh - 120px)' },
              zIndex: 0
            }}>
              <Box width={"100%"} sx={{ borderRadius: "30px" }} overflow={"auto"} p={{ xs: 2, md: 3 }}>
                {data?.organization && <Outlet />}
              </Box>
            </Stack>
          </animated.div>

        </Stack >

      </Stack >
    </Box >
  );
}


export default AccountLayout