

export const profileDomainRoutes = {
  base: "profile",
  subdomains: {
    profile: {
      base: "profile",
    },
  },
};


export const baseProfileRoute = (): string => `/${profileDomainRoutes.base}/`;
export const listEmployeesRoute = (): string =>
  `/${profileDomainRoutes.base}/${profileDomainRoutes.subdomains.profile.base}`;


